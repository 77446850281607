import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MuiAlert from "@mui/material/Alert";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useSession from "../../CustomHooks/useSession";
import { getClusters } from "../Clusters/Clusters";
import { useFormik } from "formik";
import * as yup from "yup";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  ClustorID: yup.string("Enter Cluster").required("Cluster is required"),
  // SchoolID: yup.string("Enter School").required("School is required"),
  DateofWorkshop: yup
    .string("Enter Date of Workshop")
    .required("Date of Workshop is required"),
  Topic: yup.string("Enter Topic").required("Topic is required"),
  Facilitator: yup
    .string("Enter Facilitator")
    .required("Facilitator is required"),
  Venue: yup.string("Enter Venue").required("Venue is required"),
  StartTime: yup.string("Enter Start Time").required("Start Time is required"),
  EndTime: yup.string("Enter End Time").required("End Time is required"),
  // GeneralRemarks: yup
  //   .string("Enter General Remarks")
  //   .required("General Remarks is required"),
  TrainerName: yup
    .string("Enter Trainer Name")
    .required("Trainer Name is required"),
  // RemarksAMSAcademics: yup
  //   .string("Enter Remarks AMS Academics")
  //   .required("Remarks AMS Academics is required"),
  // RemarksSchoolManager: yup
  //   .string("Enter Remarks School Manager")
  //   .required("Remarks School Manager is required"),
});

export default function WorkShop() {
  const navigate = useNavigate();
  const [snackbar, setSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteSnackbar, setDeleteSnackBar] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clusters, setClusters] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshops, setSelectedWorkshops] = useState({});
  const openAnchor = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  console.log("dsadsaasdas");
  useEffect(() => {
    _fetchClusters();
    _fetchWorkshops();
  }, []);

  const formik = useFormik({
    initialValues: {
      ClustorID: "",
      SchoolID: "",
      DateofWorkshop: dayjs().format("YYYY-MM-DD"),
      Topic: "",
      Facilitator: "",
      Venue: "",
      // StartTime: dayjs().format("YYYY-MM-DD"),
      // EndTime: dayjs().format("YYYY-MM-DD"),
      Generalremarks: "",
      TrainerName: "",
      RemarksAMSAcademics: "",
      RemarksSchoolManager: "",
    },
    // validationSchema: validationSchema,
    // validateOnChange: false,
    onSubmit: async (values) => {
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
        console.log("formData", formData);
      }
      try {
        if (isEdit) {
          setLoading((prevState) => !prevState);
          const { data } = await axiosInstance.put(
            `/workshops/${selectedWorkshops.WorkshopID}`,
            formData
          );
          console.log("DATA", data);
          if (Object.keys(data).length > 0) {
            setLoading((prevState) => !prevState);
            setSnackBar(true);
            setOpen(false);
            // resetForm();
            setIsEdit(false);
            setSelectedWorkshops({});
            _fetchWorkshops();
          } else {
            setSnackBar(false);
            setOpen(true);
            setLoading((prevState) => !prevState);
          }
        } else {
          setLoading((prevState) => !prevState);
          const { data } = await axiosInstance.post("/workshops", formData);
          console.log("DATA", data);
          if (Object.keys(data).length > 0) {
            setLoading((prevState) => !prevState);
            setSnackBar(true);
            setOpen(false);
            // resetForm();
            _fetchWorkshops();
          } else {
            setLoading((prevState) => !prevState);
            setSnackBar(false);
            setOpen(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  const _onDelete = async () => {
    try {
      const { data } = await axiosInstance.delete(
        `/workshops/${selectedWorkshops.WorkshopID}`
      );
      console.log("DATA", data);
      if (Object.keys(data).length > 0) {
        setDeleteSnackBar(true);
        _fetchWorkshops();
      } else {
        setDeleteSnackBar(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchClusters = async () => {
    try {
      const response = await getClusters();
      console.log("clusters", response.data);
      setClusters(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchWorkshops = async () => {
    try {
      const userAccessArray = JSON.parse(localStorage.getItem("UserAccess"));
      const schoolId = userAccessArray?.[0]?.SchoolId;
      if (!schoolId) {
        console.log("No SchoolId found in UserAccess");
        return;
      }
  
      const { data } = await axiosInstance.get("/workshops");
      console.log("get data", data.data);
  
      const filteredWorkshops = data.data.filter((workshop) => 
        workshop.Cluster.School.some(school => school.SchoolId === schoolId)
      );
      console.log("filtered workshops", filteredWorkshops);
      if (Object.keys(filteredWorkshops).length > 0) {
        setWorkshops(filteredWorkshops);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _handleDateOfWorkshop = (e) => {
    formik.setFieldValue("DateofWorkshop", dayjs(e));
  };
  const _handleStartDate = (e) => {
    formik.setFieldValue("StartTime", dayjs(e));
  };
  const _handleEndDate = (e) => {
    formik.setFieldValue("EndTime", dayjs(e));
  };
  const handleOpen = () => {
    setIsEdit(false);
    setOpen(true);
    for (let value in formik.values) {
      if (
        value === "DateofWorkshop" ||
        value === "StartTime" ||
        value === "EndTime"
      ) {
        formik.setFieldValue(value, dayjs(value).format("YYYY-MM-DD"));
      } else {
        formik.setFieldValue(value, "");
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (workshop) => (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    console.log("selectedWorkshop", workshop);
    setSelectedWorkshops(workshop);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const _onEdit = () => {
    setIsEdit(true);
    setOpen(true);
    for (let value in formik.values) {
      if (
        value === "DateofWorkshop" ||
        value === "StartTime" ||
        value === "EndTime"
      ) {
        formik.setFieldValue(value, dayjs(selectedWorkshops[value]));
      } else {
        formik.setFieldValue(value, selectedWorkshops[value]);
      }
    }
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };
  const deleteSnack_handleClose = () => {
    console.log("Snack bar Closed");
    setDeleteSnackBar(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Workshop Management Module
            </Typography>
          </Stack>
          <Box width={300}>
            {currentPageCRUD.create && (
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Add Workshop
                </Button>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" component="h1">
            Training Report (TR)
          </Typography>
        </Box>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Cluster/s</TableCell>
                  <TableCell align="center">Topic</TableCell>
                  <TableCell align="center">Facilitator</TableCell>
                  <TableCell align="center">Workshop Date</TableCell>
                  <TableCell align="center">Venue</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageCRUD.read &&
                  workshops?.map((workshop, index) => {
                    return (
                      <>
                        <TableRow
                          key={String(index)}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {workshop.Cluster.RegionName}
                          </TableCell>
                          <TableCell align="center">{workshop.Topic}</TableCell>
                          <TableCell align="center">
                            {workshop.Facilitator}
                          </TableCell>
                          <TableCell align="center">
                            {dayjs(workshop.DateofWorkshop).format(
                              "DD-MM-YYYY"
                            )}
                          </TableCell>
                          <TableCell align="center">{workshop.Venue}</TableCell>
                          <TableCell align="right">
                            <IconButton onClick={handleClick(workshop)}>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {currentPageCRUD.read && (
                <MenuItem
                  onClick={() =>
                    navigate("participant", {
                      state: {
                        Cluster: selectedWorkshops.Cluster,
                        WorkshopID: selectedWorkshops.WorkshopID,
                      },
                    })
                  }
                >
                  Participant
                </MenuItem>
              )}

              {currentPageCRUD.delete && (
                <MenuItem onClick={_onDelete}>Delete</MenuItem>
              )}

              {currentPageCRUD.update && (
                <MenuItem onClick={_onEdit}>Edit</MenuItem>
              )}
            </Menu>
          </TableContainer>
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>Create Workshop</DialogTitle>
            <DialogContent>
              <form onSubmit={formik.handleSubmit}>
                <Box flexGrow={1}>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h1" sx={{ mb: 2 }}>
                      Training Report (TR)
                    </Typography>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Select Cluster"
                          sx={{ display: "flex", flex: 1 }}
                          variant="outlined"
                          name="ClustorID"
                          defaultValue="Select Student"
                          size="small"
                          error={
                            formik.touched.ClustorID &&
                            Boolean(formik.errors.ClustorID)
                          }
                          helperText={
                            formik.touched.ClustorID && formik.errors.ClustorID
                          }
                          onChange={formik.handleChange}
                          value={formik.values.ClustorID}
                        >
                          {clusters?.map((cluster, index) => {
                              return (
                                <MenuItem key={index} value={cluster.RegionId}>
                                  {cluster.RegionName}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Topic"
                          variant="outlined"
                          size="small"
                          sx={{ display: "flex", flex: 1 }}
                          name="Topic"
                          onChange={formik.handleChange}
                          value={formik.values.Topic}
                          error={
                            formik.touched.Topic && Boolean(formik.errors.Topic)
                          }
                          helperText={
                            formik.touched.Topic && formik.errors.Topic
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          // autoFocus
                          label="Facilitator"
                          variant="outlined"
                          size="small"
                          sx={{ display: "flex", flex: 1 }}
                          name="Facilitator"
                          onChange={formik.handleChange}
                          value={formik.values.Facilitator}
                          error={
                            formik.touched.Facilitator &&
                            Boolean(formik.errors.Facilitator)
                          }
                          helperText={
                            formik.touched.Facilitator &&
                            formik.errors.Facilitator
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          // autoFocus
                          label="Venue"
                          variant="outlined"
                          size="small"
                          sx={{ display: "flex", flex: 1 }}
                          name="Venue"
                          onChange={formik.handleChange}
                          value={formik.values.Venue}
                          error={
                            formik.touched.Venue && Boolean(formik.errors.Venue)
                          }
                          helperText={
                            formik.touched.Venue && formik.errors.Venue
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            inputFormat="DD-MM-YYYY"
                            label="Workshop Date"
                            value={formik.values.DateofWorkshop}
                            name="DateofWorkshop"
                            onChange={_handleDateOfWorkshop}
                            renderInput={(params) => (
                              <TextField
                                sx={{ display: "flex", flex: 1 }}
                                size="small"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Divider sx={{ mt: 1, mb: 2 }} />
                        <Typography variant="subtitle2" component="span">
                          General Remarks
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="General Remarks by Training Incharge"
                          name="Generalremarks"
                          style={{ width: "100%", padding: 15 }}
                          onChange={formik.handleChange}
                          value={formik.values.Generalremarks}
                          // error={
                          //   formik.touched.GeneralRemarks &&
                          //   Boolean(formik.errors.GeneralRemarks)
                          // }
                          // helperText={
                          //   formik.touched.GeneralRemarks &&
                          //   formik.errors.GeneralRemarks
                          // }
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ pt: 0 }}>
                        <TextField
                          // autoFocus
                          label="Training Incharge Name"
                          variant="outlined"
                          size="small"
                          sx={{ display: "flex", flex: 1 }}
                          name="TrainerName"
                          onChange={formik.handleChange}
                          value={formik.values.TrainerName}
                          error={
                            formik.touched.TrainerName &&
                            Boolean(formik.errors.TrainerName)
                          }
                          helperText={
                            formik.touched.TrainerName &&
                            formik.errors.TrainerName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Divider sx={{ mt: 1, mb: 2 }} />
                        <Typography variant="subtitle2" component="span">
                          Remarks by AMS Academic and Training
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Remarks by AMS Academic and Training"
                          name="RemarksAMSAcademics"
                          onChange={formik.handleChange}
                          style={{ width: "100%", padding: 15 }}
                          value={formik.values.RemarksAMSAcademics}
                          // error={
                          //   formik.touched.RemarksAMSAcademics &&
                          //   Boolean(formik.errors.RemarksAMSAcademics)
                          // }
                          // helperText={
                          //   formik.touched.RemarksAMSAcademics &&
                          //   formik.errors.RemarksAMSAcademics
                          // }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Divider sx={{ mt: 1, mb: 2 }} />
                        <Typography variant="subtitle2" component="span">
                          Remarks by Manager Schools
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Remarks by Manager Schools"
                          style={{ width: "100%", padding: 15 }}
                          name="RemarksSchoolManager"
                          onChange={formik.handleChange}
                          value={formik.values.RemarksSchoolManager}
                          // error={
                          //   formik.touched.RemarksSchoolManager &&
                          //   Boolean(formik.errors.RemarksSchoolManager)
                          // }
                          // helperText={
                          //   formik.touched.RemarksSchoolManager &&
                          //   formik.errors.RemarksSchoolManager
                          // }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <LoadingButton
                          loading={loading}
                          disabled={loading}
                          type="submit"
                          // onSubmit={formik.handleSubmit}
                          // onClick={_editSyllabus}
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          {isEdit ? "Save Changes" : "Create Workshop"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}></Grid>
                  </Box>
                </Box>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {isEdit ? "Workshop has been Updated!" : "Workshop has been Added!"}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={deleteSnackbar}
        autoHideDuration={6000}
        onClose={deleteSnack_handleClose}
      >
        <Alert
          onClose={deleteSnack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Workshop has been Deleted!
        </Alert>
      </Snackbar>
    </>
  );
}
