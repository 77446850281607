import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import SubjectIcon from "@mui/icons-material/Subject";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleIcon from "@mui/icons-material/People";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CategoryIcon from "@mui/icons-material/Category";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ReportIcon from "@mui/icons-material/Report";
import DvrIcon from "@mui/icons-material/Dvr";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EventIcon from "@mui/icons-material/Event";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArticleIcon from "@mui/icons-material/Article";
import { Collapse, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField"; // Import TextField
// const feather = require("feather-icons");
const drawerWidth = 240;

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const REPORT_ITEMS = [
  {
    title: "Daily Entries Report",
    route: "/reports/daily-entries",
    icon: <ReportIcon />,
  },
  {
    title: "Fee Collection V1",
    route: "/reports/fee-collection-v1",
    icon: <ReportIcon />,
  },
  {
    title: "New Admissions School Report",
    route: "/reports/new-admissions-school-wise",
    icon: <ReportIcon />,
  },
  {
    inner_reports: [
      {
        title: "New Admissions School Report",
        route: "/reports/new-admissions-school-wise",
        icon: <ReportIcon />,
      },
      {
        title: "New Admissions School Report",
        route: "/reports/new-admissions-school-wise",
        icon: <ReportIcon />,
      },
    ],
  },
  {
    title: "New Admissions Cluster Report",
    route: "/reports/new-admissions-cluster-wise",
    icon: <ReportIcon />,
  },
  {
    title: "Dropouts Session Wise",
    route: "/reports/dropouts-session-wise",
    icon: <ReportIcon />,
  },
  {
    title: "Dropouts School Report",
    route: "/reports/dropout-student-school-wise",
    icon: <ReportIcon />,
  },
  {
    title: "Passouts School Report",
    route: "/reports/passout-student-school-wise",
    icon: <ReportIcon />,
  },
  {
    title: "Subject/Variation Report",
    route: "/reports/student-test-results-report",
    icon: <ReportIcon />,
  },
  {
    title: "Subject/Variation Result Report",
    route: "/reports/student-test-results-report",
    icon: <ReportIcon />,
  },
  {
    title: "All Subject Result Report",
    route: "/reports/student-all-subject-test-results-report",
    icon: <ReportIcon />,
  },
  {
    title: "Grade Wise Cluster Report",
    route: "/reports/student-by-grade-cluster-wise-report",
    icon: <ReportIcon />,
  },
  {
    title: "Exam Detail Cluster/School Wise Report",
    route: "/reports/student-by-grade-school-class-wise-report",
    icon: <ReportIcon />,
  },
  {
    title: "Teacher Attendance Report",
    route: "/reports/teacher-attendance-summary",
    icon: <ReportIcon />,
  },
  {
    title: "Test Result Attendance",
    route: "/reports/testresult-present-absent-count-schoolwise",
    icon: <ReportIcon />,
  },
  {
    title: "Active Student Details",
    route: "/reports/active-student-report-school-wise",
    icon: <ReportIcon />,
  },
  {
    title: "Active Student Attendance Stats",
    route: "/reports/student-attendance-stats-date-range-school-wise",
    icon: <ReportIcon />,
  },
  {
    title: "List of Pending Class Teacher Observation",
    route:
      "/reports/list-of-observation-not-done-class-teacher-observation-list-periodic",
    icon: <ReportIcon />,
  },
  {
    title: "Class Teacher Observation Matrix",
    route: "/reports/pivot-result-teacher-class-observation-period",
    icon: <ReportIcon />,
  },
  {
    title: "Quit School Report",
    route: "/reports/quit-student-school-wise",
    icon: <ReportIcon />,
  },
  {
    title: "Asset Physical Condition",
    route: "/reports/fixed-assets-condition",
    icon: <ReportIcon />,
  },
  {
    title: "Paid Fee Summary School Wise",
    route: "/reports/school-wise-fee-collection-report",
    icon: <ReportIcon />,
  },
  {
    title: "Unpaid Fee List of Students",
    route: "/reports/upaid-fee-school-wise-report",
    icon: <ReportIcon />,
  },
  
];

const MENU_ITEMS = [
  {
    title: "Dashboard",
    route: "/",
    Icon: <DashboardIcon />,
  },
  {
    title: "Clusters",
    route: "/clusters",
    Icon: <GpsFixedIcon />,
  },
  {
    title: "Subjects & Classes",
    route: "/class-subjects",
    Icon: <SubjectIcon />,
  },
  {
    title: "Staff",
    route: "/staff",
    Icon: <PersonOutlineIcon />,
  },
  {
    title: "Students",
    route: "/students",
    Icon: <PeopleIcon />,
  },
  {
    title: "Rollback",
    route: "/Rollback",
    Icon: <PeopleIcon />,
  },
  {
    title: "Parent Interview",
    route: "/parent-interview",
    Icon: <PodcastsIcon />,
  },
  {
    title: "Fee Management",
    route: "/fee-management",
    Icon: <CreditCardIcon />,
  },
  {
    title: "Student Attendance",
    route: "/attendance",
    Icon: <HowToRegIcon />,
  },
  {
    title: "Staff Attendance",
    route: "/staff-attendance",
    Icon: <HowToRegIcon />,
  },
  {
    title: "User Management",
    route: "/user-management",
    Icon: <ManageAccountsIcon />,
  },
  {
    title: "Test Management",
    route: "/test-management",
    Icon: <NoteAltIcon />,
  },
  {
    title: "Co-Curricular",
    route: "/co-curricular",
    Icon: <AdminPanelSettingsIcon />,
  },
  {
    title: "CAP",
    route: "/CAP",
    Icon: <EventIcon />,
  },
  {
    title: "PTM",
    route: "/PTM",
    Icon: <MeetingRoomIcon />,
  },
  {
    title: "Syllabus Management",
    route: "/syllabus",
    Icon: <AssignmentIcon />,
  },
  {
    title: "Variable Management",
    route: "/variable-management",
    Icon: <CategoryIcon />,
  },
  {
    title: "Library Management",
    route: "/library",
    Icon: <LocalLibraryIcon />,
  },
  {
    title: "Workshop",
    route: "/workshop",
    Icon: <HomeRepairServiceIcon />,
  },
  {
    title: "Class Observation",
    route: "/class-observation-form",
    Icon: <ContentPasteSearchIcon />,
  },
  {
    title: "School Record",
    route: "/school-record",
    Icon: <AssignmentTurnedInIcon />,
  },
  {
    title: "School Assembly",
    route: "/school-assembly",
    Icon: <CastForEducationIcon />,
  },
  {
    title: "Weekly Assembly Record",
    route: "/weekly-assembly-record",
    Icon: <DvrIcon />,
  },
  {
    title: "Fixed Assets",
    route: "/fixed-assets",
    Icon: <ListAltIcon />,
  },
  {
    title: "Daily Entries Report",
    route: "/reports/daily-entries",
    Icon: <ReportIcon />,
  },
  {
    title: "Fee Collection V1",
    route: "/reports/fee-collection-v1",
    Icon: <ReportIcon />,
  },
  {
    title: "New Admissions School Wise Report",
    route: "/reports/new-admissions-school-wise",
    Icon: <ReportIcon />,
  },
];

const icons = {
  GpsFixedIcon: <GpsFixedIcon />,
  SubjectIcon: <SubjectIcon />,
  PersonOutlineIcon: <PersonOutlineIcon />,
  PeopleIcon: <PeopleIcon />,
  PodcastsIcon: <PodcastsIcon />,
  CreditCardIcon: <CreditCardIcon />,
  HowToRegIcon: <HowToRegIcon />,
  HowToRegIcon: <HowToRegIcon />,
  ManageAccountsIcon: <ManageAccountsIcon />,
  NoteAltIcon: <NoteAltIcon />,
  AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
  EventIcon: <EventIcon />,
  MeetingRoomIcon: <MeetingRoomIcon />,
  AssignmentIcon: <AssignmentIcon />,
  CategoryIcon: <CategoryIcon />,
  LocalLibraryIcon: <LocalLibraryIcon />,
  HomeRepairServiceIcon: <HomeRepairServiceIcon />,
  ContentPasteSearchIcon: <ContentPasteSearchIcon />,
  AssignmentTurnedInIcon: <AssignmentTurnedInIcon />,
  CastForEducationIcon: <CastForEducationIcon />,
  DvrIcon: <DvrIcon />,
  ListAltIcon: <ListAltIcon />,
  ReportIcon: <ReportIcon />,
  ReportIcon: <ReportIcon />,
  ReportIcon: <ReportIcon />,
};

export default function Sidebar({ open, appBarClose }) {
  const [menus, setMenus] = useState([]);
  const [reports, setReports] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const RoleData =
      localStorage.getItem("Role") !== null &&
      JSON.parse(localStorage.getItem("Role"));
    const parseMenus = JSON.parse(RoleData.permissions).pages;
    const reports = parseMenus.filter((item) => item.Reports);
    console.log("menus ===>", parseMenus);
    console.log("reports ===>", reports);
    setMenus(parseMenus);
    setReports(reports[0]?.Reports);
    //console.log("reports");
    //console.log("RoleData", RoleData);
    //console.log("parseMenus", parseMenus);
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase()); // Update search query state
  };

  const filteredMenus = menus
    ? menus.filter(
        (menu) => menu.title && menu.title.toLowerCase().includes(searchQuery)
      )
    : [];

  const filteredReports = reports
    ? reports.filter(
        (report) =>
          report.title && report.title.toLowerCase().includes(searchQuery)
      )
    : [];

  const _onMenuItemClick = (route) => (event) => {
    navigate(route);
  };

  const handleClick = () => {
    setOpenCollapse((prev) => !prev);
  };

  //const fectchMenus = () => {};
  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <DrawerHeader />
      <Divider />

      {/* Add Search Bar */}
      <TextField
        variant="outlined"
        placeholder="Search..."
        id="sideMenuSearchBar"
        value={searchQuery}
        onChange={handleSearch}
        fullWidth
        sx={{ padding: "10px" }}
      />

      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => navigate("/")}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{ opacity: open ? 1 : 0 }}
              primaryTypographyProps={{
                variant: "span",
              }}
            />
          </ListItemButton>
          {/* Render filtered menu items */}
          {filteredMenus.map((menu, index) =>
            menu.create || menu.read || menu.update || menu.delete ? (
              <ListItemButton
                onClick={_onMenuItemClick(menu.route)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                key={String(index)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {icons[menu.icon]}
                </ListItemIcon>
                <ListItemText
                  primary={menu.title}
                  sx={{ opacity: open ? 1 : 0 }}
                  primaryTypographyProps={{
                    variant: "span",
                  }}
                />
              </ListItemButton>
            ) : null
          )}

          <ListItem
            onClick={handleClick}
            sx={{
              cursor: "pointer",
              paddingLeft: "20px",
              paddingRight: "20px",
              marginBottom: "8px",
            }}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary={"Reports"} />
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </ListItem>

          <Collapse in={openCollapse} timeout="auto" unmountOnExit sx={{}}>
            <List component="div" disablePadding sx={{ cursor: "pointer" }}>
              {filteredReports.length > 0 &&
                filteredReports.map((menu, index) =>
                  menu.create || menu.read || menu.update || menu.delete ? (
                    <ListItemButton
                      onClick={_onMenuItemClick(menu.route)}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      key={String(index)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {icons[menu.icon]}
                      </ListItemIcon>
                      <ListItemText
                        primary={menu.title}
                        sx={{ opacity: open ? 1 : 0 }}
                        primaryTypographyProps={{
                          variant: "span",
                        }}
                      />
                    </ListItemButton>
                  ) : null
                )}
            </List>
          </Collapse>
        </ListItem>
      </List>
    </Drawer>
  );
}
