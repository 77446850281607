import React, { useEffect } from "react";
import AddTest from "./AddTest";
import TestList from "./TestList";
import TestListNew from "./TestListNew";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import axiosInstance from "../../Api/axios.instance";
import useSession from "../../CustomHooks/useSession";

export function fetchTests() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/tests");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export default function Test() {
  const { accesLevel, access } = useSession();
  const { currentPage } = useSession();
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const [value, setValue] = React.useState(0);
  const [tests, setTests] = React.useState([]);
  useEffect(() => {
    _fetchTests();
  }, []);
  const _fetchTests = async () => {
    try {
      const res = await fetchTests();
      console.log("tests", res.data);
      const activeTest = res.data.filter((test) => test.SchoolID === null);
      setTests(activeTest);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {accesLevel == "ADMIN" ? (
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {/* <Tab label="Test List" {...a11yProps(0)} /> */}
              <Tab label="Test List New" {...a11yProps(0)} />
              <Tab label="Add New Test" {...a11yProps(1)} />
            </Tabs>
          ) : (
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {/* <Tab label="Test List" {...a11yProps(0)} /> */}
              <Tab label="Test List New" {...a11yProps(0)} />
              {/* <Tab label="Add New Test" {...a11yProps(1)} /> */}
            </Tabs>
          )}
        </Box>

        {currentPageCRUD.read && (
          <TabPanel value={value} index={0}>
            {/* <StaffList staff={staff} /> */}
            <TestListNew tests={tests} fetchTests={_fetchTests} />
          </TabPanel>
        )}

        {currentPageCRUD.create && (
          <TabPanel value={value} index={1}>
            {/* <AddStaff /> */}
            <AddTest fetchTests={_fetchTests} />
          </TabPanel>
        )}
      </Box>
    </>
  );
}
