import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

export default function SchoolAssembly() {
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openScore, setOpenScore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState("");
  const [region, setRegion] = useState("");
  const [designation, setDesignation] = useState("");
  const [observers, setObservers] = useState([]);
  const [assemblyObservations, setAssemblyObservations] = useState([]);
  const [selectedAssemblyObservation, setSelectedAssemblyObservation] =
    useState({});
  const [variables, setVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [selectedObserver, setSelectedObserver] = useState(null);
  const [selectInputObserver, setSelectedInputObserver] = React.useState("");
  const [teachers, setTeachers] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [formData, setFormData] = useState({
    School_ID: schoolId,
    TeacherID: "",
    AssemblyManagerID: "",
    ServiceDuration: "",
    ObserverName: "",
    AssemblyDate: dayjs().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      console.log("accessObject", accessObject);
      const schoolId = accessObject[0].SchoolId;
      setRegion(accessObject[0].Cluster.RegionName);
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        // _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
        _fetchAssemblyObservationVariables();
      }
      _fetchObservers();
      _fetchAssemblyObserverations();
    }
    if (accesLevel === "ADMIN") {
      const accessObject = Object.assign({}, access);
      console.log("accessObject", accessObject);
      const schoolId = accessObject[0].SchoolId;
      setRegion(accessObject[0].Cluster.RegionName);
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        // _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
        _fetchAssemblyObservationVariables();
      }
      _fetchObservers();
      _fetchAssemblyObserverations();
    }
    if (accesLevel === "CLUSTER") {
      const accessObject = Object.assign({}, access);
      console.log("accessObject", accessObject);
      const schoolId = accessObject[0].SchoolId;
      setRegion(accessObject[0].Cluster.RegionName);
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        // _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
        _fetchAssemblyObservationVariables();
      }
      _fetchObservers();
      _fetchAssemblyObserverations();
    }
  }, []);

  const _fetchAssemblyObservationVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-assembly-observation"
      );
      console.log("Variables", data.data);
      if (status === 200) {
        setVariables(data.data);
        setSelectedVariables(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchAssemblyObserverations = async () => {
    try { 
      const { data, status } = await axiosInstance.get("/assembly-observation");
      console.log("assembly observerations", data.data);
      if (status === 200) {
        setAssemblyObservations(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchTeachersBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school/${id}`
      );
      console.log("setTeachers", data.data);
      if (status === 200) {
        setTeachers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchObservers = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school-designation`
      );
      console.log("observers", data.data);
      if (status === 200) {
        setObservers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _handleAssemblyDate = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        AssemblyDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleVariableChange = (e, index, variable) => {
    const { name, value } = e.target;
    const newVariables = [...selectedVariables];
    newVariables[index][name] = value;
    newVariables[index].VariableID = variable.ID;

    setSelectedVariables(newVariables);
  };

  const _handleEditOnChange = (e) => {
    setSelectedAssemblyObservation((prevValue) => {
      console.log("kajdfkjaljf",prevValue)
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };
  
  const _handleEditAssemblyDate = (e) => {
    setSelectedAssemblyObservation((prevState) => {
      console.log("kajdfkjaljf",prevState)
      return {
        ...prevState,
        AssemblyDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleEditVariableChange = (e, index) => {
    const { name, value } = e.target;
    const newVariables = [
      ...selectedAssemblyObservation.AssemblyObservationScore,
    ];
    newVariables[index][name] = value;

    setSelectedAssemblyObservation((prevState) => {
      return {
        ...prevState,
        AssemblyObservationScore: newVariables,
      };
    });
  };

  const _onObserverChange = (event, newValue) => {
    console.log("On Observer Change", newValue);
    setSelectedObserver(newValue);
    setDesignation(newValue.TeachersDesignation);
    // formik.setFieldValue("ObserverID", newValue.id);
    // formik.setFieldValue("ObserverID", newValue.id)
    setFormData((prevState) => {
      return {
        ...prevState,
        Observer_id: newValue.id,
      };
    });
  };

  const _onObserverInputValue = (event, newInputValue) => {
    setSelectedInputObserver(newInputValue);
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    console.log("selectedVariables", selectedVariables);
    const assemblyObservation = {
      School_ID: schoolId,
      TeacherID: formData.TeacherID,
      AssemblyManagerID: formData.AssemblyManagerID,
      ServiceDuration: formData.ServiceDuration,
      ObserverName: formData.ObserverName,
      ObserverDesignation: formData.ObserverDesignation,
      AssemblyDate: formData.AssemblyDate,
      Scores: selectedVariables,
    };
    console.log("assemblyObservation", assemblyObservation);
    // setLoading((prevState) => !prevState);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/assembly-observation",
        assemblyObservation
      );
      console.log("observationData", data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchAssemblyObserverations();
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpen(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _onEdit = async (e) => {
    e.preventDefault();
    const assemblyObservation = {
      TeacherID: selectedAssemblyObservation.TeacherID,
      AssemblyManagerID: selectedAssemblyObservation.AssemblyManagerID,
      ServiceDuration: selectedAssemblyObservation.ServiceDuration,
      ObserverName: selectedAssemblyObservation.ObserverName,
      ObserverDesignation: selectedAssemblyObservation.ObserverDesignation,
      AssemblyDate: selectedAssemblyObservation.AssemblyDate,
      Scores: selectedAssemblyObservation.AssemblyObservationScore,
    };
    console.log("SchoolRecordEditData", assemblyObservation);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/assembly-observation/${selectedAssemblyObservation.id}`,
        assemblyObservation
      );
      console.log("schoolRecordData ==>", data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchAssemblyObserverations();
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpenEdit(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _onDelete = async () => {
    handleAnchorClose();
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.delete(
        `/assembly-observation/${selectedAssemblyObservation.id}`
      );
      console.log("schoolRecordData ==>", data);
      if (status === 200) {
        _fetchAssemblyObserverations();
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _handleObservationData = () => {
    if (!selectedAssemblyObservation || !selectedAssemblyObservation.Observer) {
      console.error("selectedAssemblyObservation or Observer is null or undefined");
      return;
    }
  
    const observer = selectedAssemblyObservation.Observer;
    const school = observer.School || {};
  
    setSelectedObserver({
      TeachersDesignation: observer.Designation?.TeachersDesignation || "",
      label: `(${observer.TeachersDesignationId || ""}) - ${
        observer.TeacherName || ""
      } - ${school.NameOfSchool || ""}`,
      id: observer.SchoolTeachersQId || "",
    });
  
    setDesignation(observer.Designation?.TeachersDesignation || "");
  };

  const _handleScoreOpen = (observation) => () => {
    console.log("selected assembly observation", observation);
    setSelectedAssemblyObservation(observation);
    setOpenScore(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleScoreClose = () => {
    setOpenScore(false);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
    handleAnchorClose();
    _handleObservationData();
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleClick = (observation) => (event) => {
    console.log("observation", observation);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedAssemblyObservation(observation);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            School Assembly Module
          </Typography>
          <Typography variant="subtitle2" component="span">
            Click Add Assembly button to generate a new School Assembly sheet
          </Typography>
        </Stack>
        <Box width={300}>
          {currentPageCRUD.create && (
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Assembly
              </Button>
            </FormControl>
          )}
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Cluster</TableCell>
                <TableCell align="center">School Name</TableCell>
                <TableCell align="center">AssemblyManager</TableCell>
                <TableCell align="center">Scores</TableCell>
                {/* <TableCell align="center">Teacher Name</TableCell> */}
                <TableCell align="center">Service Duration</TableCell>
                <TableCell align="center">Assembly Date</TableCell>
                <TableCell align="center">Observer Name</TableCell>
                <TableCell align="center">Observer Designation</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageCRUD.read &&
                assemblyObservations.length > 0 &&
                assemblyObservations.map((observation, index) => {
                  return (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {region}
                      </TableCell>
                      <TableCell align="center">{school}</TableCell>
                      <TableCell align="center">
                        {observation.AssemblyManager.TeacherName}
                      </TableCell>
                      <TableCell align="center">
                        {currentPageCRUD.create && (
                          <Button
                            contained
                            color="primary"
                            onClick={_handleScoreOpen(observation)}
                          >
                            Score
                          </Button>
                        )}
                      </TableCell>
                      {/* <TableCell align="center">
                        {observation.TeacherName}
                      </TableCell> */}
                      <TableCell align="center">
                        {observation.ServiceDuration}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(observation.AssemblyDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {observation.ObserverName}
                      </TableCell>
                      <TableCell align="center">
                        {observation.ObserverDesignation}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={handleClick(observation)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={handleAnchorClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {currentPageCRUD.delete && (
            <MenuItem onClick={_onDelete} color="error">
              Delete
            </MenuItem>
          )}

          {currentPageCRUD.update && (
            <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
          )}
        </Menu>
      </Stack>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              School Assembly Form
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onSubmit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              {/*<Grid item xs={12} md={12}>
                <Typography variant="span" color="error" component="h4">
                  Note: Please Select Class first before Subject.
                </Typography>
              </Grid>*/}
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="School Name"
                  name="SchoolID"
                  value={school}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Cluster Name"
                  name="ClusterID"
                  value={region}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Assembly Manager"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="AssemblyManagerID"
                  value={formData.AssemblyManagerID}
                  required
                  onChange={_handleOnChange}
                  size="small"
                >
                  {teachers.map((teacher, index) => {
                    return (
                      <MenuItem key={index} value={teacher.SchoolTeachersQId}>
                        {teacher.TeacherName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Excellent</TableCell>
                        <TableCell align="center">Good</TableCell>
                        <TableCell align="center">Better</TableCell>
                        <TableCell align="center">Normal</TableCell>
                        <TableCell align="center">Need Improvement</TableCell>
                        <TableCell align="right">Fail</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        //   key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          5
                        </TableCell>
                        <TableCell align="center">4</TableCell>
                        <TableCell align="center">
                          {/* {dayjs(participant.ArrivalDatetime).format("DD-MM-YYYY")} */}
                          3
                        </TableCell>
                        <TableCell align="center">2</TableCell>
                        <TableCell align="center">1</TableCell>
                        <TableCell align="right">0</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Assembly Observation Questions" />
              </Grid>
              <Grid item xs={12} md={12}>
                {variables.map((variable, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      spacing={2}
                      sx={{ mb: 2 }}
                      alignItems="center"
                    >
                      <Grid item xs={12} md={9}>
                        {/**/}
                        <Typography variant="body1" component="h4">
                          {variable.Caption}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {/**/}
                        <TextField
                          type="hidden"
                          size="small"
                          variant="outlined"
                          sx={{ display: "flex", flex: 1, display: "none" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="School Name"
                          name="VariablesDetailID"
                          value={variable.ID}
                        />
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Performance"
                          sx={{ display: "flex", flex: 1 }}
                          value={selectedVariables.Score}
                          variant="outlined"
                          name="Score"
                          required
                          onChange={(e) =>
                            _handleVariableChange(e, index, variable)
                          }
                          size="small"
                        >
                          <MenuItem value={5}>Excellent</MenuItem>
                          <MenuItem value={4}>Good</MenuItem>
                          <MenuItem value={3}>Better</MenuItem>
                          <MenuItem value={2}>Normal</MenuItem>
                          <MenuItem value={1}>Need Improvement</MenuItem>
                          <MenuItem value={0}>Fail</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Observation Detail" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select Teacher"
                      sx={{ display: "flex", flex: 1 }}
                      variant="outlined"
                      name="TeacherID"
                      value={formData.TeacherID}
                      required
                      onChange={_handleOnChange}
                      size="small"
                    >
                      {teachers.map((teacher, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={teacher.SchoolTeachersQId}
                          >
                            {teacher.TeacherName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        inputFormat="DD-MM-YYYY"
                        label="Assembly Date"
                        value={formData.AssemblyDate}
                        onChange={_handleAssemblyDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{ display: "flex", flex: 1 }}
                            size="small"
                            required
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Service Duration"
                      sx={{ display: "flex", flex: 1 }}
                      // value={formik.values.SubjectID}
                      variant="outlined"
                      name="ServiceDuration"
                      required
                      onChange={_handleOnChange}
                      size="small"
                    >
                      <MenuItem key="0" value="15 minutes">
                        15 minutes
                      </MenuItem>
                      <MenuItem key="1" value="30 minutes">
                        30 minutes
                      </MenuItem>
                      <MenuItem key="2" value="45 minutes">
                        45 minutes
                      </MenuItem>
                      <MenuItem key="3" value="60 minutes">
                        60 minutes
                      </MenuItem>
                    </TextField>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-teacher"
                      options={observers.map((item, index) => {
                        return {
                          // ...item,
                          TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                          label: `(${item.SchoolTeachersQId}) - ${
                            item.TeacherName
                          } - ${
                            item.School !== null && item.School.NameOfSchool
                          }`,
                          id: item.SchoolTeachersQId,
                        };
                      })}
                      value={selectedObserver}
                      onChange={_onObserverChange}
                      inputValue={selectInputObserver}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      onInputChange={_onObserverInputValue}
                      sx={{ display: "flex", flex: 1 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          key={params.id}
                          label="Observer Name"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={designation}
                      label="Observer Designation"
                      name="TeachersDesignation"
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      label="Observer Name"
                      name="ObserverName"
                      onChange={_handleOnChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      label="Observer Designation"
                      name="ObserverDesignation"
                      onChange={_handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/*<Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={12}>
                    <Typography variant="span" color="error" component="h4">
                      Note: Principal should observe one teacher per week
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>*/}
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Create Observation
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="md" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              School Assembly Form
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onEdit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              {/*<Grid item xs={12} md={12}>
                <Typography variant="span" color="error" component="h4">
                  Note: Please Select Class first before Subject.
                </Typography>
              </Grid>*/}
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="School Name"
                  name="SchoolID"
                  value={school}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Cluster Name"
                  name="ClusterID"
                  value={region}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Assembly Manager"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="AssemblyManagerID"
                  value={selectedAssemblyObservation.AssemblyManagerID}
                  required
                  onChange={_handleEditOnChange}
                  size="small"
                >
                  {teachers.map((teacher, index) => {
                    return (
                      <MenuItem key={index} value={teacher.SchoolTeachersQId}>
                        {teacher.TeacherName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Excellent</TableCell>
                        <TableCell align="center">Good</TableCell>
                        <TableCell align="center">Better</TableCell>
                        <TableCell align="center">Normal</TableCell>
                        <TableCell align="center">Need Improvement</TableCell>
                        <TableCell align="right">Fail</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        //   key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          5
                        </TableCell>
                        <TableCell align="center">4</TableCell>
                        <TableCell align="center">
                          {/* {dayjs(participant.ArrivalDatetime).format("DD-MM-YYYY")} */}
                          3
                        </TableCell>
                        <TableCell align="center">2</TableCell>
                        <TableCell align="center">1</TableCell>
                        <TableCell align="right">0</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Assembly Observation Questions" />
              </Grid>
              <Grid item xs={12} md={12}>
                {Object.keys(selectedAssemblyObservation).length > 0 &&
                  selectedAssemblyObservation.AssemblyObservationScore.map(
                    (variable, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={2}
                          sx={{ mb: 2 }}
                          alignItems="center"
                        >
                          <Grid item xs={12} md={9}>
                            {/**/}
                            <Typography variant="body1" component="h4">
                              {variable.Variable.Caption}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {/**/}
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Performance"
                              sx={{ display: "flex", flex: 1 }}
                              value={variable.Score}
                              variant="outlined"
                              name="Score"
                              required
                              onChange={(e) =>
                                _handleEditVariableChange(e, index)
                              }
                              size="small"
                            >
                              <MenuItem value={5}>Excellent</MenuItem>
                              <MenuItem value={4}>Good</MenuItem>
                              <MenuItem value={3}>Better</MenuItem>
                              <MenuItem value={2}>Normal</MenuItem>
                              <MenuItem value={1}>Need Improvement</MenuItem>
                              <MenuItem value={0}>Fail</MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Observation Detail" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select Teacher"
                      sx={{ display: "flex", flex: 1 }}
                      variant="outlined"
                      name="TeacherID"
                      value={selectedAssemblyObservation.TeacherID}
                      required
                      onChange={_handleEditOnChange}
                      size="small"
                    >
                      {teachers.map((teacher, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={teacher.SchoolTeachersQId}
                          >
                            {teacher.TeacherName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        inputFormat="DD-MM-YYYY"
                        label="Assembly Date"
                        value={selectedAssemblyObservation.AssemblyDate}
                        onChange={_handleEditAssemblyDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{ display: "flex", flex: 1 }}
                            size="small"
                            required
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Service Duration"
                      sx={{ display: "flex", flex: 1 }}
                      value={selectedAssemblyObservation.ServiceDuration}
                      variant="outlined"
                      name="ServiceDuration"
                      required
                      onChange={_handleEditOnChange}
                      size="small"
                    >
                      <MenuItem key="0" value="15 minutes">
                        15 minutes
                      </MenuItem>
                      <MenuItem key="1" value="30 minutes">
                        30 minutes
                      </MenuItem>
                      <MenuItem key="2" value="45 minutes">
                        45 minutes
                      </MenuItem>
                      <MenuItem key="3" value="60 minutes">
                        60 minutes
                      </MenuItem>
                    </TextField>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-teacher"
                      options={observers.map((item, index) => {
                        return {
                          TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                          label: `(${item.SchoolTeachersQId}) - ${
                            item.TeacherName
                          } - ${
                            item.School !== null && item.School.NameOfSchool
                          }`,
                          id: item.SchoolTeachersQId,
                        };
                      })}
                      value={selectedObserver}
                      onChange={_onObserverChange}
                      inputValue={selectInputObserver}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      onInputChange={_onObserverInputValue}
                      sx={{ display: "flex", flex: 1 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          key={params.id}
                          label="Observer Name"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={designation}
                      label="Observer Designation"
                      name="TeachersDesignation"
                    />
                  </Grid> */}
                <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      label="Observer Name"
                      name="ObserverName"
                      value={selectedAssemblyObservation.ObserverName}
                      onChange={_handleEditOnChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      value={selectedAssemblyObservation.ObserverDesignation}
                      label="Observer Designation"
                      name="ObserverDesignation"
                      onChange={_handleEditOnChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/*<Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={12}>
                    <Typography variant="span" color="error" component="h4">
                      Note: Principal should observe one teacher per week
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>*/}
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Update Observation
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openScore}
        onClose={handleScoreClose}
      >
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Teacher Observation Form (during class)
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Excellent</TableCell>
                    <TableCell align="center">Good</TableCell>
                    <TableCell align="center">Better</TableCell>
                    <TableCell align="center">Normal</TableCell>
                    <TableCell align="center">Need Improvement</TableCell>
                    <TableCell align="right">Fail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    //   key={String(index)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      5
                    </TableCell>
                    <TableCell align="center">4</TableCell>
                    <TableCell align="center">
                      {/* {dayjs(participant.ArrivalDatetime).format("DD-MM-YYYY")} */}
                      3
                    </TableCell>
                    <TableCell align="center">2</TableCell>
                    <TableCell align="center">1</TableCell>
                    <TableCell align="right">0</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <SectionDivider text="Scores" />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Question</TableCell>
                    <TableCell align="right">Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(selectedAssemblyObservation).length > 0 &&
                    selectedAssemblyObservation.AssemblyObservationScore.map(
                      (observation, index) => {
                        return (
                          <TableRow
                            key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {observation.Variable.Caption}
                            </TableCell>
                            <TableCell align="right">
                              {observation.Score}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleScoreClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
