import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default function AllSubjectTestResults() {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [results, setResults] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectVar, setSubjectVar] = useState([]);
  const [studentsBackup, setStudentsBackup] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [testType, setTestType] = useState("");
  const [formData, setFormData] = useState({
    class_id: "",
    session: "",
  });
  const [filterState, setFilterState] = useState("");
  useEffect(() => {
    _fetchClasses();
  }, []);

  const _fetchClasses = async () => {
    try {
      const { data } = await axiosInstance.get("/classes");
      setClasses(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData ===>", formData);
    const searchParams = new URLSearchParams();
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("school_id", schoolID);
    }
    if (accesLevel === "CLUSTER") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("school_id", schoolID);
    }
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolID = accessObject[0].SchoolId;
      searchParams.append("school_id", schoolID);
    }
    searchParams.append("class_id", formData.class_id);
    searchParams.append("session", formData.session);
    searchParams.append("testType", formData.testType);

    try {
      const { data, status } = await axiosInstance.get(
        "/reports/student-all-subject-test-results-report",
        {
          params: searchParams,
        }
      );

      if (status === 200) {
        setSubjects(Object.keys(data.data[0][0]));
        setStudentsBackup(data.data[0]);
        if (filterState == "") {
          setResults(data.data[0]);
        } else {
          let filtered = [];
          let newData = [...data.data[0]];
          filtered = newData.filter((x) => {
            return x.TestType == filterState;
          });
          setResults(filtered);
        }
      }
      console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(studentsBackup);
  }, [studentsBackup]);

  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "testType") {
      setTestType(value);
    }
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        from: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        to: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  function filterDataByValue(value) {
    let newData = [...studentsBackup];
    console.log(newData);
    let filtered = [];
    if (value != "ALL") {
      filtered = newData.filter((x) => {
        return x.TestType == String(value);
      });
    } else {
      filtered = newData;
    }
    setResults(filtered);
  }

  useEffect(() => {
    filterDataByValue(filterState);
  }, [filterState]);

  function sumOfSubjects(obj) {
    const total = Object.keys(obj)
      .map((subject) => {
        if (
          subject !== "sid" &&
          subject !== "studentname" &&
          subject !== "grno" &&
          subject !== "TestType" &&
          subject !== "TotalMaxMarks"
        ) {
          return obj[subject];
        }
      })
      .filter((item) => {
        if (item !== null && item !== undefined) {
          return item;
        }
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
    console.log("TotalTest", total);
    return total;
  }

  function calculateGrade(percentage) {
    if (percentage >= 90) {
      return "A+";
    } else if (percentage >= 80) {
      return "A";
    } else if (percentage >= 70) {
      return "B";
    } else if (percentage >= 60) {
      return "C";
    } else if (percentage >= 50) {
      return "D";
    } else {
      return "F";
    }
  }

  function handlePercentage(obj) {
    console.log("BRRRRRRRRRRRRRRRRRRRR", obj);

    const subjectKeys = Object.keys(obj).filter((key) => {
      return (
        key !== "sid" &&
        key !== "studentname" &&
        key !== "grno" &&
        key !== "TestType" &&
        key !== "TotalMaxMarks"
      );
    });

    const obtainedMarks = subjectKeys
      .map((subject) => {
        return obj[subject];
      })
      .filter((item) => item !== null && item !== undefined)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    let totalMarks = 0;
    Object.keys(obj).forEach((x) => {
      if (x === "TotalMaxMarks") {
        totalMarks += obj[x];
      }
    });
    // subjectKeys
    // .map((subject) => {
    //   const totalMaxMarks = obj[`${subject}TotalMaxMarks`];
    //   console.log(`Subject: ${subject}, TotalMaxMarks: ${totalMaxMarks}`);
    //   return totalMaxMarks;
    // })
    // .filter((item) => item !== null && item !== undefined)
    // .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    console.log("totalMarks", totalMarks);
    console.log("obtainedMarks", obtainedMarks);

    // Calculate percentage
    const percentage = ((obtainedMarks / totalMarks) * 100).toFixed(2);
    console.log("Percentage: ", percentage);

    calculateGrade(percentage);
    return percentage;
  }

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("Baithak-Report.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "student-strength-report.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "Report All Subject Test Result" }, {}];

    let table = [
      {
        A: "S.No",
        B: "Student Name",
        C: "Father Name",
        D: "GRNO",
        E: "Class in Admission",
        F: "Date of Admission",
        G: "Current Class",
      },
    ];

    students.length > 0 &&
      students.forEach((student, index) => {
        table.push({
          A: index + 1,
          B: student.StudentName,
          C: student.FathersName,
          D: student.GRNO,
          E: student.ClassModel ? student.ClassModel.ClassName : "",
          F: student.Date_Of_Addmission,
          G: student.ClassModel ? student.ClassModel.ClassName : "",
        });
      });

    table = [{ A: "Student Strength" }].concat(table).concat([""]);

    const finalData = [...title, ...table];

    // create workbook
    const wb = XLSX.utils.book_new();

    // create worksheet
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "report-student-strength");

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:G2",
      tbodyRange: `A3:G${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:G${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        // sheet.usedRange.style({
        //   fontFamily: "Arial",
        //   verticalAlignment: "center",
        // });

        sheet.column("A").width(15);
        sheet.column("B").width(20);
        sheet.column("C").width(20);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            All Subject Results of Students
          </Typography>
          {/*<Typography variant="subtitle2" component="span">
                        Select Date to generate a new Daily Entries Report
                      </Typography>*/}
        </Stack>
        <Box width={300}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/**/}
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Session"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="session"
            onChange={_handleOnChange}
            size="small"
          >
            <MenuItem value="2015-2016">2015-2016</MenuItem>
            <MenuItem value="2016-2017">2016-2017</MenuItem>
            <MenuItem value="2017-2018">2017-2018</MenuItem>
            <MenuItem value="2018-2019">2018-2019</MenuItem>
            <MenuItem value="2019-2020">2019-2020</MenuItem>
            <MenuItem value="2020-2021">2020-2021</MenuItem>
            <MenuItem value="2021-2022">2021-2022</MenuItem>
            <MenuItem value="2022-2023">2022-2023</MenuItem>
            <MenuItem value="2023-2024">2023-2024</MenuItem>
            <MenuItem value="2024-2025">2024-2025</MenuItem>
            <MenuItem value="2025-2026">2025-2026</MenuItem>
            <MenuItem value="2026-2027">2026-2027</MenuItem>
            <MenuItem value="2027-2028">2027-2028</MenuItem>
            <MenuItem value="2028-2029">2028-2029</MenuItem>
            <MenuItem value="2029-2030">2029-2030</MenuItem>
            <MenuItem value="2030-2031">2030-2031</MenuItem>
            <MenuItem value="2031-2032">2031-2032</MenuItem>
            <MenuItem value="2032-2033">2032-2033</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="standard-select-currency"
            select
            label="Class"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="class_id"
            onChange={_handleClassChange}
            size="small"
          >
            {classes.map((item, i) => {
              return (
                <MenuItem key={i} value={item.ID}>
                  {item.ClassName}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="standard-select-currency"
            select
            label="Select TestType"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="testType"
            onChange={_handleOnChange}
            value={testType}
            size="small"
          >
            <MenuItem
              value="WEEKLY"
              onClick={() => {
                setFilterState("WEEKLY");
              }}
            >
              WEEKLY
            </MenuItem>
            <MenuItem
              value="MID-TERM"
              onClick={() => {
                setFilterState("MID-TERM");
              }}
            >
              MID-TERM
            </MenuItem>
            <MenuItem
              value="MONTHLY"
              onClick={() => {
                setFilterState("MONTHLY");
              }}
            >
              MONTHLY
            </MenuItem>
            <MenuItem
              value="ANNUAL"
              onClick={() => {
                setFilterState("ANNUAL");
              }}
            >
              ANNUAL
            </MenuItem>
          </TextField>
        </Grid>
        {/*<Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Class"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="subject_id"
            onChange={_handleOnChange}
            size="small"
          >
            {subjects.map((sub, i) => {
              return (
                <MenuItem key={i} value={sub.ID}>
                  {sub.SubjectName}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>*/}
        <Grid item xs={12} md={4}>
          <LoadingButton
            //   loading={loading}
            //   disabled={loading}
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Get Student Results
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="my-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  {subjects.length > 0 &&
                    subjects.map((subject) =>
                      subject === "sid" ? (
                        ""
                      ) : (
                        <TableCell align="left">
                          {subject === "studentname"
                            ? "Student Name"
                            : subject === "grno"
                            ? "GRNO"
                            : subject}
                        </TableCell>
                      )
                    )}
                  <TableCell align="left">TotalObtainedMarks</TableCell>
                  <TableCell align="left">%</TableCell>
                  <TableCell align="left">Grade</TableCell>
                  <TableCell align="left">Examination</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.length > 0 &&
                  results.map((result, index) => {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">{result.grno}</TableCell>
                        <TableCell align="left">{result.studentname}</TableCell>
                        {Object.keys(result).map((subject) => {
                          if (
                            subject !== "sid" &&
                            subject !== "studentname" &&
                            subject !== "grno"
                          ) {
                            return (
                              <TableCell align="left">
                                {result[subject]}
                              </TableCell>
                            );
                          }
                        })}

                        <TableCell align="left">
                          {sumOfSubjects(result)}
                        </TableCell>
                        <TableCell align="left">
                          {handlePercentage(result)}
                        </TableCell>
                        <TableCell align="left">
                          {calculateGrade(handlePercentage(result))}
                        </TableCell>
                        <TableCell align="left">{result.TestType}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}
