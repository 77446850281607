import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import Modal from "@mui/material/Modal";
// import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";

export default function PIVOTResultTeacherClassObservation() {
  const [results, setResults] = useState([]);
  const [schoolList, setSchoolList] = React.useState([]);
  const [clusters, setClusters] = React.useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [formData, setFormData] = useState({
    dateFrom: dayjs().format("YYYY-MM-DD"),
    dateTo: dayjs().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    _onSubmit();
  }, []);

  const _onSubmit = async () => {
    console.log("formData ===>", formData);
    const searchParams = new URLSearchParams();
    if (accesLevel === "ADMIN") {
      const schoolid = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolid);
    }
    if (accesLevel === "SCHOOL") {
      const schoolid = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolid);
    }
    searchParams.append("datefrom", formData.dateFrom);
    searchParams.append("dateto", formData.dateTo);

    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/reports/pivot-result-teacher-class-observation-period",
          {
            params: searchParams,
          }
        );
        console.log("PIVOTResultTeacherClassObservation", data.data[0]);

        setResults(data.data[0]);
      }
      if (accesLevel === "SCHOOL") {
        const { data, status } = await axiosInstance.get(
          "/reports/pivot-result-teacher-class-observation-period",
          {
            params: searchParams,
          }
        );
        console.log("PIVOTResultTeacherClassObservation", data.data[0]);

        setResults(data.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dateFrom: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dateTo: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("PIVOT-Result-Teacher-Class-Observation.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "pivot-result-teacher-class-observation.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "PIVOT Result Teacher Class Observation" }, {}];

    let table = [
      {
        A: "S.No",
        B: "TeacherName",
        C: "Activities conducted effectively",
        D: "Appropriate Lesson Objectives.",
        E: "Appropriate selection of Activities",
        F: "Appropriate Selection of teaching aids / posters/materials",
        G: "Body language",
        H: "Effective Time distribution/management",
        I: "Eye contact",
        J: "Introduction of the Lesson/topic",
        K: "Methods & Strategies to achieve objectives.",
        L: "Questioning (by Teacher)",
        M: "Response & Motivation of the Students",
        N: "Selection According to Unit Planning",
        O: "Students Motivation",
        P: "Teacher Students Relation",
        Q: "Uses of teaching technique to meet the need of studets",
        R: "Voice control",
        S: "Class Name",
        T: "Subject Name",
        U: "Observation Date",
        V: ""
      },
    ];

    results.length > 0 &&
      results.map((item, index) => {
        table.push({
          A: item.S.No,
          B: item.TeacherName,
          C: item["Activities conducted effectively"],
          D: item["Appropriate Lesson Objectives."],
          E: item["Appropriate selection of Activities"],
          F: item["Appropriate Selection of teaching aids / posters/materials"],
          G: item["Body language"],
          H: item["Effective Time distribution/management"],
          I: item["Eye contact"],
          J: item["Introduction of the Lesson/topic"],
          K: item["Methods & Strategies to achieve objectives."],
          L: item["Questioning (by Teacher)"],
          M: item["Response & Motivation of the Students"],
          N: item["Selection According to Unit Planning"],
          O: item["Students Motivation"],
          P: item["Teacher Students Relation"],
          Q: item["Uses of teaching technique to meet the need of studets"],
          R: item["Voice control"],
          S: item["Class Name"],
          T: item["Subject Name"],
          U: item["Observation Date"],
        });
      });

    const finalData = [...title, ...table];
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(
      wb,
      sheet,
      "pivot-result-teacher-class-observation"
    );

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:U2",
      tbodyRange: `A3:U${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:U${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.column("A").width(15);
        sheet.column("B").width(20);
        sheet.column("C").width(20);
        sheet.column("D").width(20);
        sheet.column("E").width(20);
        sheet.column("F").width(20);
        sheet.column("G").width(20);
        sheet.column("H").width(20);
        sheet.column("I").width(20);
        sheet.column("J").width(20);
        sheet.column("K").width(20);
        sheet.column("L").width(20);
        sheet.column("M").width(20);
        sheet.column("N").width(20);
        sheet.column("O").width(20);
        sheet.column("P").width(20);
        sheet.column("Q").width(20);
        sheet.column("R").width(20);
        sheet.column("S").width(20);
        sheet.column("T").width(20);
        sheet.column("U").width(20);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  const style = {
    marginTop: "4%",
    marginLeft: "10%",
    marginRight: "10%",
    backgroundColor: "#ffffff",
    border: "none !important",
    p: 3,
  };

  const StyledPaper = styled(Paper)(({ theme }) => ({
    maxHeight: "80vh",
    overflowY: "auto",
  }));

  return (
    <Box>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Class Teacher Observation MATRIX Sheet
          </Typography>
        </Stack>
        <Box width={300}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/**/}
            </Grid>
          </Grid>
        </Box>
      </Stack>

      {/* PDF XLSX */}
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Date From"
              value={formData.dateFrom}
              name="dateFrom"
              required
              onChange={_handleDateFrom}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Date To"
              value={formData.dateTo}
              name="dateTo"
              required
              onChange={_handleDateTo}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LoadingButton
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Generate Report
          </LoadingButton>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack>
        <Typography variant="h6" component="h6">
          Score Legends
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle2" component="span">
          4 Excellent
        </Typography>
        <Typography variant="subtitle2" component="span">
          3 Good
        </Typography>
        <Typography variant="subtitle2" component="span">
          2 Better
        </Typography>
        <Typography variant="subtitle2" component="span">
          1 Need Improvement
        </Typography>
        <Typography variant="subtitle2" component="span">
          0 None
        </Typography>
      </Stack>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Stack
        sx={{ mb: 2, position: "relative" }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TableContainer
          sx={{ position: "absolute", top: "1px" }}
          component={Paper}
        >
          <Table sx={{ maxWidth: 850 }} aria-label="simple table" id="my-table">
            <TableHead>
              <TableRow>
                <TableCell align="left">S.No</TableCell>
                <TableCell align="center">Teacher Name</TableCell>
                <TableCell align="center">Subject Name</TableCell>
                <TableCell align="center">Class Name</TableCell>
                <TableCell align="center">Observation Date</TableCell>
                <TableCell align="center">
                  Activities conducted effectively
                </TableCell>
                <TableCell align="center">
                  Appropriate Lesson Objectives.
                </TableCell>
                <TableCell align="center">
                  Appropriate selection of Activities
                </TableCell>
                <TableCell align="center">
                  Appropriate Selection of teaching aids / posters/materials
                </TableCell>
                <TableCell align="center">Body language</TableCell>
                <TableCell align="center">
                  Effective Time distribution/management
                </TableCell>
                <TableCell align="center">Eye contact</TableCell>
                <TableCell align="center">
                  Introduction of the Lesson/topic
                </TableCell>
                <TableCell align="center">
                  Methods & Strategies to achieve objectives.
                </TableCell>
                <TableCell align="center">Questioning (by Teacher)</TableCell>
                <TableCell align="center">
                  Response & Motivation of the Students
                </TableCell>
                <TableCell align="center">
                  Selection According to Unit Planning
                </TableCell>
                <TableCell align="center">Students Motivation</TableCell>
                <TableCell align="center">Teacher Students Relation</TableCell>
                <TableCell align="center">
                  Uses of teaching technique to meet the need of studets
                </TableCell>
                <TableCell align="left">Voice control</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.length > 0 &&
                results.map((item, index) => (
                  <TableRow
                    key={String(index)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="center">{item.TeacherName}</TableCell>
                <TableCell align="center">{item.SubjectName}</TableCell>
                    <TableCell align="center">{item.ClassName}</TableCell>
                <TableCell align="center">{item.ObservationDate}</TableCell>
                    <TableCell align="center">
                      {item["Activities conducted effectively"]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Appropriate Lesson Objectives."]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Appropriate selection of Activities"]}
                    </TableCell>
                    <TableCell align="center">
                      {
                        item[
                          "Appropriate Selection of teaching aids / posters/materials"
                        ]
                      }
                    </TableCell>
                    <TableCell align="center">
                      {item["Body language"]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Effective Time distribution/management"]}
                    </TableCell>
                    <TableCell align="center">{item["Eye contact"]}</TableCell>
                    <TableCell align="center">
                      {item["Introduction of the Lesson/topic"]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Methods & Strategies to achieve objectives."]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Questioning (by Teacher)"]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Response & Motivation of the Students"]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Selection According to Unit Planning"]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Students Motivation"]}
                    </TableCell>
                    <TableCell align="center">
                      {item["Teacher Students Relation"]}
                    </TableCell>
                    <TableCell align="center">
                      {
                        item[
                          "Uses of teaching technique to meet the need of studets"
                        ]
                      }
                    </TableCell>
                    <TableCell align="center">
                      {item["Voice control"]}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}
