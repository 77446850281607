import React from "react";
import Box from "@mui/material/Box";
import {
  Chip,
  Button,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
  Snackbar,
  TextareaAutosize,
  Grid,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { getClasses } from "../ClassSubjects/Classes";
import useSession from "../../CustomHooks/useSession";
import axiosInstance from "../../Api/axios.instance";
import Alert from "@mui/material/Alert";
import MuiAlert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { getStudents } from "../Students/Students";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

function fetchStudents(schoolId, classId) {
  return new Promise(async (res, rej) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${classId}`
      );
      if (status === 200) {
        res(data.data);
      } else {
        res(null);
      }
    } catch (error) {
      rej(error);
    }
  });
}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const SectionDivider = ({ text, changeDate }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography
        component="span"
        variant="subtitle2"
        sx={{ mx: 1, display: "flex", alignItems: "center" }}
      >
        {text}
        <IconButton onClick={changeDate} size="small">
          <CalendarTodayOutlinedIcon fontSize="10" />
        </IconButton>
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

const AlertSnack = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
export default function FeeVoucher() {
  const currentDate = dayjs().format("YYYY-MM-DD");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showList, setShowList] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackVouchersOpen, setSnackVocuhersOpen] = React.useState(false);
  const [snackEditOpen, setSnackEditOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackVoucherMessage, setSnackVoucherMessage] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [dateOpen, setDateOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(false);
  const [selectedStudentAttendance, setSelectedStudentAttendance] =
    React.useState({});
  const [classes, setClasses] = React.useState([]);
  const [classWiseStudents, setClassWiseStudents] = React.useState([]);
  const [studentSelected, setStudentSelected] = React.useState([]);
  const [classesVocuhers, setClassesVocuhers] = React.useState([]);
  const [loadingClass, setLoadingClass] = React.useState(false);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [feeHead, setFeeHead] = React.useState("MONTHLY");
  const [students, setStudents] = React.useState([]);
  const [selectStudents, setSelectStudents] = React.useState([]);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [attendanceData, setAttendanceData] = React.useState([]);
  const [attendance, setAttendance] = React.useState([]);
  const [vouchers, setVouchers] = React.useState([]);
  const [vouchersData, setVouchersData] = React.useState([]);
  const [selectedVoucher, setSelectedVoucher] = React.useState([]);
  const [today, setToday] = React.useState(dayjs());
  const { currentPage } = useSession();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBox = Boolean(anchorEl);
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const { accesLevel, access } = useSession();
  React.useEffect(() => {
    console.log("vouchers", vouchers);
    console.log("currentDate", currentDate);
    console.log("classWiseStudents", classWiseStudents);
    _fetchVouchers(today, feeHead);
    _fetchClasses();
  }, []);
  React.useEffect(() => {
    if (selectedClass !== "") {
      if (
        accesLevel === "SCHOOL" ||
        accesLevel === "ADMIN" ||
        accesLevel === "CLUSTER"
      ) {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        _fetchStudentVouchers(schoolId, selectedClass);
      }
    }
    console.log("selectedClass", selectedClass);
  }, [selectedClass]);
  const _fetchVouchers = async (date, fee_head) => {
    console.log("DATE", date);
    try {
      if (accesLevel === "ADMIN") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        searchParams.append("date", dayjs(date).format("YYYY-MM-DD"));
        searchParams.append("fee_head", fee_head);
        const { status, data } = await axiosInstance.get(
          "/fees/current-month",
          {
            params: searchParams,
          }
        );
        console.log("al Vouchers data", data.data);
        const classData = data.data.map((item) => {
          return {
            index: item?.ClassModel?.indexId,
            class_id: item?.ClassID,
            class_name: item?.ClassModel?.ClassName,
            current_date: dayjs(item.voucher_created_at).format("YYYY-MM-DD"),
          };
        });
        const classWithoutDuplication = classData.filter(
          (ele, i) =>
            i ===
            classData.findIndex(
              (elem) =>
                elem.class_id === ele.class_id &&
                elem.index === ele.index &&
                elem.class_name === ele.class_name
            )
        );
        const sortedClass = classWithoutDuplication.sort(
          (a, b) => a.index - b.index
        );
        console.log("classData", classData);
        console.log("classWithoutDuplication", classWithoutDuplication);
        console.log("sortedClass", sortedClass);
        const classWiseVouchersData = sortedClass.map((x) => {
          return {
            ...x,
            vouchers: data.data.filter((y) => y.ClassID === x.class_id),
          };
        });
        console.log("classWiseAttendanceData", classWiseVouchersData);
        setClassesVocuhers(classWiseVouchersData);
        setVouchersData(data.data);
      }
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        searchParams.append("date", dayjs(date).format("YYYY-MM-DD"));
        searchParams.append("fee_head", fee_head);
        const { status, data } = await axiosInstance.get(
          "/fees/current-month",
          {
            params: searchParams,
          }
        );
        console.log("al Vouchers data", data.data);
        const classData = data.data.map((item) => {
          return {
            index: item?.ClassModel?.indexId,
            class_id: item?.ClassID,
            class_name: item?.ClassModel?.ClassName,
            current_date: dayjs(item.voucher_created_at).format("YYYY-MM-DD"),
          };
        });
        const classWithoutDuplication = classData.filter(
          (ele, i) =>
            i ===
            classData.findIndex(
              (elem) =>
                elem.class_id === ele.class_id &&
                elem.index === ele.index &&
                elem.class_name === ele.class_name
            )
        );
        const sortedClass = classWithoutDuplication.sort(
          (a, b) => a.index - b.index
        );
        console.log("classData", classData);
        console.log("classWithoutDuplication", classWithoutDuplication);
        console.log("sortedClass", sortedClass);
        const classWiseVouchersData = sortedClass.map((x) => {
          return {
            ...x,
            vouchers: data.data.filter((y) => y.ClassID === x.class_id),
          };
        });
        console.log("classWiseAttendanceData", classWiseVouchersData);
        setClassesVocuhers(classWiseVouchersData);
        setVouchersData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchSelectStudents = async () => {
    try {
      const schoolId =
        localStorage.getItem("UserAccess") !== null
          ? JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId
          : 0;
      const response = await getStudents(schoolId);
      setSelectStudents(response.data);
      setVouchers(
        response.data.map((student) => {
          return {
            name: student.StudentName,
            father: student.FathersName,
            class_id: student.ClassID,
            school_id: student?.School.SchoolId,
            sid: student.SID,
            Fee_Head: "",
            Amount: 0,
            Narration: "No Comments",
            DueDate: "0000-00-00",
            Paid: false,
            PaidDate: "0000-00-00",
            BookNumber: "---",
            ReceiptNumber: "---",
            date: today.format("YYYY-MM-DD"),
          };
        })
      );
      console.log("selectStudents", selectStudents);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchStudentVouchers = async (schoolId, classId) => {
    setLoadingClass(true);
    try {
      const response = await fetchStudents(schoolId, classId);
      console.log("Response before if", response);
      if (response !== null) {
        console.log("Response in if", response);
      } else {
        alert("no students found...");
      }
      setLoadingClass(false);
    } catch (error) {
      console.log(error);
      setLoadingClass(false);
    }
  };
  const _fetchClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  const getVouchers = () => {
    console.log("today", today);
    console.log("feeHead", feeHead);
    const data = dayjs(today).format("YYYY-MM-DD");
    setLoading((prevState) => !prevState);
    if (data && feeHead) {
      setLoading((prevState) => !prevState);
      _fetchVouchers(today, feeHead);
      handleSelectedDateClose();
      // setFeeHead("");
      setShowList(false);
    } else {
      setLoading((prevState) => !prevState);
    }
    // _fetchVouchers()
  };
  const handleClickOpen = () => {
    setFeeHead("");
    setSelectedClass("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateClickOpen = () => {
    setDateOpen(true);
  };

  const handleDateClose = () => {
    setDateOpen(false);
  };
  const handleClick = (item) => (event) => {
    console.log("ITEM", item);
    setAnchorEl(event.currentTarget);
    setSelectedVoucher(item);
    // returenedItem(item);
  };
  const handleCloseBox = () => {
    setAnchorEl(null);
  };
  const handleSelectedDateOpen = () => {
    setSelectedDate(true);
  };

  const handleEditChange = (e) => {
    setSelectedVoucher((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSelectedDateClose = () => {
    setSelectedDate(false);
  };
  const handleEditToggle = (paid) => (event) => {
    setSelectedVoucher((prevState) => {
      return {
        ...prevState,
        Paid: !paid,
      };
    });
  };
  const handleEditPaidDate = (type) => (event) => {
    setSelectedVoucher((prevState) => {
      return {
        ...prevState,
        [type]: event,
      };
    });
  };
  const handleEditDueDate = (type) => (event) => {
    setSelectedVoucher((prevState) => {
      return {
        ...prevState,
        [type]: event,
      };
    });
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleChange = async (e) => {
    setSelectedClass(e.target.value);
    _getStudentsByClass(e.target.value);
  };
  const _getStudentsByClass = async (class_id) => {
    try {
      const schoolId =
        localStorage.getItem("UserAccess") !== null
          ? JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId
          : 0;
      const { data, status } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${class_id}`
      );
      if (status === 200) {
        const response = data.data.map((student) => {
          return {
            name: student.StudentName,
            father: student.FathersName,
            class_id: student.ClassID,
            school_id: student.SchoolId,
            sid: student.SID,
            Fee_Head: "",
            Amount: 0,
            Narration: "No Comments",
            DueDate: "0000-00-00",
            Paid: false,
            PaidDate: "0000-00-00",
            BookNumber: "---",
            ReceiptNumber: "---",
            date: today.format("YYYY-MM-DD"),
            isSelected: false,
          };
        });
        setVouchers(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFeeHeadChange = async (e) => {
    if (e.target.value == "MONTHLY") {
      setShowList(false);
      _fetchSelectStudents();
      setFeeHead(e.target.value);
    } else {
      setShowList(true);
      setVouchers([]);
      setFeeHead(e.target.value);
    }
  };
  const handleToggle = (student) => (event) => {
    console.log("student toggle", student);
    setVouchers((prevState) => {
      return prevState.map((item) => {
        console.log("item toggle", item);
        if (item.sid === student.sid) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        }
        return item;
      });
    });
  };
  const _submitVouchers = () => {
    if (feeHead !== "MONTHLY") {
      const newVouchers = vouchers.filter(
        (voucher) => voucher.isSelected === true
      );
      const setFeeHeadInVouchers = newVouchers.map((voucher) => {
        return {
          ...voucher,
          Fee_Head: feeHead,
        };
      });
      console.log("setFeeHeadInVouchers", setFeeHeadInVouchers);
      setVouchers(setFeeHeadInVouchers);
      postVouchers(setFeeHeadInVouchers);
    } else {
      const setFeeHeadInVouchers = vouchers.map((voucher) => {
        return {
          ...voucher,
          date: today,
          Fee_Head: feeHead,
        };
      });
      setVouchers(setFeeHeadInVouchers);
      postVouchers(setFeeHeadInVouchers);
    }
  };

  const postVouchers = async (vouchers) => {
    try {
      setLoading((prevState) => !prevState);
      if (vouchersData.length > 0 && feeHead === "MONTHLY") {
        let voucherCreatedAt = new Date(vouchersData[0].voucher_created_at);
        let voucherDate = new Date(vouchers[0].date);
        let createdAtMonth = voucherCreatedAt.getMonth() + 1;
        let createdAtYear = voucherCreatedAt.getFullYear();
        let dateMonth = voucherDate.getMonth() + 1;
        let dateYear = voucherDate.getFullYear();
        if (createdAtMonth === dateMonth && createdAtYear === dateYear) {
          setSnackOpen(true);
          setLoading((prevState) => !prevState);
          return;
        }
      }
      const { data, status } = await axiosInstance.post("/fees", vouchers);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setOpen(false);
        setSnackVoucherMessage(data.message);
        setSnackVocuhersOpen(true);
        _fetchVouchers(today, feeHead);
      }
    } catch (error) {
      setSnackOpen(true);
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _onEdit = async (e) => {
    e.preventDefault();
    console.log("selectedVoucher ==>", selectedVoucher);
    try {
      const { status, data } = await axiosInstance.put(
        `/fees/${selectedVoucher.id}`,
        selectedVoucher
      );
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackEditOpen(true);
        _fetchVouchers(today, feeHead);
        handleEditClose();
        handleCloseBox();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _deleteVoucher = async () => {
    console.log("selectedVoucher ==>", selectedVoucher);
    try {
      const { status, data } = await axiosInstance.delete(
        `/fees/${selectedVoucher.id}`
      );
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackEditOpen(true);
        _fetchVouchers(today, feeHead);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  const snack_handleVouchersClose = () => {
    console.log("Snack bar Closed");
    setSnackVocuhersOpen(false);
  };
  const snack_handleEditClose = () => {
    console.log("Snack bar Closed");
    setSnackEditOpen(false);
  };
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Fee Management Module
          </Typography>
          <Typography variant="subtitle2" component="span">
            Click Generate Fee Voucher button to generate Fee Vouchers sheet
          </Typography>
        </Stack>
        {currentPageCRUD.create && (
          <Button variant="contained" onClick={handleClickOpen}>
            Generate Voucher
          </Button>
        )}
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <SectionDivider
        text={`Student Fee Vouchers for ${today.format("MMMM, YYYY")}`}
        changeDate={handleSelectedDateOpen}
      />
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Box sx={{ mt: 2, mb: 2 }}>
        {classesVocuhers.length > 0 ? (
          classesVocuhers.map((classes) => (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2">
                  <strong>
                    Vouchers for Class {`${classes.class_name}`}
                    <Chip
                      sx={{ ml: 1 }}
                      label={`Date: ${classes.current_date}`}
                      color="success"
                    />
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Class</TableCell>
                            <TableCell align="center">
                              Voucher Created At
                            </TableCell>
                            <TableCell align="center">Student</TableCell>
                            <TableCell align="center">Fee Head</TableCell>
                            <TableCell align="center">Narration</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Book Number</TableCell>
                            <TableCell align="center">Receipt Number</TableCell>
                            <TableCell align="center">Paid Date</TableCell>
                            <TableCell align="center">Due Date</TableCell>
                            <TableCell align="center">Paid</TableCell>
                            <TableCell align="right">Action</TableCell>
                            {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentPageCRUD.read && classes.vouchers.length > 0
                            ? classes.vouchers.map((voucher, index) => (
                                <TableRow
                                  key={String(index)}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {voucher.ClassModel &&
                                      voucher.ClassModel.ClassName}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {dayjs(voucher.voucher_created_at).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    {`${
                                      voucher.Student &&
                                      voucher.Student.StudentName
                                    } - ${
                                      voucher.Student &&
                                      voucher.Student.FathersName
                                    }`}
                                  </TableCell>
                                  <TableCell align="center">
                                    {voucher.Fee_Head}
                                  </TableCell>
                                  <TableCell align="center">
                                    {voucher.Narration}
                                  </TableCell>
                                  <TableCell align="center">
                                    {voucher.Amount}
                                  </TableCell>
                                  <TableCell align="center">
                                    {voucher.BookNumber}
                                  </TableCell>
                                  <TableCell align="center">
                                    {voucher.ReceiptNumber}
                                  </TableCell>
                                  <TableCell align="center">
                                    {voucher.PaidDate == null
                                      ? "Not Added Yet"
                                      : dayjs(voucher.PaidDate).format(
                                          "YYYY-MM-DD"
                                        )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {voucher.DueDate == null
                                      ? "Not Added Yet"
                                      : dayjs(voucher.DueDate).format(
                                          "YYYY-MM-DD"
                                        )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {voucher.Paid ? (
                                      <Chip
                                        sx={{ ml: 1 }}
                                        label="PAID"
                                        color="success"
                                      />
                                    ) : (
                                      <Chip
                                        sx={{ ml: 1 }}
                                        label="UNPAID"
                                        color="error"
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton onClick={handleClick(voucher)}>
                                      <MoreVertIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))
                            : ""}
                        </TableBody>
                      </Table>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openBox}
                        onClose={handleCloseBox}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {currentPageCRUD.delete && (
                          <MenuItem onClick={_deleteVoucher} color="error">
                            Delete
                          </MenuItem>
                        )}

                        {currentPageCRUD.update && (
                          <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
                        )}
                      </Menu>
                    </TableContainer>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Box component={Paper} sx={{ padding: 1, textAlign: "center" }}>
            <Typography variant="subtitle2" component="span">
              <strong>No Fee Voucher has been added yet!</strong>
            </Typography>
          </Box>
        )}
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle textAlign="center" variant="h6">
          Voucher Sheet
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            textAlign="center"
            variant="subtitle2"
            alignItems="center"
          >
            Voucher for {today.format("MMMM, YYYY")}{" "}
            <IconButton
              // onClick={handleDateClickOpen}
              size="small"
            >
              <CalendarTodayOutlinedIcon fontSize="8" />
            </IconButton>
          </DialogContentText>
          <Box sx={{ mb: 2, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <DialogContentText variant="subtitle2" color="secondary">
                Select any date of a month to create a Voucher for that 
                month.
                </DialogContentText>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="Set a new date"
                    value={today}
                    required
                    onChange={(e) => {
                      setToday(e);
                      // handleDateClose();
                      _fetchStudentVouchers(dayjs(e).format("YYYY-MM-DD"));
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1, mr: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Fee Head"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="Fee_Head"
                  // value={formik.values.TeacherID}
                  required
                  onChange={handleFeeHeadChange}
                  size="small"
                >
                  <MenuItem value="MONTHLY">Monthly Fee</MenuItem>
                  <MenuItem value="ADMISSION">Admission Fee</MenuItem>
                  <MenuItem value="EXAM">Exam Fee</MenuItem>
                  <MenuItem value="ANNUAL">Annual Fee</MenuItem>
                  <MenuItem value="BOOKS">Books</MenuItem>
                  <MenuItem value="TC">TC</MenuItem>
                </TextField>
              </Grid>
              {showList && (
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      value={selectedClass}
                      label="Select Class"
                      onChange={handleChange}
                      endAdornment={
                        loadingClass ? <CircularProgress size={20} /> : null
                      }
                    >
                      <MenuItem value="">Select A Class</MenuItem>
                      {classes.map((item, index) => (
                        <MenuItem key={String(index)} value={item.ID}>
                          {item.ClassName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box sx={{ mt: 2, mb: 1 }}>
            <List sx={{ width: "100%" }}>
              {vouchers.length > 0 &&
                feeHead !== "MONTHLY" &&
                vouchers.map((student, index) => (
                  <ListItem key={String(index)}>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary={`${student.name} - ${student.father}`}
                    />
                    <Switch
                      edge="end"
                      onChange={handleToggle(student)}
                      checked={student.isSelected}
                      inputProps={{
                        "aria-labelledby": "switch-list-label-wifi",
                      }}
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={_submitVouchers}
          >
            Generate
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog onClose={handleDateClose} open={dateOpen} maxWidth="sm" fullWidth>
        <DialogContent>
          <DialogTitle variant="body2">Select new date</DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Select Fee Head"
                sx={{ display: "flex", flex: 1 }}
                variant="outlined"
                name="Fee_Head"
                // value={formik.values.TeacherID}
                required
                onChange={handleFeeHeadChange}
                size="small"
              >
                <MenuItem value="MONTHLY">Monthly Fee</MenuItem>
                {/*<MenuItem value="ADMISSION">Admission Fee</MenuItem>
                  <MenuItem value="EXAM">Exam Fee</MenuItem>
                  <MenuItem value="ANNUAL">Annual Fee</MenuItem>
                  <MenuItem value="BOOKS">Books</MenuItem>
                  <MenuItem value="TC">TC</MenuItem>*/}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ p: 4 }}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="Set a new date"
                    value={today}
                    required
                    onChange={(e) => {
                      setToday(e);
                      handleDateClose();
                      _fetchVouchers(dayjs(e).format("YYYY-MM-DD"));
                      // setAttendance((prevState) => {
                      //   return prevState.map((item) => {
                      //     return {
                      //       ...item,
                      //       date: dayjs(e).format("YYYY-MM-DD"),
                      //     };
                      //   });
                      // });
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1, mr: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDateClose}>Cancel</Button>
          <LoadingButton
          // loading={loading}
          // disabled={loading}
          // onClick={_submitVouchers}
          >
            Generate
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleSelectedDateClose}
        open={selectedDate}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle variant="body2">Select new date</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ mt: 1 }}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="Set a new date"
                    value={today}
                    onChange={(e) => {
                      setToday(e);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1, mr: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Select Fee Head"
                sx={{ display: "flex", flex: 1, mt: 1 }}
                variant="outlined"
                name="Fee_Head"
                // value={formik.values.TeacherID}
                required
                onChange={handleFeeHeadChange}
                size="small"
              >
                <MenuItem value="MONTHLY">Monthly Fee</MenuItem>
                <MenuItem value="ADMISSION">Admission Fee</MenuItem>
                <MenuItem value="EXAM">Exam Fee</MenuItem>
                <MenuItem value="ANNUAL">Annual Fee</MenuItem>
                <MenuItem value="BOOKS">Books</MenuItem>
                <MenuItem value="TC">TC</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSelectedDateClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={getVouchers}
          >
            get vouchers
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Update Student Fee Voucher
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onEdit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                <Typography>
                  <Typography variant="span" color="error" component="h4">
                    Voucher for:{" "}
                  </Typography>
                  {`${
                    selectedVoucher.Student &&
                    selectedVoucher.Student.StudentName.toUpperCase()
                  } - ${
                    selectedVoucher.Student &&
                    selectedVoucher.Student.FathersName.toUpperCase()
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ mt: 1, mb: 1 }}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        size="small"
                        label="Amount"
                        variant="outlined"
                        value={selectedVoucher.Amount}
                        sx={{ display: "flex", flex: 1 }}
                        name="Amount"
                        type="number"
                        required
                        onChange={handleEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        label="Book Number"
                        variant="outlined"
                        value={selectedVoucher.BookNumber}
                        sx={{ display: "flex", flex: 1 }}
                        name="BookNumber"
                        type="text"
                        required
                        onChange={handleEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        label="Receipt Number"
                        variant="outlined"
                        value={selectedVoucher.ReceiptNumber}
                        sx={{ display: "flex", flex: 1 }}
                        name="ReceiptNumber"
                        type="text"
                        required
                        onChange={handleEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Paid Date"
                          value={selectedVoucher.PaidDate}
                          onChange={handleEditPaidDate("PaidDate")}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              required
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Due Date"
                          value={selectedVoucher.DueDate}
                          onChange={handleEditDueDate("DueDate")}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              required
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Narration"
                          name="Narration"
                          value={selectedVoucher.Narration}
                          onChange={handleEditChange}
                          style={{ width: "100%", padding: 15 }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <List
                        sx={{
                          width: "100%",
                          padding: "0px",
                        }}
                        component={Paper}
                      >
                        <ListItem>
                          <ListItemText
                            id="switch-list-label-wifi"
                            primary={"PAID"}
                          />
                          <Switch
                            edge="end"
                            onChange={handleEditToggle(selectedVoucher.Paid)}
                            checked={selectedVoucher.Paid}
                            inputProps={{
                              "aria-labelledby": "switch-list-label-wifi",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
          <Button onClick={_onEdit}>Update</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <AlertSnack
          onClose={snack_handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          This month already has Vouchers of students!
        </AlertSnack>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackVouchersOpen}
        autoHideDuration={6000}
        onClose={snack_handleVouchersClose}
      >
        <AlertSnack
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackVoucherMessage}
        </AlertSnack>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackEditOpen}
        autoHideDuration={6000}
        onClose={snack_handleEditClose}
      >
        <AlertSnack
          onClose={snack_handleEditClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </AlertSnack>
      </Snackbar>
    </Box>
  );
}
