import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default function ClassWiseStudents() {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");

  useEffect(() => {
    _fetchClasses();
  }, []);
  const _fetchClasses = async () => {
    try {
      const response = await getClasses();
      // console.log("Classes", response);
      setClasses(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchClassWiseStudents = async (schooId, classId) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/reports/class-wise-students/${schooId}/${classId}`
      );
      console.log("setStudents", data.data);
      if (status === 200) {
        const sortedStudents = data.data.sort((a, b) => {
          if (a.Gender === "Female" && b.Gender === "Male") return -1;
          if (a.Gender === "Male" && b.Gender === "Female") return 1;
          return 0;
        });
        setStudents(sortedStudents);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _handleClassChange = (e) => {
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      _fetchClassWiseStudents(schoolID, e.target.value);
    }
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolID = accessObject[0].SchoolId;
      console.log("school id", schoolID);
      _fetchClassWiseStudents(schoolID, e.target.value);
    }
    if (accesLevel === "CLUSTER") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      _fetchClassWiseStudents(schoolID, e.target.value);
    }
  };

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("Baithak-Report.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "student-strength-report.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "Report Student Strength Class Wise" }, {}];

    let table = [
      {
        A: "S.No",
        B: "Student Name",
        C: "Father Name",
        D: "GRNO",
        E: "Class in Admission",
        F: "Date of Admission",
        G: "Current Class",
        G: "Current Class",
        G: "Gender",
      },
    ];

    students.length > 0 &&
      students.forEach((student, index) => {
        table.push({
          A: index + 1,
          B: student.StudentName,
          C: student.FathersName,
          D: student.GRNO,
          E: student.ClassAtTheTimeOfAdmissionModel
            ? student.ClassAtTheTimeOfAdmissionModel.ClassName
            : "",
          F: student.Date_Of_Addmission,
          G: student.ClassModel ? student.ClassModel.ClassName : "",
          H: student.Gender,
        });
      });

    table = [{ A: "Student Strength" }].concat(table).concat([""]);

    const finalData = [...title, ...table];

    // create workbook
    const wb = XLSX.utils.book_new();

    // create worksheet
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "report-student-strength");

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:H2",
      tbodyRange: `A3:H${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:H${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        // sheet.usedRange.style({
        //   fontFamily: "Arial",
        //   verticalAlignment: "center",
        // });

        sheet.column("A").width(15);
        sheet.column("B").width(20);
        sheet.column("C").width(20);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);
        sheet.column("H").width(15);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Student Strength Class Wise
          </Typography>
          {/*<Typography variant="subtitle2" component="span">
              Select Date to generate a new Daily Entries Report
            </Typography>*/}
        </Stack>
        <Box width={300}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                id="standard-select-currency"
                select
                label="Class"
                sx={{ display: "flex", flex: 1 }}
                variant="outlined"
                // value={selectedTest.ClassID}
                name="ClassID"
                onChange={_handleClassChange}
                size="small"
              >
                {classes.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.ID}>
                      {item.ClassName}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="my-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">Student Name</TableCell>
                  <TableCell align="left">Father Name</TableCell>
                  <TableCell align="left">GRNO</TableCell>
                  {/*<TableCell align="center">Age</TableCell>*/}
                  <TableCell align="left">Class in Admission</TableCell>
                  <TableCell align="left">Date of Admission</TableCell>
                  <TableCell align="left">Current Class</TableCell>
                  <TableCell align="left">Gender</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.length > 0 &&
                  students.map((student, index) => {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">
                          {student.StudentName}
                        </TableCell>
                        <TableCell align="left">
                          {student.FathersName}
                        </TableCell>
                        <TableCell align="left">{student.GRNO}</TableCell>
                        <TableCell align="left">
                          {student.ClassAtTheTimeOfAdmissionModel
                            ? student.ClassAtTheTimeOfAdmissionModel.ClassName
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {student.Date_Of_Addmission}
                        </TableCell>
                        <TableCell align="left">
                          {student.ClassModel
                            ? student.ClassModel.ClassName
                            : ""}
                        </TableCell>
                        <TableCell align="left">{student.Gender}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}
