import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default function StudentTestResultsReport() {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentsBackup, setStudentsBackup] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectVar, setSubjectVar] = useState([]);
  const [testType, setTestType] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [testFilters, setTestFilters] = useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [formData, setFormData] = useState({
    school_id: "",
    class_id: "",
    session: "",
  });

  useEffect(() => {
    _fetchClasses();
  }, []);

  const _fetchClasses = async () => {
    try {
      const { data } = await axiosInstance.get("/classes");
      setClasses(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData ===>", formData);
    const searchParams = new URLSearchParams();
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("school_id", schoolID);
    }
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolID = accessObject[0].SchoolId;
      searchParams.append("school_id", schoolID);
    }
    if (accesLevel === "CLUSTER") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("school_id", schoolID);
    }
    searchParams.append("class_id", formData.class_id);
    searchParams.append("subject_id", formData.subject_id);
    searchParams.append("session", formData.session);
    try {
      const { data, status } = await axiosInstance.get(
        "/reports/student-test-results",
        {
          params: searchParams,
        }
      );
      const subjectVariations = data.data.map((test) => {
        return {
          SubjectVariation: test.SubjectVariation,
        };
      });
      console.log("subjectVariations", subjectVariations);

      const testType = data.data.map((test) => {
        return {
          TestType: test.Testype,
        };
      });
      console.log("test type", testType);

      const testResults = data.data.map((test) => {
        return {
          TestResults: test.TestResults.map((obj) => {
            return {
              ...obj,
              MaxMarks: test.MaxMarks,
              SubjectVariation: test.SubjectVariation,
              Testype: test.Testype,
            };
          }),
        };
      });
      console.log("testResults", testResults);
      const getkeyValue = subjectVariations.map((sub) => sub.SubjectVariation);
      console.log("getkeyValue", getkeyValue);
      let listWithoutDuplicates = [...new Set(getkeyValue)];
      console.log("listWithoutDuplicates", listWithoutDuplicates);
      console.log("subjectVariations", listWithoutDuplicates);

      const getTestType = testType.map((sub) => sub.Testype);
      console.log("getkeyValue", getTestType);
      let listWithoutDuplicatesTestType = [...new Set(getTestType)];
      console.log(
        "listWithoutDuplicatesTestType",
        listWithoutDuplicatesTestType
      );
      console.log("Testype", listWithoutDuplicatesTestType);

      let compileData = testResults.map((t) => t.TestResults).flat();
      console.log("compileData", compileData);
      let resultArray = [];

      compileData.forEach((item) => {
        let sid = item.SID;
        let SubjectVariation = item.SubjectVariation;
        let ObtainedMarks = item.ObtainedMarks;
        let MaxMarks = item.MaxMarks;
        let Testype = item.Testype;

        let studentObject = {
          SID: sid,
          StudentName: item.Student && item.Student.StudentName,
          GRNO: item.Student && item.Student.GRNO,
          Testype: Testype,
        };

        let existingEntry = resultArray.find((entry) => entry.sid === sid);
        console.log("existingEntry", existingEntry);

        if (!existingEntry) {
          let newEntry = {
            sid: sid,
            ObtainedMarks: [],
            MaxMarks: [],
            StudentName: studentObject.StudentName,
            GRNO: studentObject.GRNO,
            TotalObtainedMarks: 0,
            TotalMaxMarks: 0,
            Testype: Testype,
          };
          resultArray.push(newEntry);
          existingEntry = newEntry;
        }

        existingEntry.ObtainedMarks.push({
          [`${SubjectVariation}`]: ObtainedMarks,
        });
        existingEntry["TotalObtainedMarks"] =
          existingEntry.ObtainedMarks.reduce(function (sum, item) {
            var key = Object.keys(item)[0];
            return sum + item[key];
          }, 0);
        existingEntry.MaxMarks.push(MaxMarks);
        existingEntry["TotalMaxMarks"] = existingEntry.MaxMarks.reduce(
          function (a, b) {
            return a + b;
          },
          0
        );
      });

      // Extracting unique keys from ObtainedMarks
      var uniqueKeys = [
        ...new Set(
          resultArray.flatMap((obj) =>
            obj.ObtainedMarks.flatMap((obj) => Object.keys(obj))
          )
        ),
      ];

      // Adding missing keys with value 0
      resultArray.forEach((obj) => {
        var obtainedMarks = obj.ObtainedMarks;
        uniqueKeys.forEach((key) => {
          var found = obtainedMarks.some((item) => item.hasOwnProperty(key));
          if (!found) {
            obtainedMarks.push({ [key]: 0 });
          }
        });
      });

      console.log("resultArray", resultArray);
      if (status === 200) {
        setSubjectVar(listWithoutDuplicates);
        setTestType(listWithoutDuplicatesTestType);
        console.log();
        setStudents(resultArray);
        setStudentsBackup(resultArray);
      }
      let filterSet = new Set([]);
      filterSet.add("ALL");
      data.data.forEach((d) => {
        filterSet.add(d.Testype);
      });
      let filterSetAr = Array.from(filterSet);
      let filterSetArray = [];
      filterSetAr.forEach((d) => {
        filterSetArray.push({
          value: d,
          label: d,
        });
      });
      setTestFilters(filterSetArray);
      console.log(filterSetArray);
      // console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  function filterDataByValue(value) {
    console.log(value);
    let newData = [...studentsBackup];
    console.log(newData);
    let filtered = [];
    if (value != "ALL") {
      filtered = newData.filter((x) => {
        return x.Testype == String(value);
      });
    } else {
      filtered = newData;
    }
    console.log(filtered);
    setStudents(filtered);
  }

  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${e.target.value}`
      );
      console.log("subjects", data.data);
      if (status === 200) {
        setSubjects(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        from: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        to: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  function calculateGrade(percentage) {
    if (percentage >= 90) {
      return "A+";
    } else if (percentage >= 80) {
      return "A+";
    } else if (percentage >= 70) {
      return "A";
    } else if (percentage >= 60) {
      return "B";
    } else if (percentage >= 50) {
      return "C";
    } else if (percentage >= 40) {
      return "D";
    } else {
      return "F";
    }
  }

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("Baithak-Report.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "student-strength-report.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "Report Student Test Result" }, {}];

    let table = [
      {
        A: "S.No",
        B: "Student Name",
        C: "Father Name",
        D: "GRNO",
        E: "Class in Admission",
        F: "Date of Admission",
        G: "Current Class",
      },
    ];

    students.length > 0 &&
      students.forEach((student, index) => {
        table.push({
          A: index + 1,
          B: student.StudentName,
          C: student.FathersName,
          D: student.GRNO,
          E: student.ClassModel ? student.ClassModel.ClassName : "",
          F: student.Date_Of_Addmission,
          G: student.ClassModel ? student.ClassModel.ClassName : "",
        });
      });

    table = [{ A: "Student Strength" }].concat(table).concat([""]);

    const finalData = [...title, ...table];

    // create workbook
    const wb = XLSX.utils.book_new();

    // create worksheet
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "report-student-strength");

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:G2",
      tbodyRange: `A3:G${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:G${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        // sheet.usedRange.style({
        //   fontFamily: "Arial",
        //   verticalAlignment: "center",
        // });

        sheet.column("A").width(15);
        sheet.column("B").width(20);
        sheet.column("C").width(20);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Student Test Results of a single subject with variation
          </Typography>
          {/*<Typography variant="subtitle2" component="span">
                      Select Date to generate a new Daily Entries Report
                    </Typography>*/}
        </Stack>
        <Box width={300}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/**/}
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Session"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="session"
            onChange={_handleOnChange}
            size="small"
          >
            <MenuItem value="2015-2016">2015-2016</MenuItem>
            <MenuItem value="2016-2017">2016-2017</MenuItem>
            <MenuItem value="2017-2018">2017-2018</MenuItem>
            <MenuItem value="2018-2019">2018-2019</MenuItem>
            <MenuItem value="2019-2020">2019-2020</MenuItem>
            <MenuItem value="2020-2021">2020-2021</MenuItem>
            <MenuItem value="2021-2022">2021-2022</MenuItem>
            <MenuItem value="2022-2023">2022-2023</MenuItem>
            <MenuItem value="2023-2024">2023-2024</MenuItem>
            <MenuItem value="2024-2025">2024-2025</MenuItem>
            <MenuItem value="2025-2026">2025-2026</MenuItem>
            <MenuItem value="2026-2027">2026-2027</MenuItem>
            <MenuItem value="2027-2028">2027-2028</MenuItem>
            <MenuItem value="2028-2029">2028-2029</MenuItem>
            <MenuItem value="2029-2030">2029-2030</MenuItem>
            <MenuItem value="2030-2031">2030-2031</MenuItem>
            <MenuItem value="2031-2032">2031-2032</MenuItem>
            <MenuItem value="2032-2033">2032-2033</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Class"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="class_id"
            onChange={_handleClassChange}
            size="small"
          >
            {classes.map((item, i) => {
              return (
                <MenuItem key={i} value={item.ID}>
                  {item.ClassName}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Subject"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="subject_id"
            onChange={_handleOnChange}
            size="small"
          >
            {subjects.map((sub, i) => {
              return (
                <MenuItem key={i} value={sub.ID}>
                  {sub.SubjectName}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Test Type"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="subject_id"
            onChange={_handleOnChange}
            size="small"
          >
            {testFilters.map((filter, i) => {
              return (
                <MenuItem
                  key={i}
                  value={filter.value}
                  onClick={() => {
                    filterDataByValue(filter.value);
                  }}
                >
                  {filter.value}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <LoadingButton
            //   loading={loading}
            //   disabled={loading}
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Get Student Results
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="my-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">Student Name</TableCell>
                  <TableCell align="left">GRNO</TableCell>
                  {subjectVar.length > 0 &&
                    subjectVar.map((subvar) => (
                      <TableCell align="left">{subvar}</TableCell>
                    ))}
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="left">%</TableCell>
                  <TableCell align="left">Grade</TableCell>
                  <TableCell align="left">Examination</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.length > 0 &&
                  students.map((student, index) => {
                    let percentage = Number(
                      (
                        (student.TotalObtainedMarks / student.TotalMaxMarks) *
                        100
                      ).toFixed(2)
                    );
                    // console.log("percentage", percentage);
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">
                          <strong>{student.StudentName.toUpperCase()}</strong>
                        </TableCell>
                        <TableCell align="left">{student.GRNO}</TableCell>

                        {subjectVar.map((key) => {
                          const obtainedMark = student.ObtainedMarks.find(
                            (mark) => mark.hasOwnProperty(key)
                          );
                          return (
                            <TableCell align="left" key={key}>
                              {obtainedMark ? obtainedMark[key] : 0}
                            </TableCell>
                          );
                        })}
                        <TableCell align="left">
                          {student.TotalMaxMarks}
                        </TableCell>
                        <TableCell align="left">{percentage}</TableCell>
                        <TableCell align="left">
                          {calculateGrade(percentage)}
                        </TableCell>
                        <TableCell align="left">{student.Testype}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}
