import {
  Box,
  Button,
  FormControl,
  Stack,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import useSession from "../../CustomHooks/useSession";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useLocation } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SyllabusAddOrView() {
  const { state: locationState } = useLocation();
  const { subject, create, read, update, IsDelete } = locationState;
  console.log("subject ===?", locationState.subject);
  const [syllabusList, setSyllabusList] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [clusterId, setClusterId] = useState(null);
  const [schoolList, setSchoolList] = useState([]);
  const [snackMessage, setSnackMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  //   const [subject, setSubject] = useState(null);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completionDate, setCompletionDate] = useState("");
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [syllabus, setSyllabus] = useState({
    ProposedCompletionDate: dayjs(),
    StartingDate: dayjs(),
    ChaperName: "",
    RegionID: null,
  });
  const [classList, setClassList] = useState([]);
  const [className, setClassName] = useState([]);

  useEffect(() => {
    if (subject.Classid != undefined) {
      _fetchClasses();
    }
    console.log("TESTING", classList[0]?.ClassModel?.ClassName);
  }, [subject]);

  const _fetchClasses = async () => {
    try {
      if (
        accesLevel === "SCHOOL" ||
        accesLevel === "ADMIN" ||
        accesLevel === "CLUSTER"
      ) {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const { data } = await axiosInstance.get(
          `/class-subjects/by-school/${schoolId}`
        );
        setClassList(data.data);
        console.log("TESTING II", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    console.log("currentPageCRUD", currentPageCRUD[0]);
    const clusterID = JSON.parse(localStorage.getItem("UserAccess"))[0]
      .RegionId;
    setClusterId(clusterID);

    if (accesLevel === "CLUSTER") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);

            const cluster = clusterID
              ? data.data.filter((item) => item.RegionId === clusterID)
              : [];

            setSchoolList(cluster[0].School);
            setSyllabus((prevState) => {
              return {
                ...prevState,
                RegionID: clusterID,
              };
            });
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (subject !== null) {
      _fetchSyllabus();
    }
  }, [subject]);

  const getSyllabusByRegion = async () => {
    const RegionID = JSON.parse(localStorage.getItem("UserAccess"))[0].RegionId;
    console.log("RegionID", RegionID);
    console.log("subject.Classid", subject.Classid);
    console.log("subject.SubjectID", subject.SubjectID);
    try {
      const { data } = await axiosInstance.get(
        `/syllabus/get-syllabus-by-cluster/${RegionID}/${subject.Classid}/${subject.SubjectID}`
      );
      setSyllabusList(data.data);
      console.log("syllabusList", syllabusList);
    } catch (error) {
      console.log(error);
    }
  };

  const getSyllabusBySchool = async () => {
    try {
      if (accesLevel === "ADMIN" || accesLevel === "CLUSTER") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data } = await axiosInstance.get(
          `/syllabus/get-syllabus-by-school/${schoolID}/${subject.Classid}/${subject.SubjectID}`
        );
        setSyllabusList(data.data);
        console.log("syllabusList", syllabusList);
      }
      if (accesLevel === "SCHOOL" || accesLevel === "CLUSTER") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        const { data } = await axiosInstance.get(
          `/syllabus/get-syllabus-by-school/${schoolID}/${subject.Classid}/${subject.SubjectID}`
        );
        setSyllabusList(data.data);
        console.log("syllabusList", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const _fetchSyllabus = async () => {
  //   try {
  //     const { data, status } = await axiosInstance.get(
  //       `/syllabus/get-syllabus/${subject.Classid}/${subject.SubjectID}`
  //     );
  //     if (status === 200) {
  //       setSyllabusList(data.data);
  //       console.log("syllabusList ===>", data.data);
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  const _fetchSyllabus = async () => {
    if (accesLevel === "SCHOOL") {
      getSyllabusBySchool();
    }
    if (accesLevel === "ADMIN" || accesLevel === "CLUSTER") {
      getSyllabusByRegion();
    }
  };

  const _onRadioChange = async (e) => {
    console.log("on radio change", e.target.value);
    if (e.target.value === "REGION") {
      getSyllabusByRegion();
    } else if (e.target.value === "SCHOOL") {
      getSyllabusBySchool();
    } else {
      return;
    }
  };

  const _onFormValue = (e) => {
    setSyllabus((prevState) => {
      return {
        ...prevState,
        ChaperName: e.target.value,
      };
    });
  };
  const _setDateValue = (type) => (event) => {
    setSyllabus((prevStat) => {
      return {
        ...prevStat,
        [type]: event,
      };
    });
  };
  const _setEditDateValue = (type) => (event) => {
    setSelectedSyllabus((prevStat) => {
      return {
        ...prevStat,
        [type]: event.$d,
      };
    });
  };

  const _handleOnAdminClusterChange = (e) => {
    const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    console.log("cluster", cluster);
    console.log("schools", cluster[0].School);
    setSchoolList(cluster[0].School);
    setSyllabus((prevState) => {
      return {
        ...prevState,
        RegionID: e.target.value,
      };
    });
  };

  const _handleOnChange = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        ChaperName: e.target.value,
      };
    });
  };
  const _handlePCD = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        ProposedCompletionDate: dayjs(e.$d).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleCD = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        CompletionDate: dayjs(e.$d).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleCDOnUpdate = (id) => async (event) => {
    try {
      setSyllabusList((prevState) => {
        return prevState.map((item, index) => {
          if (item.id === id) {
            return {
              ...item,
              CompletionDate: event,
            };
          }
          return item;
        });
        // CompletionDate: dayjs(e.$d).format("YYYY-MM-DD")
      });
      const { data } = await axiosInstance.put(
        `/syllabus/completion-date/${id}`,
        { CompletionDate: event }
      );
      if (Object.keys(data).length > 0) {
        _fetchSyllabus();
      }
      console.log(data);
      setLoading(false);
    } catch (error) {}
  };
  const handleModalClose = () => setOpenModal(false);
  const _handleSD = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        StartingDate: dayjs(e.$d).format("YYYY-MM-DD"),
      };
    });
  };

  const _submitSyllabus = async () => {
    const { ChaperName, StartingDate, ProposedCompletionDate } = syllabus;
    try {
      if (accesLevel === "ADMIN") {
        console.log("syllabus", syllabus);
        console.log("School List", schoolList);

        const schoolData = schoolList.map((school) => {
          return {
            ChaperName: syllabus.ChaperName,
            ProposedCompletionDate: dayjs(ProposedCompletionDate).format(
              "YYYY-MM-DD"
            ),
            StartingDate: dayjs(StartingDate).format("YYYY-MM-DD"),
            SchoolID: school.SchoolId,
            RegionID: syllabus.RegionID,
          };
        });

        const syllabusData = {
          schoolData,
          SubjectID: subject.SubjectID,
          Classid: subject.Classid,
        };

        console.log("syllabusData", syllabusData);
        try {
          const { data, status } = await axiosInstance.post(
            `/syllabus`,
            syllabusData
          );
          console.log(data);
          if (status === 200) {
            setOpen(true);
            setSnackMessage(data.message);
            setLoading(false);
            _fetchSyllabus();
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (accesLevel === "CLUSTER") {
        const RegionId = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .RegionId;
        const schoolData = schoolList.map((school) => {
          return {
            ChaperName: syllabus.ChaperName,
            ProposedCompletionDate: dayjs(ProposedCompletionDate).format(
              "YYYY-MM-DD"
            ),
            StartingDate: dayjs(StartingDate).format("YYYY-MM-DD"),
            SchoolID: school.SchoolId,
            RegionID: RegionId,
          };
        });

        const syllabusData = {
          schoolData,
          SubjectID: subject.SubjectID,
          Classid: subject.Classid,
        };

        console.log("syllabusData", syllabusData);

        try {
          const { data, status } = await axiosInstance.post(
            `/syllabus`,
            syllabusData
          );
          console.log(data);
          if (status === 200) {
            setOpen(true);
            setSnackMessage(data.message);
            setLoading(false);
            _fetchSyllabus();
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const _onEdit = (item) => {
    setSelectedSyllabus(item);
    setOpenModal(true);
    console.log("item", item);
  };
  const handleClick = (item) => (event) => {
    console.log("setSelectedSyllabus", item);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedSyllabus(item);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const _onEditFormChange = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        ChaperName: e.target.value,
      };
    });
  };
  const _onSyllabusEdit = async () => {
    try {
      const { data, status } = await axiosInstance.put(
        `/syllabus/syllabus-detail/${selectedSyllabus.id}`,
        selectedSyllabus
      );
      if (status == 200) {
        setSnackMessage(data.message);
        _fetchSyllabus();
        setOpenModal(false);
        setOpen(true);
      }
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const _onDelete = async () => {
    try {
      const { data, status } = await axiosInstance.delete(
        `/syllabus/syllabus-detail/${selectedSyllabus.id}`
      );
      if ((data, status)) {
        setSnackMessage(data.message);
        _fetchSyllabus();
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setOpen(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Syllabus for {subject.Subject.SubjectName}
            </Typography>
            <Typography variant="subtitle2" component="span">
              Manage Transaction for respective classes and their subjects.
              Select a Class to continue.
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box flexGrow={1}>
        {accesLevel === "ADMIN" ? (
          <Stack component={Paper} sx={{ mt: 2, mb: 2, p: 2 }}>
            <Stack
              sx={{ mt: 2, mb: 1 }}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormControl fullWidth>
                <TextField
                  error={validationError}
                  value={syllabus.ChaperName}
                  onChange={_onFormValue}
                  fullWidth
                  size="small"
                  label="Chapter Name"
                  variant="outlined"
                />
              </FormControl>

              <Box sx={{ m: 1 }} />
              {accesLevel === "ADMIN" && accesLevel !== "CLUSTER" ? (
                <FormControl fullWidth>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Cluster"
                    sx={{ display: "flex", flex: 1 }}
                    // value={formik.values.SchoolID}
                    variant="outlined"
                    name="RegionId"
                    required
                    onChange={_handleOnAdminClusterChange}
                    size="small"
                  >
                    {clusters.map((cluster, index) => {
                      return (
                        <MenuItem key={index} value={cluster.RegionId}>
                          {cluster.RegionName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              ) : (
                ""
              )}
              {accesLevel === "CLUSTER" && accesLevel !== "ADMIN" ? (
                <FormControl fullWidth>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Cluster"
                    sx={{ display: "flex", flex: 1 }}
                    autoFocus
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    name="RegionId"
                    required
                    onChange={_handleOnAdminClusterChange}
                    size="small"
                    value={clusterId}
                  >
                    {clusters.map((cluster, index) => {
                      return (
                        <MenuItem key={index} value={cluster.RegionId}>
                          {cluster.RegionName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              ) : (
                ""
              )}
            </Stack>
            <Stack
              sx={{ mt: 2, mb: 2 }}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    onChange={_setDateValue("StartingDate")}
                    value={syllabus.StartingDate}
                    inputFormat="DD-MM-YYYY"
                    label="Starting Date"
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <Box sx={{ m: 1 }} />
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    onChange={_setDateValue("ProposedCompletionDate")}
                    value={syllabus.ProposedCompletionDate}
                    inputFormat="DD-MM-YYYY"
                    label="Proposed Completion Date"
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <Stack sx={{ mt: 1 }}>
              {create && (
                <LoadingButton
                  loading={loading}
                  onClick={_submitSyllabus}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Add new
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        ) : (
          ""
        )}
        <Divider sx={{ mt: 2, mb: 2 }} />
        {accesLevel === "ADMIN" && (
          <FormControl fullWidth>
            <FormLabel id="demo-radio-buttons-group-label">
              Select Region / School
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="REGION"
              name="radio-buttons-group"
              onChange={_onRadioChange}
            >
              <FormControlLabel
                value="REGION"
                control={<Radio />}
                label="Region"
              />
              <FormControlLabel
                value="SCHOOL"
                control={<Radio />}
                label="School"
              />
            </RadioGroup>
            <Divider sx={{ mt: 2, mb: 2 }} />
          </FormControl>
        )}
        <Box display="flex" flex={1} flexDirection="column">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {accesLevel === "ADMIN" && (
                    <TableCell align="left">School Name</TableCell>
                  )}
                  {accesLevel === "CLUSTER" && (
                    <TableCell align="left">School Name</TableCell>
                  )}
                  <TableCell align={accesLevel === "ADMIN" ? "center" : "left"}>
                    Chapter Name
                  </TableCell>
                  <TableCell align="center">Class Name</TableCell>
                  <TableCell align="center">Starting Date</TableCell>
                  <TableCell align="center">Proposed Completion Date</TableCell>
                  <TableCell align="center">Actual Completion Date</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {syllabusList.length > 0 &&
                  read &&
                  syllabusList.map((item, index) => (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {accesLevel === "ADMIN" && (
                        <TableCell align="left">
                          {item.School ? item.School.NameOfSchool : ""}
                        </TableCell>
                      )}
                      {accesLevel === "CLUSTER" && (
                        <TableCell align="left">
                          {item.School ? item.School.NameOfSchool : ""}
                        </TableCell>
                      )}
                      <TableCell
                        align={accesLevel === "ADMIN" ? "center" : "left"}
                      >
                        {item.ChaperName}
                      </TableCell>
                      <TableCell align="center">
                        {subject.Classid && (
                          <>
                            {
                              classList.find(
                                (classItem) =>
                                  classItem.Classid === subject.Classid
                              )?.ClassModel.ClassName
                            }
                          </>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(item.StartingDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(item.ProposedCompletionDate).format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            label="Completion Date"
                            inputFormat="DD/MM/YYYY"
                            value={
                              item.CompletionDate ? item.CompletionDate : ""
                            }
                            onChange={_handleCDOnUpdate(item.id)}
                            renderInput={(params) => (
                              <TextField
                                name="CompletionDate"
                                {...params}
                                variant="outlined"
                                size="small"
                                sx={{ display: "flex", flex: 1 }}
                                required
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell align="center">{item.status}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={handleClick(item)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {update && (
                <MenuItem onClick={() => setOpenModal(true)}>Edit</MenuItem>
              )}

              {IsDelete && <MenuItem onClick={_onDelete}>Delete</MenuItem>}
            </Menu>
            <Dialog
              fullWidth
              maxWidth="lg"
              open={openModal}
              onClose={handleModalClose}
            >
              <DialogTitle>Edit Syllabus</DialogTitle>
              <DialogContent>
                <Stack component={Paper} sx={{ mt: 2, mb: 2, p: 2 }}>
                  <Stack
                    sx={{ mt: 2, mb: 1 }}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <TextField
                      error={validationError}
                      value={
                        selectedSyllabus && selectedSyllabus.ChaperName !== null
                          ? selectedSyllabus.ChaperName
                          : ""
                      }
                      onChange={_onEditFormChange}
                      fullWidth
                      size="small"
                      label="Chapter Name"
                      variant="outlined"
                      disabled={accesLevel !== "ADMIN"}
                    />
                    <Box sx={{ m: 1 }} />
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          onChange={_setEditDateValue("CompletionDate")}
                          value={
                            selectedSyllabus && selectedSyllabus.CompletionDate
                              ? selectedSyllabus.CompletionDate
                              : ""
                          }
                          inputFormat="DD-MM-YYYY"
                          label="Actual Completion Date"
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                  <Stack
                    sx={{ mt: 2, mb: 2 }}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          onChange={_setEditDateValue("StartingDate")}
                          value={
                            selectedSyllabus && selectedSyllabus.StartingDate
                              ? selectedSyllabus.StartingDate
                              : ""
                          }
                          inputFormat="DD-MM-YYYY"
                          label="Starting Date"
                          renderInput={(params) => (
                            <TextField
                              disabled={accesLevel !== "ADMIN"}
                              size="small"
                              {...params}
                            />
                          )}
                          disabled={accesLevel !== "ADMIN"}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <Box sx={{ m: 1 }} />
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          onChange={_setEditDateValue("ProposedCompletionDate")}
                          value={
                            selectedSyllabus &&
                            selectedSyllabus.ProposedCompletionDate
                              ? selectedSyllabus.ProposedCompletionDate
                              : ""
                          }
                          inputFormat="DD-MM-YYYY"
                          label="Proposed Completion Date"
                          renderInput={(params) => (
                            <TextField
                              disabled={accesLevel !== "ADMIN"}
                              size="small"
                              {...params}
                            />
                          )}
                          disabled={accesLevel !== "ADMIN"}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                  <Stack sx={{ mt: 1 }}>
                    <LoadingButton
                      loading={loading}
                      onClick={_onSyllabusEdit}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Update Syllabus
                    </LoadingButton>
                  </Stack>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleModalClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </TableContainer>
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
