import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MuiAlert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useSession from "../../CustomHooks/useSession";
import { getClusters } from "../Clusters/Clusters";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CoCurricular() {
  const navigate = useNavigate();
  const [snackbar, setSnackBar] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteSnackbar, setDeleteSnackBar] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [responsibles, setResponsibles] = useState([]);
  const [designation, setDesignation] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [curricularData, setCurricularData] = useState([]);
  const [selectedCurricularData, setSelectedCurricularData] = useState({});
  const [selectedWorkshops, setSelectedWorkshops] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacherAttendance, setSelectedTeacherAttendance] = useState(
    []
  );
  const [selectedParentAttendance, setSelectedParentAttendance] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [variables, setVariables] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState(null);
  const [selectedInputResponsible, setSelectedInputResponsible] = useState("");
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [openTeacherAttendance, setOpenTeacherAttendance] = useState(false);
  const [openParentAttendance, setOpenParentAttendance] = useState(false);
  const [formData, setFormData] = useState({
    CurricularDate: dayjs().format("YYYY-MM-DD"),
    Event: "",
    Activity: "",
    ResponsibleName: "",
    ResponsibleDesignation: "",
  });
  useEffect(() => {
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
      .SchoolId;
      setSchoolId(schoolID);
    }
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      const searchParams = new URLSearchParams();
      searchParams.append("school_id", schoolId);
      setSchoolId(schoolId);
    }
    if (accesLevel === "CLUSTER") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
      .SchoolId;
      setSchoolId(schoolID);
    }
    _fetchResponsible();
    _fetchTeachers();
    _fetchStudentsData();
    _fetchGulESarsabadVariables();
    _fetchCurricularData();
  }, []);

  const _fetchCurricularData = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/co-curricular/${schoolID}`
        );
        console.log("setCurricularData", data.data);
        if (status === 200) {
          setCurricularData(data.data);
        }
      }
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const { data, status } = await axiosInstance.get(
          `/co-curricular/${schoolId}`
        );
        console.log("setCurricularData", data.data);
        if (status === 200) {
          setCurricularData(data.data);
        }
      }
      if (accesLevel === "CLUSTER") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/co-curricular/${schoolID}`
        );
        console.log("setCurricularData", data.data);
        if (status === 200) {
          setCurricularData(data.data);
        }
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchGulESarsabadVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-sarsabad-list"
      );
      console.log("Variables", data.data);
      if (status === 200) {
        setVariables(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchResponsible = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school-designation`
      );
      console.log("responsibles", data.data);
      if (status === 200) {
        setResponsibles(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchTeachers = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
        const { status, data } = await axiosInstance.get(
          `/teachers/by-school/${schoolID}`
        );
        console.log("get attendance", data.data);
        const teacherData = data.data.map((teacher) => {
          return {
            TeacherID: teacher.SchoolTeachersQId,
            TeacherName: teacher.TeacherName,
            Designation: teacher.Designation.TeachersDesignation,
            TeacherRole: "",
          };
        });
        setTeachers(teacherData);
      }
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        const { status, data } = await axiosInstance.get(
          `/teachers/by-school/${schoolId}`
        );
        console.log("get attendance", data.data);
        const teacherData = data.data.map((teacher) => {
          return {
            TeacherID: teacher.SchoolTeachersQId,
            TeacherName: teacher.TeacherName,
            Designation: teacher.Designation.TeachersDesignation,
            TeacherRole: "",
          };
        });
        setTeachers(teacherData);
      }
      if (accesLevel === "CLUSTER") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
        const { status, data } = await axiosInstance.get(
          `/teachers/by-school/${schoolID}`
        );
        console.log("get attendance", data.data);
        const teacherData = data.data.map((teacher) => {
          return {
            TeacherID: teacher.SchoolTeachersQId,
            TeacherName: teacher.TeacherName,
            Designation: teacher.Designation.TeachersDesignation,
            TeacherRole: "",
          };
        });
        setTeachers(teacherData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchStudentsData = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
        const { status, data } = await axiosInstance.get(
          `/students/by-school/${schoolID}`
        );
        console.log("get students", data.data);
        const list = data.data.map((student) => {
          return {
            SID: student.SID,
            StudentName: student.StudentName,
            ClassName: student.ClassModel ? student.ClassModel.ClassName : "",
            StudentRole: "",
          };
        });
        setStudentData(list);
      }
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        const { status, data } = await axiosInstance.get(
          `/students/by-school/${schoolId}`
        );
        console.log("get students", data.data);
        const list = data.data.map((student) => {
          return {
            SID: student.SID,
            StudentName: student.StudentName,
            ClassName: student.ClassModel ? student.ClassModel.ClassName : "",
            StudentRole: "",
          };
        });
        setStudentData(list);
      }
      if (accesLevel === "CLUSTER") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
        const { status, data } = await axiosInstance.get(
          `/students/by-school/${schoolID}`
        );
        console.log("get students", data.data);
        const list = data.data.map((student) => {
          return {
            SID: student.SID,
            StudentName: student.StudentName,
            ClassName: student.ClassModel ? student.ClassModel.ClassName : "",
            StudentRole: "",
          };
        });
        setStudentData(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    const curricularData = {
      TeacherPerformerJSON: teachers,
      StudentPerformerJSON: studentData,
      schoolid: schoolId,
      CurricularDate: formData.CurricularDate,
      Event: formData.Event,
      Activity: formData.Activity,
      ResponsibleName: formData.ResponsibleName,
      ResponsibleDesignation: formData.ResponsibleDesignation,
    };

    console.log("Curricular Data", curricularData);

    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/co-curricular",
        curricularData
      );
      console.log("response", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        _fetchCurricularData();
        handleAnchorClose();
        handleClose();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();

    const editCurricularData = {
      CurricularDate: selectedCurricularData.CurricularDate,
      Event: selectedCurricularData.Event,
      Activity: selectedCurricularData.Activity,
      StudentPerformerJSON: selectedParentAttendance,
      TeacherPerformerJSON: selectedTeacherAttendance,
      ResponsibleName: selectedCurricularData.ResponsibleName,
      ResponsibleDesignation: selectedCurricularData.ResponsibleDesignation,
    };

    console.log("EDIT DATA", editCurricularData);

    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/co-curricular/${selectedCurricularData.CurricularID}`,
        editCurricularData
      );
      console.log("response", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        _fetchCurricularData();
        handleAnchorClose();
        handleEditClose();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _handleTeacherChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...teachers];
    list[index][name] = value;
    setTeachers(list);
  };
  const handleEditTeacherChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...selectedTeacherAttendance];
    list[index][name] = value;
    setSelectedTeacherAttendance(list);
  };
  const _handleStudentChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...studentData];
    list[index][name] = value;
    setStudentData(list);
  };
  const handleEditStudentChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...selectedParentAttendance];
    list[index][name] = value;
    setSelectedParentAttendance(list);
  };
  const _handleCurricularDate = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        CurricularDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleEditCurricularDate = (e) => {
    setSelectedCurricularData((prevState) => {
      return {
        ...prevState,
        CurricularDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _handleOnEditChange = (e) => {
    setSelectedCurricularData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _onResponsibleChange = (event, newValue) => {
    console.log("On Observer Change", newValue);
    setSelectedResponsible(newValue);
    setDesignation(newValue.TeachersDesignation);
    setFormData((prevState) => {
      return {
        ...prevState,
        ResponsibleID: newValue.id,
      };
    });
  };
  const _onEditResponsibleChange = (event, newValue) => {
    console.log("On Observer Change", newValue);
    setSelectedResponsible(newValue);
    setDesignation(newValue.TeachersDesignation);
    setSelectedCurricularData((prevState) => {
      return {
        ...prevState,
        ResponsibleID: newValue.id,
      };
    });
  };
  const _onResponsibleInputValue = (event, newInputValue) => {
    setSelectedInputResponsible(newInputValue);
  };
  const handleTeacherAttendanceOpen = (item) => () => {
    setOpenTeacherAttendance(true);
    setSelectedCurricularData(item);
    console.log("HEHEHHEHEHEHHEHEHE", item);
    const TeacherPerformance = JSON.parse(item?.TeacherPerformerJSON??"[]");
    setSelectedTeacherAttendance(TeacherPerformance);
    console.log("HEHEHHEHEHEHHEHEHE", TeacherPerformance);
  };
  const handleTeacherAttendanceClose = () => {
    setOpenTeacherAttendance(false);
  };
  const handleParentAttendanceOpen = (item) => () => {
    setOpenParentAttendance(true);
    setSelectedCurricularData(item);
    console.log("HEHEHHEHEHEHHEHEHE", item);
    const studentPerformance = JSON.parse(item?.StudentPerformerJSON??"[]");
    setSelectedParentAttendance(studentPerformance);
    console.log("HEHEHHEHEHEHHEHEHE", studentPerformance);

  };
  const handleParentAttendanceClose = () => {
    setOpenParentAttendance(false);
  };
  const handleClick = (item) => (event) => {
    console.log("Selected CapData", item);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedCurricularData(item);
    const TeacherPerformance = JSON.parse(item.TeacherPerformerJSON);
    setSelectedTeacherAttendance(TeacherPerformance);
    const studentPerformance = JSON.parse(item.StudentPerformerJSON);
    setSelectedParentAttendance(studentPerformance);
  };
  const _handleResponsibleData = () => {
    setSelectedResponsible({
      TeachersDesignation:
        Object.keys(selectedCurricularData).length > 0 &&
        selectedCurricularData.Teacher.Designation.TeachersDesignation,
      label: `(${
        Object.keys(selectedCurricularData.Teacher).length > 0 &&
        selectedCurricularData.Teacher.SchoolTeachersQId
      }) - ${
        Object.keys(selectedCurricularData).length > 0 &&
        selectedCurricularData.Teacher.TeacherName
      } - ${
        selectedCurricularData.Teacher.School !== null &&
        selectedCurricularData.Teacher.School.NameOfSchool
      }`,
      id:
        Object.keys(selectedCurricularData).length > 0 &&
        selectedCurricularData.Teacher.SchoolTeachersQId,
    });
    setDesignation(
      selectedCurricularData.Teacher.Designation.TeachersDesignation
    );
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditOpen = () => {
    // _handleResponsibleData();
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };

  const classOrder = [
    "KG I",
    "KG II",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII"
  ];
  
  const sortedParentsAttendance = selectedParentAttendance
    .filter((performer) => performer.StudentRole && performer.StudentRole.trim() !== "")
    .sort((a, b) => {
      return classOrder.indexOf(a.ClassName) - classOrder.indexOf(b.ClassName);
    });

  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              CoCurricular Module
            </Typography>
          </Stack>
          <Box width={300}>
            {currentPageCRUD.create && (
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Add Curricular
                </Button>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="center">Event</TableCell>
                  <TableCell align="center">Activity</TableCell>
                  <TableCell align="center">Responsible Name</TableCell>
                  <TableCell align="center">Responsible Designation</TableCell>
                  <TableCell align="center">Teacher Performance</TableCell>
                  <TableCell align="center">Student Performance</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageCRUD.create &&
                  curricularData.map((curricular, index) => {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {dayjs(curricular.CurricularDate).format(
                            "YYYY-MM-DD"
                          )}
                        </TableCell>
                        <TableCell align="center">{curricular.Event}</TableCell>
                        <TableCell align="center">
                          {curricular.Activity}
                        </TableCell>
                        <TableCell align="center">
                          {curricular.ResponsibleName}
                        </TableCell>
                        <TableCell align="center">
                          {curricular.ResponsibleDesignation}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={handleTeacherAttendanceOpen(curricular)}
                          >
                            Performance
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={handleParentAttendanceOpen(curricular)}
                          >
                            Performance
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={handleClick(curricular)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {currentPageCRUD.update && (
                <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
              )}
            </Menu>
          </TableContainer>
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>Create Co-Curricular</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onSubmit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date"
                          value={formData.CurricularDate}
                          name="CurricularDate"
                          onChange={_handleCurricularDate}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Event"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        variant="outlined"
                        size="small"
                        name="Event"
                      >
                        {variables.map((variable, index) => {
                          return (
                            <MenuItem
                              key={String(index)}
                              value={variable.Caption}
                            >
                              {variable.Caption}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/*<Autocomplete
                        disablePortal
                        id="combo-box-teacher"
                        options={responsibles.map((item, index) => {
                          return {
                            // ...item,
                            TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                            label: `(${item.SchoolTeachersQId}) - ${
                              item.TeacherName
                            } - ${
                              item.School !== null && item.School.NameOfSchool
                            }`,
                            id: item.SchoolTeachersQId,
                          };
                        })}
                        value={selectedResponsible}
                        onChange={_onResponsibleChange}
                        inputValue={selectedInputResponsible}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        onInputChange={_onResponsibleInputValue}
                        sx={{ display: "flex", flex: 1 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            key={params.id}
                            label="Responsible Name"
                            size="small"
                          />
                        )}
                      />*/}
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        label="Responsible Name"
                        name="ResponsibleName"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        label="Responsible Designation"
                        name="ResponsibleDesignation"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        label="Activity"
                        name="Activity"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Teacher Staff Performers
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">Teachers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      Teacher Name
                                    </TableCell>
                                    <TableCell align="center">
                                      Designation
                                    </TableCell>
                                    <TableCell align="right">
                                      Role/Category
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {teachers.map((teacher, index) => {
                                    return (
                                      <TableRow
                                        key={String(index)}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {teacher.TeacherName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {teacher.Designation}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "30%" }}
                                        >
                                          <TextField
                                            label="Role"
                                            variant="outlined"
                                            size="small"
                                            value={teacher.TeacherRole}
                                            sx={{ display: "flex", flex: 1 }}
                                            name="TeacherRole"
                                            onChange={(e) =>
                                              _handleTeacherChange(e, index)
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Student Best Performers
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">Students</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      Student Name
                                    </TableCell>
                                    <TableCell align="center">Class</TableCell>
                                    <TableCell align="right">
                                      Role/Category
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {studentData.map((student, index) => {
                                    return (
                                      <TableRow
                                        key={String(index)}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {student.StudentName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {student.ClassName}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "30%" }}
                                        >
                                          <TextField
                                            label="Role"
                                            variant="outlined"
                                            size="small"
                                            sx={{ display: "flex", flex: 1 }}
                                            value={student.StudentRole}
                                            name="StudentRole"
                                            onChange={(e) =>
                                              _handleStudentChange(e, index)
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        // onClick={_editSyllabus}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Create Co-Curricular
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={openEdit}
            onClose={handleEditClose}
          >
            <DialogTitle>Update Co-Curricular</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onEdit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date"
                          value={selectedCurricularData.CurricularDate}
                          name="CurricularDate"
                          onChange={_handleEditCurricularDate}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Event"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCurricularData.Event}
                        onChange={_handleOnEditChange}
                        variant="outlined"
                        size="small"
                        name="Event"
                      >
                        {variables.map((variable, index) => {
                          return (
                            <MenuItem
                              key={String(index)}
                              value={variable.Caption}
                            >
                              {variable.Caption}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/*<Autocomplete
                        disablePortal
                        id="combo-box-teacher"
                        options={responsibles.map((item, index) => {
                          return {
                            // ...item,
                            TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                            label: `(${item.SchoolTeachersQId}) - ${
                              item.TeacherName
                            } - ${
                              item.School !== null && item.School.NameOfSchool
                            }`,
                            id: item.SchoolTeachersQId,
                          };
                        })}
                        value={selectedResponsible}
                        onChange={_onEditResponsibleChange}
                        inputValue={selectedInputResponsible}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        onInputChange={_onResponsibleInputValue}
                        sx={{ display: "flex", flex: 1 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            key={params.id}
                            label="Responsible Name"
                            size="small"
                          />
                        )}
                      />*/}
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCurricularData.ResponsibleName}
                        onChange={_handleOnEditChange}
                        label="Responsible Name"
                        name="ResponsibleName"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCurricularData.ResponsibleDesignation}
                        onChange={_handleOnEditChange}
                        label="Responsible Designation"
                        name="ResponsibleDesignation"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCurricularData.Activity}
                        onChange={_handleOnEditChange}
                        label="Activity"
                        name="Activity"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Teacher Staff Performers
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">Teachers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      Teacher Name
                                    </TableCell>
                                    <TableCell align="center">
                                      Designation
                                    </TableCell>
                                    <TableCell align="right">
                                      Role/Category
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedTeacherAttendance.length > 0 &&
                                    selectedTeacherAttendance.map(
                                      (performer, index) => {
                                        return (
                                          <TableRow
                                            key={String(index)}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {performer.TeacherName}
                                            </TableCell>
                                            <TableCell align="center">
                                              {performer.Designation}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              sx={{ width: "30%" }}
                                            >
                                              <TextField
                                                label="Role"
                                                variant="outlined"
                                                size="small"
                                                value={performer.TeacherRole}
                                                sx={{
                                                  display: "flex",
                                                  flex: 1,
                                                }}
                                                name="TeacherRole"
                                                onChange={(e) =>
                                                  handleEditTeacherChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Student Best Performers
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">Students</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      Teacher Name
                                    </TableCell>
                                    <TableCell align="center">
                                      Designation
                                    </TableCell>
                                    <TableCell align="right">
                                      Role/Category
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedParentAttendance.length > 0 &&
                                    selectedParentAttendance.map(
                                      (performer, index) => {
                                        return (
                                          <TableRow
                                            key={String(index)}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {performer.StudentName}
                                            </TableCell>
                                            <TableCell align="center">
                                              {performer.ClassName}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              sx={{ width: "30%" }}
                                            >
                                              <TextField
                                                label="Role"
                                                variant="outlined"
                                                size="small"
                                                value={performer.StudentRole}
                                                sx={{
                                                  display: "flex",
                                                  flex: 1,
                                                }}
                                                name="StudentRole"
                                                onChange={(e) =>
                                                  handleEditStudentChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Update Co-Curricular
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={openTeacherAttendance}
            onClose={handleTeacherAttendanceClose}
          >
            <DialogTitle>Teacher Performance</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Teacher Name</TableCell>
                              <TableCell align="Center">
                                Teacher Designation
                              </TableCell>
                              <TableCell align="right">Teacher Role</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedTeacherAttendance.length > 0 &&
                              selectedTeacherAttendance.map(
                                (performer, index) => {
                                  return (
                                    <TableRow
                                      key={String(index)}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                      >
                                        {performer.TeacherName}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        component="th"
                                        scope="row"
                                      >
                                        {performer.Designation}
                                      </TableCell>
                                      <TableCell align="right">
                                        {performer.TeacherRole}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}></Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleTeacherAttendanceClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={openParentAttendance}
            onClose={handleParentAttendanceClose}
          >
            <DialogTitle>Student Performance</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Student Name</TableCell>
                              <TableCell align="center">
                                Student Class
                              </TableCell>
                              <TableCell align="right">Student Role</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortedParentsAttendance.length > 0 &&
                              sortedParentsAttendance.map(
                                (performer, index) => {
                                  return (
                                    <TableRow
                                      key={String(index)}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {performer.StudentName}
                                      </TableCell>
                                      <TableCell align="center">
                                        {performer.ClassName}
                                      </TableCell>
                                      <TableCell align="right">
                                        {performer.StudentRole}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}></Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleParentAttendanceClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
