import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

export default function AssetTransaction() {
  const { state: locationState } = useLocation();
  const {
    FixedAssetRecordId,
    AssetCode,
    AssetListItemType,
    OpeningBal,
    StaffID,
    CreatedByUser,
    id,
  } = locationState.selectedFixedAssets;
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [totalQty, setTotalQty] = useState(0);
  const [checked, setChecked] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [students, setStudents] = useState([]);
  const [sNos, setSNos] = useState([]);
  const [assetTransactions, setAssetTransactions] = useState([]);
  const [selectedAssetTransaction, setSelectedAssetTransaction] = useState({});
  const [obtainedPercentage, setObtainedPercentage] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [school, setSchool] = useState("");
  const { accesLevel, access } = useSession();
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [formData, setFormData] = useState({
    ASSETID: FixedAssetRecordId,
    TransactionDate: dayjs().format("YYYY-MM-DD"),
    AddAsset: 0,
    SubtractAsset: 0,
    CurrentBalance: 0,
    PhysicalCondition: "",
    Notes: "",
    Cost: 0,
    DateOfPurchase: dayjs().format("YYYY-MM-DD"),
    DeliverByType: "",
    SerialNoFrom: 0,
    SerialNoTo: 0,
  });

  useEffect(() => {
    if (accesLevel === "SCHOOL" || accesLevel === "ADMIN") {
      if (AssetCode !== null && AssetCode !== undefined && AssetCode !== "") {
        _fetchSpFixedAssetTransaction(AssetCode);
        _fetchAssetTransaction();
      }
    }
    console.log(
      "locationState.selectedFixedAssets",
      locationState.selectedFixedAssets
    );
  }, []);

  useEffect(() => {
    console.log("locationState", locationState);
    console.log("students", students);
  }, []);

  const _fetchAssetTransaction = async (schoolId, classId) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/fixed-asset-transaction/${FixedAssetRecordId}`
      );
      console.log("fixed-asset-transaction", data.data);
      if (status === 200) {
        setAssetTransactions(data.data);
        if (data.data.length > 0) {
          const SNos = data.data.map((x) => x.SerialNumbers);
          setSNos(SNos);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchSpFixedAssetTransaction = async (asset_code) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/fixed-asset-transaction/fixed-assets-sp/${asset_code}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const _setQuantityAndSerialNumbers = (e) => {};

  const _onSubmit = async (e) => {
    e.preventDefault();
    let qty = 0;
    let serialNumbers = 0;
    if (assetTransactions.length === 0) {
      qty = Number(formData.CurrentBalance);
    } else if (assetTransactions.length > 0) {
      qty =
        Number(assetTransactions[assetTransactions.length - 1].CurrentBalance) +
        Number(formData.CurrentBalance);
    } else {
      qty = 0;
    }
    console.log("formData.CurrentBalance", qty);
    console.log("formData.AddAsset", formData.AddAsset);
    console.log("formData.SubtractAsset", formData.SubtractAsset);
    console.log("formData.SerialNumbers", serialNumbers);
    const transactionData = {
      ASSETID: formData.ASSETID,
      AddAsset: formData.AddAsset,
      SubtractAsset: formData.SubtractAsset,
      // CurrentBalance:
      //   qty + (Number(formData.AddAsset) - Number(formData.SubtractAsset)),
      PhysicalCondition: formData.PhysicalCondition,
      TransactionDate: formData.TransactionDate,
      Notes: formData.Notes,
      Cost: formData.Cost,
      DateOfPurchase: formData.DateOfPurchase,
      DeliverByType: formData.DeliverByType,
      SerialNoFrom: formData.SerialNoFrom,
      SerialNoTo: formData.SerialNoTo,
    };

    console.log("transactionData", transactionData);
    try {
      const { data, status } = await axiosInstance.post(
        "/fixed-asset-transaction",
        transactionData
      );
      console.log("response after post", data.data);
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpen(false);
        if (AssetCode !== null && AssetCode !== undefined && AssetCode !== "") {
          _fetchSpFixedAssetTransaction(AssetCode);
          _fetchAssetTransaction();
        }
        setFormData((prevState) => {
          return {
            ...prevState,
            AddAsset: 0,
            SubtractAsset: 0,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onEdit = async (e) => {
    e.preventDefault();
    let qty = 0;
    let serialNumbers = 0;
    if (assetTransactions.length === 0) {
      qty = Number(formData.CurrentBalance);
    } else if (assetTransactions.length > 0) {
      qty =
        Number(assetTransactions[assetTransactions.length - 1].CurrentBalance) +
        Number(formData.CurrentBalance);
    } else {
      qty = 0;
    }
    console.log("selectedAssetTransaction", selectedAssetTransaction);
    const editTransactionData = {
      ASSETID: selectedAssetTransaction.ASSETID,
      AddAsset: selectedAssetTransaction.AddAsset,
      SubtractAsset: selectedAssetTransaction.SubtractAsset,
      // CurrentBalance:
      //   qty +
      //   (Number(selectedAssetTransaction.AddAsset) -
      //     Number(selectedAssetTransaction.SubtractAsset)),
      PhysicalCondition: selectedAssetTransaction.PhysicalCondition,
      TransactionDate: selectedAssetTransaction.TransactionDate,
      Notes: selectedAssetTransaction.Notes,
      Cost: selectedAssetTransaction.Cost,
      DateOfPurchase: selectedAssetTransaction.DateOfPurchase,
      DeliverByType: selectedAssetTransaction.DeliverByType,
      SerialNoFrom: selectedAssetTransaction.SerialNoFrom,
      SerialNoTo: selectedAssetTransaction.SerialNoTo,
    };
    try {
      const { data, status } = await axiosInstance.put(
        `/fixed-asset-transaction/${selectedAssetTransaction.ATRID}`,
        editTransactionData
      );
      console.log("response after post", data.data);
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpenEdit(false);
        if (AssetCode !== null && AssetCode !== undefined && AssetCode !== "") {
          _fetchSpFixedAssetTransaction(AssetCode);
          _fetchAssetTransaction();
        }
        // setFormData((prevState) => {
        //   return {
        //     ...prevState,
        //     AddAsset: 0,
        //     SubtractAsset: 0,
        //   };
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onDelete = async () => {
    try {
      console.log(
        "selectedAssetTransaction.ATRID",
        selectedAssetTransaction.ATRID
      );
      const { data, status } = await axiosInstance.delete(
        `/fixed-asset-transaction/${selectedAssetTransaction.ATRID}`
      );
      if (status === 200) {
        if (AssetCode !== null && AssetCode !== undefined && AssetCode !== "") {
          _fetchSpFixedAssetTransaction(AssetCode);
          _fetchAssetTransaction();
          setSnackMessage(data.message);
          setSnackOpen(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleDateOfTransaction = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        TransactionDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleEditDateOfTransaction = (e) => {
    setSelectedAssetTransaction((prevState) => {
      return {
        ...prevState,
        TransactionDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleDateOfPurchase = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        DateOfPurchase: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleEditDateOfPurchase = (e) => {
    setSelectedAssetTransaction((prevState) => {
      return {
        ...prevState,
        DateOfPurchase: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleOnEditChange = (e) => {
    setSelectedAssetTransaction((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleOnSubtractAsset = (e) => {
    console.log("e.target.value", e.target.value);
    if (e.target.value > 1 || e.target.value < 0) {
      return (e.target.value = 1);
    }
    setFormData((prevState) => {
      return {
        ...prevState,
        SubtractAsset: e.target.value,
      };
    });
  };

  const _openTransaction = () => {
    setOpen(true);
    setFormData((prevState) => {
      return {
        ...prevState,
        AddAsset: 0,
        SubtractAsset: 0,
      };
    });
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (transaction) => (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedAssetTransaction(transaction);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Asset Transaction Module
          </Typography>
        </Stack>
        <Box width={300}>
          <FormControl fullWidth>
            <Button
              variant="contained"
              color="primary"
              onClick={_openTransaction}
            >
              Make Transaction
            </Button>
          </FormControl>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid item xs={6}>
              <Box sx={{ width: "100%", marginBottom: "8px" }}>
                <div className="profile-data">
                  <div className="data-key">Asset Item Name:</div>
                  <div className="data-value">{AssetListItemType}</div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ width: "100%", marginBottom: "8px" }}>
                <div className="profile-data">
                  <div className="data-key">Asset Code:</div>
                  <div className="data-value">{AssetCode}</div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ width: "100%", marginBottom: "8px" }}>
                <div className="profile-data">
                  <div className="data-key">Opening Balance (Quantity):</div>
                  <div className="data-value">{OpeningBal}</div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ width: "100%", marginBottom: "8px" }}>
                <div className="profile-data">
                  <div className="data-key">Current Balance (Quantity):</div>
                  <div className="data-value">
                    {assetTransactions.length > 0 &&
                      assetTransactions[assetTransactions.length - 1]
                        .CurrentBalance}
                  </div>
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <SectionDivider text={"Asset Transaction Record/History"} />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Transaction Date</TableCell>
                      <TableCell align="left">Date Of Purchase</TableCell>
                      <TableCell align="center">Asset (IN)</TableCell>
                      <TableCell align="center">Asset (OUT)</TableCell>
                      <TableCell align="center">Asset (CURRENT)</TableCell>
                      <TableCell align="center">Cost</TableCell>
                      <TableCell align="center">Deliver By Type</TableCell>
                      <TableCell align="center">
                        Current Physical Condition
                      </TableCell>
                      <TableCell align="center">Serial Numbers</TableCell>
                      <TableCell align="center">Remarks</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assetTransactions.length > 0 &&
                      assetTransactions.map((transaction, index) => {
                        return (
                          <TableRow
                            key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {dayjs(transaction.TransactionDate).format(
                                "YYYY-MM-DD"
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {dayjs(transaction.DateOfPurchase).format(
                                "YYYY-MM-DD"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Chip
                                label={`${transaction.AddAsset}`}
                                color="success"
                              />
                              {/*{transaction.AddAsset}*/}
                            </TableCell>
                            <TableCell align="center">
                              <Chip
                                label={`${transaction.SubtractAsset}`}
                                color="error"
                              />
                              {/*{transaction.SubtractAsset}*/}
                            </TableCell>
                            <TableCell align="center">
                              {/*<Chip
                                label={`${transaction.CurrentBalance}`}
                                color="primary"
                              />*/}
                              {transaction.CurrentBalance}
                            </TableCell>
                            <TableCell align="center">
                              {transaction.Cost}
                            </TableCell>
                            <TableCell align="center">
                              {transaction.DeliverByType}
                            </TableCell>
                            <TableCell align="center">
                              {transaction.PhysicalCondition == "1" && (
                                <Chip label="Excellent" color="success" />
                              )}
                              {transaction.PhysicalCondition == "2" && (
                                <Chip
                                  label="Not so good (Useable)"
                                  color="warning"
                                />
                              )}
                              {transaction.PhysicalCondition == "3" && (
                                <Chip
                                  label="Damaged (Need to replace)"
                                  color="error"
                                />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {`${transaction.SerialNoFrom} - ${transaction.SerialNoTo}`}
                            </TableCell>
                            <TableCell align="center">
                              {transaction.Notes}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton onClick={handleClick(transaction)}>
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={handleAnchorClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {accesLevel === "ADMIN" && (
            <MenuItem onClick={_onDelete} color="error">
              Delete
            </MenuItem>
          )}
          <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
        </Menu>
      </Stack>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Create Asset Transaction
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onSubmit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              {/*<Grid item xs={12} md={12}>
                  <Typography variant="span" color="error" component="h4">
                    Note: Please Select Class first before Subject.
                  </Typography>
                </Grid>*/}
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="Date Of Transaction"
                    value={formData.TransactionDate}
                    name="TransactionDate"
                    onChange={_handleDateOfTransaction}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="Date Of Purchase"
                    value={formData.DateOfPurchase}
                    name="StartTime"
                    onChange={_handleDateOfPurchase}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Cost"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="Cost"
                  type="number"
                  onChange={_handleOnChange}
                />
              </Grid>
              {/*<Grid item xs={12} md={6}>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="CurrentBalance"
                  type="number"
                  onChange={_handleOnChange}
                />
              </Grid>*/}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Add Asset"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="AddAsset"
                  type="number"
                  onChange={_handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Subtract Asset"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="SubtractAsset"
                  type="number"
                  onChange={_handleOnSubtractAsset}
                />
              </Grid>
              {/*<Grid item xs={12} md={12}>
                {
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Range"
                    sx={{ display: "flex", flex: 1 }}
                    //   value={formData.PhysicalCondition}
                    variant="outlined"
                    name="PhysicalCondition"
                    required={show}
                    // disabled={!show}
                    onChange={_handleOnChange}
                    size="small"
                  >
                    {sNos.map((sno, index) => (
                      <MenuItem value={sno} key={index}>
                        {sno}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              </Grid>*/}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Serial No. From"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="SerialNoFrom"
                  type="number"
                  required
                  onChange={_handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Serial No. To"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="SerialNoTo"
                  type="number"
                  required
                  onChange={_handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Current Physical Condition"
                  sx={{ display: "flex", flex: 1 }}
                  //   value={formData.PhysicalCondition}
                  variant="outlined"
                  name="PhysicalCondition"
                  required
                  onChange={_handleOnChange}
                  size="small"
                >
                  <MenuItem value="1">Excellent</MenuItem>
                  <MenuItem value="2">Not so good (Useable)</MenuItem>
                  <MenuItem value="3">Damage (Not for use)</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Deliver By Type"
                  sx={{ display: "flex", flex: 1 }}
                  value={formData.DeliverByType}
                  variant="outlined"
                  name="DeliverByType"
                  onChange={_handleOnChange}
                  size="small"
                >
                  <MenuItem value="Donate">Donate</MenuItem>
                  <MenuItem value="Purchase">Purchase</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid item xs={12} md={12}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Description"
                    name="Notes"
                    // value={formik.values.Notes}
                    onChange={_handleOnChange}
                    style={{ width: "100%", padding: 15 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  //   loading={loading}
                  //   disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Post Transaction
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="md" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Update Asset Transaction
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onEdit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              {/*<Grid item xs={12} md={12}>
                  <Typography variant="span" color="error" component="h4">
                    Note: Please Select Class first before Subject.
                  </Typography>
                </Grid>*/}
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="Date Of Purchase"
                    value={selectedAssetTransaction.DateOfPurchase}
                    name="DateOfPurchase"
                    onChange={_handleEditDateOfTransaction}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Cost"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="Cost"
                  value={selectedAssetTransaction.Cost}
                  type="number"
                  onChange={_handleOnEditChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Add Asset"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="AddAsset"
                  value={selectedAssetTransaction.AddAsset}
                  type="number"
                  onChange={_handleOnEditChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Subtract Asset"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="SubtractAsset"
                  value={selectedAssetTransaction.SubtractAsset}
                  type="number"
                  onChange={_handleOnEditChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Serial No. From"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="SerialNoFrom"
                  value={selectedAssetTransaction.SerialNoFrom}
                  type="number"
                  required
                  onChange={_handleOnEditChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Serial No. To"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="SerialNoTo"
                  value={selectedAssetTransaction.SerialNoTo}
                  type="number"
                  required
                  onChange={_handleOnEditChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Current Physical Condition"
                  sx={{ display: "flex", flex: 1 }}
                  //   value={formData.Condition}
                  variant="outlined"
                  name="PhysicalCondition"
                  value={selectedAssetTransaction.PhysicalCondition}
                  required
                  onChange={_handleOnEditChange}
                  size="small"
                >
                  <MenuItem value="1">Excellent</MenuItem>
                  <MenuItem value="2">Not so good (Useable)</MenuItem>
                  <MenuItem value="3">Damage (Not for use)</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid item xs={12} md={12}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Notes"
                    name="Notes"
                    value={selectedAssetTransaction.Notes}
                    onChange={_handleOnEditChange}
                    style={{ width: "100%", padding: 15 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  //   loading={loading}
                  //   disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Update Transaction
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
