import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/Context.tsx";

export default function AssetPhysicalCondition() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [assets, setAssets] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [formData, setFormData] = useState({
    datefrom: dayjs().format("YYYY-MM-DD"),
    dateto: dayjs().format("YYYY-MM-DD"),
  });

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData ===>", formData);
    const searchParams = new URLSearchParams();
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolID = accessObject[0].SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    if (accesLevel === "CLUSTER") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    searchParams.append("datefrom", formData.datefrom);
    searchParams.append("dateto", formData.dateto);
    try {
      const { data, status } = await axiosInstance.get(
        "/reports/fixed-assets-condition",
        {
          params: searchParams,
        }
      );
      if (status === 200) {
        setAssets(data.data[0]);
      }
      console.log("Assets Report", data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        datefrom: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dateto: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("Baithak-Report.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "fixed-asset-condition.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "Fixed Assets Physical Condition School Wise" }, {}];

    let table = [
      {
        A: "S.No",
        B: "School Name",
        C: "Asset Name",
        D: "Excellent",
        E: "Not So Good",
        F: "Damaged",
        G: "Total Current Balance",
      },
    ];

    assets.length > 0 &&
      assets.forEach((assetItem, index) => {
        table.push({
          A: index + 1,
          B: assetItem.NameOfSchool,
          C: assetItem.AssetListItemType,
          D: assetItem["1-Excellent"],
          E: assetItem["2-Not so Good"],
          F: assetItem["3-Damaged"],
          G: assetItem["Total Current Balance"],
        });
      });

    table = [{ A: "Fixed Assets Physical Condition" }].concat(table).concat([""]);

    const finalData = [...title, ...table];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "fixed-asset-condition-report");

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:H2",
      tbodyRange: `A3:H${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:H${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.column("A").width(15);
        sheet.column("B").width(15);
        sheet.column("C").width(15);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);
        sheet.column("H").width(15);
        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

    // Calculate column sums
    const calculateSums = () => {
      const totalExcellent = assets.reduce((sum, asset) => sum + asset["1-Excellent"], 0);
      const totalNotSoGood = assets.reduce((sum, asset) => sum + asset["2-Not so Good"], 0);
      const totalDamaged = assets.reduce((sum, asset) => sum + asset["3-Damaged"], 0);
      const totalBalance = assets.reduce((sum, asset) => sum + asset["Total Current Balance"], 0);
  
      return { totalExcellent, totalNotSoGood, totalDamaged, totalBalance };
    };
  
    const { totalExcellent, totalNotSoGood, totalDamaged, totalBalance } = calculateSums();
  

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Asset Physical Condition School Wise
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <LoadingButton
            type="submit"
            onClick={() => {
              let fixedAssetsData=[];
              assets.forEach((item, index) => {
                fixedAssetsData.push({
                  "S.No": index + 1,
                  "School Name": item.NameOfSchool,
                  "Asset Name": item.AssetListItemType,
                  "Excellent": item["1-Excellent"],
                  "Not So Good": item["2-Not so Good"],
                  "Damaged": item["3-Damaged"],
                  "Total Current Balance": item["Total Current Balance"],
                });
              });
              context.dispatch({Payload:{dataForPivotTable:[...fixedAssetsData]}, Type:'DATA FOR PIVOT TABLE'})
              navigate("/reports/react-pivot-table");
            }}
            fullWidth
            variant="contained"
            color="primary"
          >
            Visualizer{" "}
          </LoadingButton>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Date From"
              name="from"
              value={formData.datefrom}
              onChange={_handleDateFrom}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1 }}
                  size="small"
                  required
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Date To"
              name="to"
              value={formData.dateto}
              onChange={_handleDateTo}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1 }}
                  size="small"
                  required
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <LoadingButton
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Asset Physical Condition List{" "}
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="my-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="center">School Name</TableCell>
                  <TableCell align="center">Asset Name</TableCell>
                  <TableCell align="center">Excellent</TableCell>
                  <TableCell align="center">Not So Good</TableCell>
                  <TableCell align="center">Damaged</TableCell>
                  <TableCell align="right">Total Current Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assets.length > 0 &&
                  assets.map((assetItem, index) => {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {assetItem.NameOfSchool}
                        </TableCell>
                        <TableCell align="center">
                          {assetItem.AssetListItemType}
                        </TableCell>
                        <TableCell align="center">
                          {assetItem["1-Excellent"]}
                        </TableCell>
                        <TableCell align="center">
                          {assetItem["2-Not so Good"]}
                        </TableCell>
                        <TableCell align="center">
                          {assetItem["3-Damaged"]}
                        </TableCell>
                        <TableCell align="right">
                          {assetItem["Total Current Balance"]}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                <TableRow>
                  <TableCell
                    align="left"
                    colSpan={3}
                    sx={{ fontWeight: "bold" }}
                  >
                    Total
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {totalExcellent}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {totalNotSoGood}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {totalDamaged}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {totalBalance}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}
