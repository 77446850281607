import { Typography, Divider } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import useSession from "../../CustomHooks/useSession";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { AppContext } from "../../Context/Context.tsx";
const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function ReactPivotTable() {
  const context = useContext(AppContext);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");

  const [pivotState, setPivotState] = useState({
    data: context.applicationStore.dataForPivotTable,
    rows: [],
    cols: []
  });

  useEffect(() => {
    const data = context.applicationStore.dataForPivotTable;
    if (data && data.length > 0) {
      const columns = Object.keys(data[0]);
      setPivotState((prevState) => ({
        ...prevState,
        rows: columns,
        cols: []
      }));
    }
    console.log(data, "dataForPivotTable");
  }, [context.applicationStore.dataForPivotTable]);

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            React-Pivot Table
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />

      {/* Pivot Table UI */}
      <PivotTableUI
        data={pivotState} // Use dynamic data
        onChange={(s) => setPivotState(s)}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)} // Combine renderers
        {...pivotState}
      />
    </Box>
  );
}
