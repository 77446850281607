import { createContext } from "react";
import { AppStore, GlobalStateVariable } from "./Interfaces";

export const globalState: GlobalStateVariable = {
  count: 0,
  dataForPivotTable: [],
};

const store: AppStore = {
  applicationStore: globalState,
  dispatch: () => {},
};

export const AppContext = createContext<AppStore>(store);
