import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import { useFormik } from "formik";
import * as yup from "yup";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

export default function ClassObservationForm() {
  const [open, setOpen] = useState(false);
  const [scoreOpen, setScoreOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState("");
  const [designation, setDesignation] = useState("");
  const [observers, setObservers] = useState([]);
  const [observations, setObservations] = useState([]);
  const [selectedObservation, setSelectedObservation] = useState({});
  const [variables, setVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [editVariables, setEditVariables] = useState([]);
  const [selectedObserver, setSelectedObserver] = useState(null);
  const [selectInputObserver, setSelectedInputObserver] = React.useState("");
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const [deleteSnackOpen, setDeleteSnackOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [formData, setFormData] = useState({
    SchoolID: schoolId,
    TeacherID: "",
    ClassID: "",
    SubjectID: "",
    ObserverName: "",
    Comments: "",
    Scores: [],
    ObservationDate: dayjs().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
        _fetchClassObservationVariables();
      }
    }
    if (accesLevel === "ADMIN") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
        _fetchClassObservationVariables();
      }
    }
    if (accesLevel === "CLUSTER") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
        _fetchClassObservationVariables();
      }
    }
  }, []);

  useEffect(() => {
    _fetchObservers();
    _fetchObserverations();
  }, []);

  useEffect(() => {
    console.log("edit variables", editVariables);
  }, [editVariables]);

  const handleClick = (observation) => async (event) => {
    console.log("selected observation", observation);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedObservation(observation);
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${observation.ClassID}`
      );
      console.log("subjects", data.data);
      if (status === 200) {
        setSubjects(data.data);
      }
      // formik.setFieldValue("DateofWorkshop", dayjs(e));
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    const observationData = {
      SchoolID: schoolId,
      TeacherID: formData.TeacherID,
      ClassID: formData.ClassID,
      SubjectID: formData.SubjectID,
      ObserverName: formData.ObserverName,
      ObserverDesignation: formData.ObserverDesignation,
      Comments: formData.Comments,
      Scores: selectedVariables,
      ObservationDate: formData.ObservationDate,
    };
    console.log("selectedVariables", selectedVariables);
    console.log("formData", observationData);
    setLoading((prevState) => !prevState);
    try {
      const { data, status } = await axiosInstance.post(
        "/class-observation",
        observationData
      );
      console.log("observationData", data);
      if (status === 200) {
        _fetchObserverations();
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpen(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();
    // console.log("on edit", selectedObservation);
    // console.log("on edit observer", selectedObserver);
    const observationEditData = {
      SchoolID: schoolId,
      TeacherID: selectedObservation.TeacherID,
      ClassID: selectedObservation.ClassID,
      SubjectID: selectedObservation.SubjectID,
      ObserverName: selectedObservation.ObserverName,
      ObserverDesignation: selectedObservation.ObserverDesignation,
      Comments: selectedObservation.Comments,
      Scores: selectedObservation.ClassObservationScore,
      ObservationDate: selectedObservation.ObservationDate,
    };
    console.log("on edit data", observationEditData);
    setLoading((prevState) => !prevState);
    try {
      const { data, status } = await axiosInstance.put(
        `/class-observation/${selectedObservation.id}`,
        observationEditData
      );
      console.log("observationData ==>", data);
      if (status === 200) {
        _fetchObserverations();
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpenEdit(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onDelete = async () => {
    try {
      const { data, status } = await axiosInstance.delete(
        `/class-observation/${selectedObservation.id}`
      );
      if (status === 200) {
        _fetchObserverations();
        setSnackMessage(data.message);
        setSnackOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchObserverations = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const { data, status } = await axiosInstance.get(
          `/class-observation/${schoolId}`
        );
        console.log("observerations", data.data);
        if (status === 200) {
          setObservations(data.data);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/class-observation/${schoolID}`
        );
        console.log("observerations", data.data);
        if (status === 200) {
          setObservations(data.data);
        }
      }
      if (accesLevel === "CLUSTER") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/class-observation/${schoolID}`
        );
        console.log("observerations", data.data);
        if (status === 200) {
          setObservations(data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchObservers = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school-designation`
      );
      console.log("observers", data.data);
      if (status === 200) {
        setObservers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchClassesBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-school/${id}`
      );
      console.log("classes", data.data);
      if (status === 200) {
        setClasses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchTeachersBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school/${id}`
      );
      console.log("setTeachers", data.data);
      if (status === 200) {
        setTeachers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchClassObservationVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-class-observation"
      );
      console.log("Variables", data.data);
      if (status === 200) {
        console.log(data.data);
        let tempArr = [...data.data];
        tempArr.forEach((x) => {
          x.VariablesDetailID = x.ID;
          x.score = 0;
        });
        console.log(tempArr);
        setVariables(tempArr);
        setSelectedVariables(tempArr);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        ClassID: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${e.target.value}`
      );
      console.log("subjects", data.data);
      if (status === 200) {
        setSubjects(data.data);
      }
      // formik.setFieldValue("DateofWorkshop", dayjs(e));
    } catch (error) {
      console.log(error);
    }
  };

  const _handleEditClassChange = async (e) => {
    setSelectedObservation((prevState) => {
      return {
        ...prevState,
        ClassID: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${e.target.value}`
      );
      console.log("subjects", data.data);
      if (status === 200) {
        setSubjects(data.data);
      }
      // formik.setFieldValue("DateofWorkshop", dayjs(e));
    } catch (error) {
      console.log(error);
    }
  };
  // const _handleDateOfObservation = (e) => {
  //   formik.setFieldValue("ObservationDate", dayjs(e));
  // };
  const _handleOnChange = (e) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleOnEditChange = (e) => {
    setSelectedObservation((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleVariableChange = (e, variable) => {
    console.log("variable", variable);
    setSelectedVariables((prevState) =>
      prevState.map((item) => {
        if (variable.ID === item.ID) {
          return {
            VariablesDetailID: variable.ID,
            score: e.target.value,
          };
        }
        return item;
      })
    );
  };

  const _handleEditVariableChange = (e, variable) => {
    setSelectedObservation((prevState) => {
      return {
        ...prevState,
        ClassObservationScore: prevState.ClassObservationScore.map((item) => {
          if (variable.id === item.id) {
            return {
              ...item,
              score: e.target.value,
            };
          }
          return item;
        }),
      };
    });
  };

  const _handleDateOfObservation = (e) => {
    console.log("data of observation", dayjs(e).format("YYYY-MM-DD"));
    setFormData((prevState) => {
      return {
        ...prevState,
        ObservationDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleEditDateOfObservation = (e) => {
    console.log("data of observation", dayjs(e).format("YYYY-MM-DD"));
    setSelectedObservation((prevState) => {
      return {
        ...prevState,
        ObservationDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _onObserverChange = (event, newValue) => {
    console.log("On Observer Change", newValue);
    setSelectedObserver(newValue);
    setDesignation(newValue.TeachersDesignation);
    // formik.setFieldValue("ObserverID", newValue.id);
    // formik.setFieldValue("ObserverID", newValue.id)
    setFormData((prevState) => {
      return {
        ...prevState,
        ObserverID: newValue.id,
      };
    });
  };
  const _onObserverInputValue = (event, newInputValue) => {
    setSelectedInputObserver(newInputValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const _handleObservationData = () => {
    if (!selectedObservation || !selectedObservation.Observer) {
      console.error("selectedObservation or Observer is null or undefined");
      return;
    }
  
    const observer = selectedObservation.Observer;
    const school = observer.School || {};
  
    setSelectedObserver({
      TeachersDesignation: observer.Designation?.TeachersDesignation || "",
      label: `(${observer.TeachersDesignationId || ""}) - ${
        observer.TeacherName || ""
      } - ${school.NameOfSchool || ""}`,
      id: observer.SchoolTeachersQId || "",
    });
  
    setDesignation(observer.Designation?.TeachersDesignation || "");
  };

  const handleEditOpen = () => {
    setOpenEdit(true);
    _handleObservationData();
    setEditVariables(selectedObservation.ClassObservationScore);
  };
  const _handleScoreOpen = (observation) => () => {
    console.log("selected observation", observation);
    setSelectedObservation(observation);
    setScoreOpen(true);
  };
  const handleScoreClose = () => {
    setScoreOpen(false);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Class Teacher Observation Module
          </Typography>
        </Stack>
        <Box width={300}>
          {currentPageCRUD.create && (
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Observation
              </Button>
            </FormControl>
          )}
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">School Name</TableCell>
                <TableCell align="center">Teacher Name</TableCell>
                <TableCell align="center">Observation Date</TableCell>
                <TableCell align="center">Class</TableCell>
                <TableCell align="center">Subject</TableCell>
                <TableCell align="center">Scores</TableCell>
                <TableCell align="center">Narration</TableCell>
                <TableCell align="center">Observer Name</TableCell>
                <TableCell align="center">Observer Designation</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageCRUD.read &&
                observations.map((observation, index) => {
                  return (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {observation.School
                          ? observation.School.NameOfSchool
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {observation.Teacher
                          ? observation.Teacher.TeacherName
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(observation.ObservationDate).format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {observation.ClassModel
                          ? observation.ClassModel.ClassName
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {observation.Subject
                          ? observation.Subject.SubjectName
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {currentPageCRUD.read && (
                          <Button
                            color="primary"
                            onClick={_handleScoreOpen(observation)}
                          >
                            scores
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {observation.Comments}
                      </TableCell>
                      <TableCell align="center">
                        {observation.ObserverName}
                      </TableCell>
                      <TableCell align="center">
                        {observation.ObserverDesignation}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={handleClick(observation)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={handleAnchorClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {currentPageCRUD.create && (
            <MenuItem onClick={_onDelete} color="error">
              Delete
            </MenuItem>
          )}

          {currentPageCRUD.create && (
            <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
          )}
        </Menu>
      </Stack>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Teacher Observation Form (during class)
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onSubmit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                {/* <Typography variant="span" color="error" component="h4">
                  Note: Please Select Class first before Subject.
                </Typography> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="School Name"
                  name="SchoolID"
                  value={school}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Teacher"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="TeacherID"
                  // value={formik.values.TeacherID}
                  required
                  onChange={_handleOnChange}
                  size="small"
                >
                  {teachers.map((teacher, index) => {
                    return (
                      <MenuItem key={index} value={teacher.SchoolTeachersQId}>
                        {teacher.TeacherName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Class"
                  sx={{ display: "flex", flex: 1 }}
                  // value={formik.values.ClassID}
                  variant="outlined"
                  name="ClassID"
                  required
                  onChange={_handleClassChange}
                  // error={
                  //   formik.touched.TeacherID &&
                  //   Boolean(formik.errors.TeacherID)
                  // }
                  // helperText={
                  //   formik.touched.TeacherID && formik.errors.TeacherID
                  // }
                  size="small"
                >
                  {classes.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.ClassModel.ID}>
                        {item.ClassModel.ClassName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Subjects"
                  sx={{ display: "flex", flex: 1 }}
                  // value={formik.values.SubjectID}
                  variant="outlined"
                  name="SubjectID"
                  required
                  onChange={_handleOnChange}
                  // error={
                  //   formik.touched.TeacherID &&
                  //   Boolean(formik.errors.TeacherID)
                  // }
                  // helperText={
                  //   formik.touched.TeacherID && formik.errors.TeacherID
                  // }
                  size="small"
                >
                  {subjects.map((subject, index) => {
                    return (
                      <MenuItem key={index} value={subject.ID}>
                        {subject.SubjectName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Questions" />
              </Grid>
              <Grid item xs={12} md={12}>
                {variables.map((variable, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      spacing={2}
                      sx={{ mb: 2 }}
                      alignItems="center"
                    >
                      <Grid item xs={12} md={9}>
                        {/**/}
                        <Typography variant="body1" component="h4">
                          {variable.Caption}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {/**/}
                        <TextField
                          type="hidden"
                          size="small"
                          variant="outlined"
                          sx={{ display: "flex", flex: 1, display: "none" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="School Name"
                          name="VariablesDetailID"
                          value={variable.ID}
                        />
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Performance"
                          sx={{ display: "flex", flex: 1 }}
                          // value={formik.values.SubjectID}
                          variant="outlined"
                          name="score"
                          // required
                          onChange={(e) => _handleVariableChange(e, variable)}
                          // error={
                          //   formik.touched.SchoolID && Boolean(formik.errors.SchoolID)
                          // }
                          // helperText={
                          //   formik.touched.SchoolID && formik.errors.SchoolID
                          // }
                          size="small"
                        >
                          <MenuItem value={4}>Excellent</MenuItem>
                          <MenuItem value={3}>Good</MenuItem>
                          <MenuItem value={2}>Better</MenuItem>
                          <MenuItem value={1}>Need Improvement</MenuItem>
                          <MenuItem value={0}>None</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Observation Detail" />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Narration"
                  name="Comments"
                  // value={formik.values.Comments}
                  onChange={_handleOnChange}
                  style={{ width: "100%", padding: 15 }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      // InputProps={{
                      //   readOnly: true,
                      // }}
                      label="Observer Name"
                      name="ObserverName"
                      onChange={_handleOnChange}
                    />
                    {/*<Autocomplete
                      disablePortal
                      id="combo-box-teacher"
                      options={observers.map((item, index) => {
                        return {
                          // ...item,
                          TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                          label: `(${item.SchoolTeachersQId}) - ${
                            item.TeacherName
                          } - ${
                            item.School !== null && item.School.NameOfSchool
                          }`,
                          id: item.SchoolTeachersQId,
                        };
                      })}
                      value={selectedObserver}
                      onChange={_onObserverChange}
                      inputValue={selectInputObserver}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      onInputChange={_onObserverInputValue}
                      sx={{ display: "flex", flex: 1 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          key={params.id}
                          label="Select Teacher"
                          size="small"
                        />
                      )}
                    />*/}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      label="Observer Designation"
                      name="ObserverDesignation"
                      onChange={_handleOnChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        inputFormat="DD-MM-YYYY"
                        label="Date of Observation"
                        name="ObservationDate"
                        value={formData.ObservationDate}
                        onChange={_handleDateOfObservation}
                        renderInput={(params) => (
                          <TextField
                            sx={{ display: "flex", flex: 1 }}
                            size="small"
                            required
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={12}>
                    <Typography variant="span" color="error" component="h4">
                      Note: Principal should observe one teacher per week
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={formik.handleSubmit}
                >
                  Create Observation
                  {/*{isEdit ? "Update Observation" : "Create Observation"}*/}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="md" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Teacher Observation Form (during class)
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onEdit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                {/* <Typography variant="span" color="error" component="h4">
                  Note: Please Select Class first before Subject.
                </Typography> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="School Name"
                  name="SchoolID"
                  value={school}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Teacher"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="TeacherID"
                  value={selectedObservation.TeacherID}
                  required
                  onChange={_handleOnEditChange}
                  // error={
                  //   formik.touched.TeacherID &&
                  //   Boolean(formik.errors.TeacherID)
                  // }
                  // helperText={
                  //   formik.touched.TeacherID && formik.errors.TeacherID
                  // }
                  size="small"
                >
                  {teachers.map((teacher, index) => {
                    return (
                      <MenuItem key={index} value={teacher.SchoolTeachersQId}>
                        {teacher.TeacherName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Class"
                  sx={{ display: "flex", flex: 1 }}
                  // value={formik.values.ClassID}
                  variant="outlined"
                  name="ClassID"
                  value={selectedObservation.ClassID}
                  required
                  onChange={_handleEditClassChange}
                  size="small"
                >
                  {classes.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.ClassModel.ID}>
                        {item.ClassModel.ClassName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Subjects"
                  sx={{ display: "flex", flex: 1 }}
                  value={selectedObservation.SubjectID}
                  variant="outlined"
                  name="SubjectID"
                  required
                  onChange={_handleOnEditChange}
                  size="small"
                >
                  {subjects.map((subject, index) => {
                    return (
                      <MenuItem key={index} value={subject.ID}>
                        {subject.SubjectName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Questions" />
              </Grid>
              <Grid item xs={12} md={12}>
                {Object.keys(selectedObservation).length > 0 &&
                  selectedObservation.ClassObservationScore.map(
                    (variable, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={2}
                          sx={{ mb: 2 }}
                          alignItems="center"
                        >
                          <Grid item xs={12} md={9}>
                            {/**/}
                            <Typography variant="body1" component="h4">
                              {variable.Variable &&
                              variable.Variable.Caption !== null
                                ? variable.Variable.Caption
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {/**/}
                            <TextField
                              type="hidden"
                              size="small"
                              variant="outlined"
                              sx={{ display: "flex", flex: 1, display: "none" }}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="School Name"
                              name="VariablesDetailID"
                              value={variable.ID}
                            />
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Performance"
                              sx={{ display: "flex", flex: 1 }}
                              value={variable.score}
                              variant="outlined"
                              name="score"
                              required
                              onChange={(e) =>
                                _handleEditVariableChange(e, variable)
                              }
                              size="small"
                            >
                              <MenuItem value={4}>Excellent</MenuItem>
                              <MenuItem value={3}>Good</MenuItem>
                              <MenuItem value={2}>Better</MenuItem>
                              <MenuItem value={1}>Need Improvement</MenuItem>
                              <MenuItem value={0}>None</MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Observation Detail" />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Narration"
                  name="Comments"
                  value={selectedObservation.Comments}
                  onChange={_handleOnEditChange}
                  style={{ width: "100%", padding: 15 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      // InputProps={{
                      //   readOnly: true,
                      // }}
                      label="Observer Name"
                      name="ObserverName"
                      value={selectedObservation.ObserverName}
                      onChange={_handleOnEditChange}
                    />
                    {/*<Autocomplete
                      disablePortal
                      id="combo-box-teacher"
                      options={observers.map((item, index) => {
                        return {
                          // ...item,
                          TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                          label: `(${item.SchoolTeachersQId}) - ${
                            item.TeacherName
                          } - ${
                            item.School !== null && item.School.NameOfSchool
                          }`,
                          id: item.SchoolTeachersQId,
                        };
                      })}
                      value={selectedObserver}
                      onChange={_onObserverChange}
                      inputValue={selectInputObserver}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      onInputChange={_onObserverInputValue}
                      sx={{ display: "flex", flex: 1 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          key={params.id}
                          label="Select Teacher"
                          size="small"
                        />
                      )}
                    />*/}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      // InputProps={{
                      //   readOnly: true,
                      // }}
                      value={selectedObservation.ObserverDesignation}
                      label="Observer Designation"
                      name="ObserverDesignation"
                      onChange={_handleOnEditChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        inputFormat="DD-MM-YYYY"
                        label="Date of Observation"
                        name="ObservationDate"
                        value={selectedObservation.ObservationDate}
                        onChange={_handleEditDateOfObservation}
                        renderInput={(params) => (
                          <TextField
                            sx={{ display: "flex", flex: 1 }}
                            size="small"
                            required
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={12}>
                    <Typography variant="span" color="error" component="h4">
                      Note: Principal should observe one teacher per week
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={formik.handleSubmit}
                >
                  Update Observation
                  {/*{isEdit ? "Update Observation" : "Create Observation"}*/}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={scoreOpen}
        onClose={handleScoreClose}
      >
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Teacher Observation Form (during class)
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Excellent</TableCell>
                    <TableCell align="center">Good</TableCell>
                    <TableCell align="center">Better</TableCell>
                    <TableCell align="center">Need Improvement</TableCell>
                  <TableCell align="right">None</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    //   key={String(index)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">4</TableCell>
                    <TableCell align="center">3</TableCell>
                    <TableCell align="center">2</TableCell>
                    <TableCell align="center">1</TableCell>
                    <TableCell align="right">0</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <SectionDivider text="Scores" />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Question</TableCell>
                    <TableCell align="right">Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(selectedObservation).length > 0 &&
                    selectedObservation.ClassObservationScore.map(
                      (observation, index) => {
                        return (
                          <TableRow
                            key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {observation.Variable &&
                              observation.Variable.Caption !== null
                                ? observation.Variable.Caption
                                : ""}
                            </TableCell>
                            <TableCell align="right">
                              {observation.score}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleScoreClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
