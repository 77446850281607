import { ContextPayload, GlobalStateVariable } from "./Interfaces";
import { globalState } from "./Context";

export const Reducer = (
  state: GlobalStateVariable,
  action: ContextPayload
): GlobalStateVariable => {
  // //console.log(`Dispatched with Type: ${action.Type}`);
  // //console.log(`Dispatched with Payload: ${action.Payload}`);
  state = { ...state, count: state.count ? ++state.count : 1 };
  switch (action.Type) {
    case "DATA FOR PIVOT TABLE": {
      return {
        ...state,
        dataForPivotTable: action.Payload.dataForPivotTable,
      };
    }
    default: {
      // //console.log(`Unhandled action type: ${action.Type}`);
      return state;
    }
  }
};
