import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function WeeklyAssemblyRecord() {
  const [open, setOpen] = useState(false);
  const [openScore, setOpenScore] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedWeeklyAssemblyrecords, setSelectedWeeklyAssemblyRecords] =
    useState({});
  const [personName, setPersonName] = React.useState([]);
  const [selectedStudentsData, setSelectedStudentsData] = useState([]);
  const [variables, setVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedEditStudents, setSelectedEditStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [formData, setFormData] = useState({
    School_Id: schoolId,
    TeacherID: "",
    Class_id: "",
    Student_Count: "",
    VarCatID: 18,
    Date_from: dayjs().format("YYYY-MM-DD"),
    Date_to: dayjs().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      // setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
      }
      _fetchWeeklyAssemblyRecordVariables();
      _fetchWeeklyAssemblyRecords();
    }
    if (accesLevel === "ADMIN") {
      const schoolId = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
      }
      _fetchWeeklyAssemblyRecordVariables();
      _fetchWeeklyAssemblyRecords();
    }
    if (accesLevel === "CLUSTER") {
      const schoolId = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
      }
      _fetchWeeklyAssemblyRecordVariables();
      _fetchWeeklyAssemblyRecords();
    }
  }, []);

  const _fetchWeeklyAssemblyRecordVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-weekly-assembly-record"
      );
      console.log("Variables", data.data);
      if (status === 200) {
        setVariables(data.data);
        setSelectedVariables(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchWeeklyAssemblyRecords = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        const { data, status } = await axiosInstance.get(
          `/weekly-assembly-record/${schoolID}`
        );
        console.log("setRecords", data.data);
        if (status === 200) {
          setRecords(data.data);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/weekly-assembly-record/${schoolID}`
        );
        console.log("setRecords", data.data);
        if (status === 200) {
          setRecords(data.data);
        }
      }
      if (accesLevel === "CLUSTER") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/weekly-assembly-record/${schoolID}`
        );
        console.log("setRecords", data.data);
        if (status === 200) {
          setRecords(data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchClassesBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-school/${id}`
      );
      console.log("classes", data.data);
      if (status === 200) {
        setClasses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchTeachersBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school/${id}`
      );
      console.log("setTeachers", data.data);
      if (status === 200) {
        setTeachers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    if (personName.length < 6) {
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        Date_from: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        Date_to: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleVariableChange = (e, index, student, variable) => {
    console.log(student);
    const studentId =
      variable.ID === 84 ? null : Number(student.match(/\(([^)]+)\)/)[1]);
    const { value } = e.target;

    var varObj = {
      SID: studentId,
      VariableID: variable.ID,
      Score: value,
      TeacherID: null,
      inputIndex: index,
    };

    if (variable.ID === 84) {
      varObj = {
        SID: null,
        VariableID: variable.ID,
        Score: null,
        TeacherID: value,
        inputIndex: index,
      };
    }

    const stdIndex = selectedStudents.find(
      (item) => item.VariableID === variable.ID && item.inputIndex === index
    );
    console.log("stdIndex", stdIndex);
    if (stdIndex) {
      const i = selectedStudents.indexOf(stdIndex);
      selectedStudents[i] = varObj;
    } else {
      selectedStudents.push(varObj);
    }
    console.log("stdArr", selectedStudents);
    setSelectedStudents(selectedStudents);
    const { name, Value } = e.target;
    const newVariables = [...selectedVariables];
    newVariables[index][name] = Value;
    newVariables[index].VariableID = variable.ID;

    setSelectedVariables(newVariables);
  };
  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        Class_id: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${e.target.value}`
      );
      console.log("setStudents", data.data);
      if (status === 200) {
        setStudents(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditChange = (e) => {
    const {
      target: { value },
    } = e;
    if (selectedStudentsData.length < 6) {
      setSelectedStudentsData(
        typeof value === "string" ? value.split(",") : value
      );
    }
    setSelectedWeeklyAssemblyRecords((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    console.log(selectedWeeklyAssemblyrecords.WeeklyAssemblyRecordScore);
  }, [selectedWeeklyAssemblyrecords]);

  // Function to handle 'From Date' change
  const _handleEditDateFrom = (e) => {
    setSelectedWeeklyAssemblyRecords((prevState) => ({
      ...prevState,
      Date_from: dayjs(e).format("YYYY-MM-DD"),
    }));
  };

  // Function to handle 'To Date' change
  const _handleEditDateTo = (e) => {
    setSelectedWeeklyAssemblyRecords((prevState) => ({
      ...prevState,
      Date_to: dayjs(e).format("YYYY-MM-DD"),
    }));
  };

  // Function to handle performance score change
  const _handleEditVariableChange = (
    e,
    varIndex,
    studentIndex,
    student,
    variable
  ) => {
    const { value } = e.target;
    const studentId =
      variable.Variable.ID === 84
        ? null
        : Number(student.match(/\(([^)]+)\)/)[1]);

    // Update the variable object with new values
    let updatedVariable = {
      ...variable,
      Score: value ?? 0, // Ensure Score defaults to 0 if no value
      SID: studentId,
    };

    if (variable.Variable.ID === 84) {
      updatedVariable.TeacherID = value;
    }

    // Update the selected edit students list
    const updatedStudents = selectedEditStudents.map((item) =>
      item.id === variable.id ? updatedVariable : item
    );

    setSelectedEditStudents(updatedStudents);

    // Update the main state with the new weekly assembly record scores
    setSelectedWeeklyAssemblyRecords((prevState) => ({
      ...prevState,
      WeeklyAssemblyRecordScore: updatedStudents,
    }));

    // Update assembly observation score if necessary
    const updatedWeeklyScores = [
      ...selectedWeeklyAssemblyrecords.WeeklyAssemblyRecordScore,
    ];
    updatedWeeklyScores[varIndex] = {
      ...updatedWeeklyScores[varIndex],
      [e.target.name]: value ?? 0,
    };

    setSelectedWeeklyAssemblyRecords((prevState) => ({
      ...prevState,
      AssemblyObservationScore: updatedWeeklyScores,
    }));
  };

  // Handle change in class selection
  const _handleEditClassChange = async (e) => {
    setSelectedWeeklyAssemblyRecords((prevState) => ({
      ...prevState,
      Class_id: e.target.value,
    }));
    try {
      const { data, status } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${e.target.value}`
      );
      if (status === 200) {
        setStudents(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    console.log("selectedVariables", selectedVariables);
    const record = {
      School_Id: schoolId,
      Class_id: formData.Class_id,
      Student_Count: personName.length,
      Date_from: formData.Date_from,
      Date_to: formData.Date_to,
      Scores: selectedStudents,
    };
    console.log("record ===>", record);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/weekly-assembly-record",
        record
      );
      console.log("records", data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchWeeklyAssemblyRecords();
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpen(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();

    const record = {
      School_Id: schoolId,
      Class_id: selectedWeeklyAssemblyrecords.Class_id,
      Student_Count: selectedStudentsData.length,
      Date_from: selectedWeeklyAssemblyrecords.Date_from,
      Date_to: selectedWeeklyAssemblyrecords.Date_to,
      Scores: selectedWeeklyAssemblyrecords.WeeklyAssemblyRecordScore,
    };

    try {
      setLoading(true);
      const { data, status } = await axiosInstance.put(
        `/weekly-assembly-record/${selectedWeeklyAssemblyrecords.id}`,
        record
      );

      if (status === 200) {
        _fetchWeeklyAssemblyRecords(); // Reload data after update
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpenEdit(false);
      }
    } catch (error) {
      console.error("Error while updating:", error);
    } finally {
      setLoading(false);
    }
  };
  const _onDelete = async () => {
    handleAnchorClose();
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.delete(
        `/weekly-assembly-record/${selectedWeeklyAssemblyrecords.id}`
      );
      console.log("schoolRecordData ==>", data);
      if (status === 200) {
        _fetchWeeklyAssemblyRecords();
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  // const _handleEditVariableChange = (e, varIndex, index, student, variable) => {
  //   console.log("variable in edit", variable);
  //   const studentId =
  //     variable.ID === 84 ? null : Number(student.match(/\(([^)]+)\)/)[1]);
  //   const { value } = e.target;

  //   var varObj = {
  //     SID: studentId,
  //     VariableID: variable.ID,
  //     Score: value,
  //     TeacherID: null,
  //     inputIndex: index,
  //   };

  //   if (variable.ID === 84) {
  //     varObj = {
  //       SID: null,
  //       VariableID: variable.ID,
  //       Score: null,
  //       TeacherID: value,
  //       inputIndex: index,
  //     };
  //   }

  //   const stdIndex = selectedStudents.find(
  //     (item) => item.VariableID === variable.ID && item.inputIndex === index
  //   );
  //   if (stdIndex) {
  //     const i = selectedStudents.indexOf(stdIndex);
  //     selectedStudents[i] = varObj;
  //   } else {
  //     selectedStudents.push(varObj);
  //   }
  //   console.log("stdArr", selectedStudents);
  //   setSelectedWeeklyAssemblyRecords((prevState) => {
  //     return {
  //       ...prevState,
  //       WeeklyAssemblyRecordScore: selectedStudents,
  //     };
  //   });
  // };

  const handleClose = () => {
    setOpen(false);
  };
  const _handleScoreOpen = (record) => () => {
    console.log("selected assembly records", record);
    setSelectedWeeklyAssemblyRecords(record);
    setOpenScore(true);
  };
  const handleScoreClose = () => {
    setOpenScore(false);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
    handleAnchorClose();
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleClick = (record) => async (event) => {
    console.log("event", event);
    console.log("record", record);
    let listWithoutDuplicates = record.WeeklyAssemblyRecordScore.filter(
      (ele, i) =>
        i ===
          record.WeeklyAssemblyRecordScore.findIndex(
            (elem) => elem.SID === ele.SID
          ) && i.SID !== null
    );
    const stdWholeData = listWithoutDuplicates.filter((x) => x.SID !== null);
    const stdData = stdWholeData.map((x) => {
      if (x.SID) {
        return `(${x.Student.SID}) ${x.Student.StudentName}`;
      }
    });
    console.log(listWithoutDuplicates);
    console.log("listWithoutDuplicates", stdData);
    setSelectedStudentsData(stdData);
    setAnchorEl(event.currentTarget);
    setSelectedWeeklyAssemblyRecords(record);
    setSelectedEditStudents(record.WeeklyAssemblyRecordScore);
    try {
      const { data, status } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${record.Class_id}`
      );
      console.log("setStudents", data.data);
      if (status === 200) {
        setStudents(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Weekly Assembly Record
          </Typography>
          <Typography variant="subtitle2" component="span">
            Click Add Record button to generate a new Record.
          </Typography>
        </Stack>
        <Box width={300}>
          {currentPageCRUD.create && (
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Record
              </Button>
            </FormControl>
          )}
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Class</TableCell>
                <TableCell align="center">Number Of Students</TableCell>
                <TableCell align="center">From Date</TableCell>
                <TableCell align="center">To Date</TableCell>
                <TableCell align="center">Scores</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageCRUD.read &&
                records
                  .sort((a, b) => new Date(b.Date_from) - new Date(a.Date_from))
                  .map((record, index) => {
                    return (
                      <TableRow
                        //   key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {record.ClassModel?.ClassName}
                        </TableCell>
                        <TableCell align="center">
                          {record.Student_Count}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(record.Date_from).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(record.Date_to).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {currentPageCRUD.create && (
                            <Button
                              contained
                              color="primary"
                              onClick={_handleScoreOpen(record)}
                            >
                              Score
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={handleClick(record)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={handleAnchorClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {currentPageCRUD.delete && (
            <MenuItem onClick={_onDelete} color="error">
              Delete
            </MenuItem>
          )}

          {currentPageCRUD.update && (
            <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
          )}
        </Menu>
      </Stack>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Weekly Assembly Record <br />
              Baithak School Network
            </Typography>
            <Typography variant="subtitle2" component="span">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onSubmit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="span" color="error" component="h4">
                  Note: You can select a maximum of 6 Students.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Class"
                  sx={{ display: "flex", flex: 1 }}
                  // value={formik.values.SubjectID}
                  variant="outlined"
                  name="ClassID"
                  required
                  onChange={_handleClassChange}
                  size="small"
                >
                  {classes.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.ClassModel.ID}>
                        {item.ClassModel?.ClassName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    id="demo-multiple-chip-label"
                    sx={{ top: "-7px" }}
                  >
                    Select Students
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    name="SID"
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Select Students"
                        size="small"
                        name="SID"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {students.map((student, index) => (
                      <MenuItem
                        key={index}
                        value={`(${student.SID}) ${student.StudentName}`}
                      >
                        {student.StudentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="From Date"
                    name="Date_from"
                    value={formData.Date_from}
                    onChange={_handleDateFrom}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="To Date"
                    name="Date_to"
                    value={formData.Date_to}
                    onChange={_handleDateTo}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Excellent</TableCell>
                        <TableCell align="center">Good</TableCell>
                        <TableCell align="center">Better</TableCell>
                        <TableCell align="center">Normal</TableCell>
                        <TableCell align="center">Need Improvement</TableCell>
                        <TableCell align="right">Fail</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        //   key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          5
                        </TableCell>
                        <TableCell align="center">4</TableCell>
                        <TableCell align="center">
                          {/* {dayjs(participant.ArrivalDatetime).format("DD-MM-YYYY")} */}
                          3
                        </TableCell>
                        <TableCell align="center">2</TableCell>
                        <TableCell align="center">1</TableCell>
                        <TableCell align="right">0</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Activities</TableCell>
                        {personName.map((name, index) => {
                          return (
                            <TableCell key={index} align="center">
                              {name}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {variables.map((variable, varIndex) => {
                        if (
                          variable.Caption === "Speech of Principal / Teacher"
                        ) {
                          return (
                            <TableRow
                              key={String(varIndex)}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <strong>{variable.Caption}</strong>
                              </TableCell>
                              {personName.length > 0 && (
                                <TableCell key={varIndex} align="center">
                                  <TextField
                                    id="standard-select-currency"
                                    select
                                    label="Teacher"
                                    sx={{ display: "flex", flex: 1 }}
                                    // value={formik.values.SubjectID}
                                    variant="outlined"
                                    name="TeacherID"
                                    // required
                                    onChange={(e) =>
                                      _handleVariableChange(
                                        e,
                                        varIndex,
                                        null,
                                        variable
                                      )
                                    }
                                    size="small"
                                  >
                                    {teachers.map((teacher, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={teacher.SchoolTeachersQId}
                                        >
                                          {teacher.TeacherName}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        }
                        return (
                          <TableRow
                            key={String(varIndex)}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "15%" }}
                            >
                              <strong>{variable.Caption}</strong>
                            </TableCell>
                            {personName.map((name, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  align="center"
                                  sx={{ width: "12%" }}
                                >
                                  <TextField
                                    id="standard-select-currency"
                                    select
                                    label="Performance"
                                    sx={{ display: "flex", flex: 1 }}
                                    // value={formik.values.SubjectID}
                                    variant="outlined"
                                    name="Score"
                                    // required
                                    onChange={(e) =>
                                      _handleVariableChange(
                                        e,
                                        index,
                                        name,
                                        variable
                                      )
                                    }
                                    size="small"
                                  >
                                    <MenuItem value={5}>Excellent</MenuItem>
                                    <MenuItem value={4}>Good</MenuItem>
                                    <MenuItem value={3}>Better</MenuItem>
                                    <MenuItem value={2}>Normal</MenuItem>
                                    <MenuItem value={1}>
                                      Need Improvement
                                    </MenuItem>
                                    <MenuItem value={0}>Fail</MenuItem>
                                  </TextField>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle2" component="span">
                  Note: Dedicate the whole week to single class (from KG to V)
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  // loading={loading}
                  // disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={formik.handleSubmit}
                >
                  Create Record
                  {/* {isEdit ? "Update Observation" : "Create Observation"} */}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="lg" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Weekly Assembly Record <br />
              Baithak School Network
            </Typography>
            <Typography variant="subtitle2" component="span">
              Society for Educational Welfare (Regd.)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={_onEdit}>
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Typography variant="span" color="error" component="h4">
                  Note: Max 6 Students you can select.
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Select Class"
                  sx={{ display: "flex", flex: 1 }}
                  value={selectedWeeklyAssemblyrecords.Class_id || ""}
                  variant="outlined"
                  name="ClassID"
                  required
                  onChange={_handleEditClassChange}
                  size="small"
                >
                  {classes.map((item, index) => (
                    <MenuItem key={index} value={item.ClassModel.ID}>
                      {item.ClassModel?.ClassName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="select-students-label" sx={{ top: "-7px" }}>
                    Select Students
                  </InputLabel>
                  <Select
                    labelId="select-students-label"
                    multiple
                    value={selectedStudentsData}
                    onChange={handleEditChange}
                    name="SID"
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Select Students"
                        size="small"
                        name="SID"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {students.map((student, index) => (
                      <MenuItem
                        key={index}
                        value={`(${student.SID}) ${student.StudentName}`}
                      >
                        {student.StudentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="From Date"
                    name="Date_from"
                    value={selectedWeeklyAssemblyrecords.Date_from}
                    onChange={_handleEditDateFrom}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="To Date"
                    name="Date_to"
                    value={selectedWeeklyAssemblyrecords.Date_to}
                    onChange={_handleEditDateTo}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Excellent</TableCell>
                        <TableCell align="center">Good</TableCell>
                        <TableCell align="center">Better</TableCell>
                        <TableCell align="center">Normal</TableCell>
                        <TableCell align="center">Need Improvement</TableCell>
                        <TableCell align="right">Fail</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          5
                        </TableCell>
                        <TableCell align="center">4</TableCell>
                        <TableCell align="center">3</TableCell>
                        <TableCell align="center">2</TableCell>
                        <TableCell align="center">1</TableCell>
                        <TableCell align="right">0</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Activities</TableCell>
                        {selectedStudentsData.map((name, index) => (
                          <TableCell key={index} align="center">
                            {name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {selectedWeeklyAssemblyrecords.WeeklyAssemblyRecordScore?.map(
                        (variable, varIndex) => {
                          if (
                            variable.Variable.Caption ===
                            "Speech of Principal / Teacher"
                          ) {
                            console.log(variable.Teacher);
                            return (
                              <TableRow
                                key={String(varIndex)}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <strong>{variable.Variable.Caption}</strong>
                                </TableCell>
                                {/* {personName.length > 0 && ( */}
                                <TableCell key={varIndex} align="center">
                                  <TextField
                                    id="standard-select-currency"
                                    select
                                    label="Teacher"
                                    sx={{ display: "flex", flex: 1 }}
                                    defaultValue={
                                      variable.Teacher?.SchoolTeachersQId
                                    }
                                    variant="outlined"
                                    name="TeacherID"
                                    // required
                                    onChange={(e) =>
                                      _handleEditVariableChange(
                                        e,
                                        varIndex,
                                        varIndex,
                                        null,
                                        variable
                                      )
                                    }
                                    size="small"
                                  >
                                    {teachers.map((teacher, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={teacher.SchoolTeachersQId}
                                        >
                                          {teacher.TeacherName}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                </TableCell>
                                {/* )} */}
                              </TableRow>
                            );
                          } else {
                            return (
                              <TableRow
                                key={varIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <strong>{variable.Variable.Caption}</strong>
                                </TableCell>
                                {selectedStudentsData?.map((name, index) => {
                                  //FOUZ HACKER,NOOR JUGARU,ARHAB
                                  function setValue(variable, name_) {
                                    var regExp = /\(([^)]+)\)/;
                                    var SID = parseInt(
                                      regExp
                                        .exec(name_)[0]
                                        .substring(
                                          1,
                                          regExp.exec(name_)[0].length
                                        )
                                    );

                                    //matches[1] contains the value between the parentheses
                                    if (SID === variable.SID) {
                                      return variable.Score;
                                    }
                                    return -1;
                                  }

                                  return (
                                    <TableCell
                                      key={index}
                                      align="center"
                                      sx={{ width: "12%" }}
                                    >
                                      <TextField
                                        select
                                        label="Performance"
                                        sx={{ display: "flex", flex: 1 }}
                                        value={
                                          setValue(variable, name)
                                          // variable.SID !== null
                                          //           ? variable.Score ?? 0
                                          //           : 0
                                        } // Ensure a fallback value
                                        variant="outlined"
                                        name="Score"
                                        required
                                        onChange={(e) =>
                                          _handleEditVariableChange(
                                            e,
                                            varIndex,
                                            index,
                                            name,
                                            variable
                                          )
                                        }
                                        size="small"
                                      >
                                        <MenuItem value={-1}></MenuItem>
                                        <MenuItem value={5}>Excellent</MenuItem>
                                        <MenuItem value={4}>Good</MenuItem>
                                        <MenuItem value={3}>Better</MenuItem>
                                        <MenuItem value={2}>Normal</MenuItem>
                                        <MenuItem value={1}>
                                          Need Improvement
                                        </MenuItem>
                                        <MenuItem value={0}>Fail</MenuItem>
                                      </TextField>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          }
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Typography variant="subtitle2" component="span">
                  Note: Dedicate the whole week to a single class (from KG to V)
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  loading={loading}
                  disabled={loading}
                >
                  Update Record
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openScore}
        onClose={handleScoreClose}
      >
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Weekly Assembly Record
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for Educational Welfare (Regd)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Excellent</TableCell>
                    <TableCell align="center">Good</TableCell>
                    <TableCell align="center">Better</TableCell>
                    <TableCell align="center">Normal</TableCell>
                    <TableCell align="center">Need Improvement</TableCell>
                    <TableCell align="right">Fail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    //   key={String(index)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      5
                    </TableCell>
                    <TableCell align="center">4</TableCell>
                    <TableCell align="center">
                      {/* {dayjs(participant.ArrivalDatetime).format("DD-MM-YYYY")} */}
                      3
                    </TableCell>
                    <TableCell align="center">2</TableCell>
                    <TableCell align="center">1</TableCell>
                    <TableCell align="right">0</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <SectionDivider text="Scores" />
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ width: "33.33%" }}>
                      Question
                    </TableCell>
                    <TableCell align="center" sx={{ width: "33.33%" }}>
                      Student / Teacher / Principal Name
                    </TableCell>
                    <TableCell align="right" sx={{ width: "33.33%" }}>
                      Score
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(selectedWeeklyAssemblyrecords).length > 0 &&
                    selectedWeeklyAssemblyrecords.WeeklyAssemblyRecordScore.map(
                      (record, index) => {
                        return (
                          <TableRow
                            key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "33.33%" }}
                            >
                              <strong>{record.Variable.Caption}</strong>
                            </TableCell>
                            <TableCell align="center" sx={{ width: "33.33%" }}>
                              {record.SID !== null &&
                                record.Student.StudentName}
                              {record.TeacherID !== null &&
                                `${
                                  record.Teacher
                                    ? record.Teacher.TeacherName
                                    : ""
                                } (Teacher / Principle)`}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              sx={{ width: "33.33%" }}
                            >
                              {record.Score !== null
                                ? record.Score
                                : "Not Performed"}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleScoreClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
