import {
  Button,
  CardContent,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Divider,
  FormControl,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axiosInstance from "../../Api/axios.instance";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuizIcon from "@mui/icons-material/Quiz";
import MuiAlert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import useSession from "../../CustomHooks/useSession";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Variables() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [snackbar, setSnackBar] = useState(false);
  const [optionModal, setOptionModal] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [catOptionModal, setCatOptionModal] = useState(false);
  const [editCatOptionModal, setEditCatOptionModal] = useState(false);
  const [optionAnchorEl, setOptionAnchorEl] = useState(null);
  const openOptionAnchor = Boolean(optionAnchorEl);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [formData, setFormData] = useState({
    VariableCatName: "",
    Caption: [
      {
        IndexID: null,
        Caption: "",
      },
    ],
  });

  const [options, setOptions] = useState({
    VarCatID: "",
    Caption: [
      {
        IndexID: null,
        Caption: "",
      },
    ],
  });

  const _resetForm = () => {
    setFormData({
      VariableCatName: "",
      Caption: [
        {
          IndexID: null,
          Caption: "",
        },
      ],
    });
  };
  const _resetOptionForm = () => {
    setOptions({
      VarCatID: "",
      Caption: [
        {
          IndexID: null,
          Caption: "",
        },
      ],
    });
  };
  useEffect(() => {
    console.log("useEffect ===>", formData.Caption);
    _fetchVariables();
    if (formData.VariableCatID) {
      _fetchVariablesByID();
    } else {
      console.error("VariableCatID is undefined, skipping fetch by ID.");
    }
  }, [formData.Caption]);
  const addVariableOptionFields = () => {
    setFormData((prevState) => {
      return {
        ...prevState,
        Caption: [...formData.Caption, { IndexID: null, Caption: "" }],
      };
    });
  };
  const addOptionFields = () => {
    setOptions((prevState) => {
      return {
        ...prevState,
        Caption: [...options.Caption, { IndexID: null, Caption: "" }],
      };
    });
  };
  const removeVariableOptionFields = (key) => {
    let newFormVariables = [...formData.Caption];
    newFormVariables.splice(key, 1);
    setFormData((prevState) => {
      return {
        ...prevState,
        Caption: newFormVariables,
      };
    });
  };

  const removeOptionFields = (key) => {
    if (!Array.isArray(options.Caption)) {
      console.error("Caption inside options is not an array:", options.Caption);
      return;
    }
    let newOptions = [...options.Caption];
    newOptions.splice(key, 1);
    setOptions((prevState) => {
      return {
        ...prevState,
        Caption: newOptions,
      };
    });
  };

  const _handleOnChange = (e, key) => {
    const { name, value } = e?.target;
    const list = [...formData.Caption];
    list[key][name] = value;
    // let listWithoutDuplicates = list.filter(
    //   (ele, i) => i === list.findIndex((elem) => elem.IndexID === ele.IndexID)
    // );
    setFormData((prevState) => {
      return {
        ...prevState,
        Caption: list,
      };
    });
  };
  const _handleOptionChange = (e, key) => {
    const { name, value } = e.target;
    const list = [...options.Caption];
    list[key][name] = value;
    setOptions((prevState) => {
      return {
        ...prevState,
        VarCatID: selectedVariables.ID,
        Caption: list,
      };
    });
  };
  const _handleEditOptionChange = (e) => {
    setSelectedOption((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _handleCatChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _fetchVariables = async () => {
    const { data, status } = await axiosInstance.get("/variables");
    try {
      if (status === 200) {
        setVariables(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchVariablesByID = async (id) => {
    const { data, status } = await axiosInstance.get(
      `/variables/options/${id}`
    );
    console.log("Variables", data.data);
    try {
      if (status === 200) {
        setSelectedVariables(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    setLoading((prevState) => !prevState);
    // const formData = new FormData(e.currentTarget);
    try {
      const { data, status } = await axiosInstance.post("/variables", formData);
      console.log("response data add ===>", data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchVariables();
        setSnackMessage(data.message);
        setSnackBar(true);
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setLoading((prevState) => !prevState);
    }
  };
  const _onOptionSubmit = async (e) => {
    e.preventDefault();
    setLoading((prevState) => !prevState);
    try {
      const { data, status } = await axiosInstance.post(
        "/variables/options",
        options
      );
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchVariablesByID(selectedVariables.ID);
        setSnackMessage(data.message);
        setSnackBar(true);
        setCatOptionModal(false);
      }
    } catch (error) {
      console.log(error);
      setLoading((prevState) => !prevState);
    }
  };
  const onOptionEdit = () => {
    _handleEditCatOptionModalOpen();
    handleOptionAnchorClose();
  };
  const onOptionDelete = async () => {
    try {
      const { data, status } = await axiosInstance.delete(
        `/variables/options/${selectedOption.ID}`
      );
      console.log("data response update", data);
      if (status === 200) {
        _fetchVariablesByID(selectedOption.VarCatID);
        setSnackMessage(data.message);
        setSnackBar(true);
        handleOptionAnchorClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onOptionEdit = async (e) => {
    e.preventDefault();
    setLoading((prevState) => !prevState);
    try {
      const { data, status } = await axiosInstance.put(
        `/variables/options/${selectedOption.ID}`,
        selectedOption
      );
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchVariablesByID(selectedOption.VarCatID);
        setSnackMessage(data.message);
        setSnackBar(true);
        setEditCatOptionModal(false);
      }
    } catch (error) {
      console.log(error);
      setLoading((prevState) => !prevState);
    }
  };
  const _handleOptionModalOpen = (variable) => () => {
    console.log("selected variable", variable);
    setSelectedVariables(variable);
    setOptionModal(true);
  };
  const _handleOptionModalClose = () => {
    setOptionModal(false);
  };
  const _handleCatOptionModalOpen = () => {
    _resetOptionForm();
    setCatOptionModal(true);
  };
  const _handleCatOptionModalClose = () => {
    setCatOptionModal(false);
  };
  const _handleEditCatOptionModalOpen = () => {
    _resetOptionForm();
    setEditCatOptionModal(true);
  };
  const _handleEditCatOptionModalClose = () => {
    setEditCatOptionModal(false);
  };
  const handleOptionClick = (variable) => (event) => {
    console.log("event", event);
    setOptionAnchorEl(event.currentTarget);
    setSelectedOption(variable);
  };
  const handleOptionAnchorClose = () => {
    setOptionAnchorEl(null);
  };
  const handleOpen = () => {
    _resetForm();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };
  return (
    <>
      <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" component="h1">
            Variable Module
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {currentPageCRUD.create && (
            <FormControl fullWidth>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Add Variable
              </Button>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider sx={{ mt: 1, mb: 2 }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Variable Category Name</TableCell>
                  <TableCell align="left">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageCRUD.read &&
                  variables?.map((variable, index) => {
                    return (
                      <>
                        <TableRow
                          key={String(index)}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {variable.VariableCatName}
                          </TableCell>
                          {currentPageCRUD.create && (
                            <TableCell align="left">
                              <Button
                                color="primary"
                                onClick={_handleOptionModalOpen(variable)}
                              >
                                options
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Create Variable</DialogTitle>
        <DialogContent>
          <Box onSubmit={_onSubmit} component="form">
            <Box flexGrow={1}>
              <Stack
                sx={{ mb: 2 }}
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={8}>
                    <TextField
                      label="Variable Category Name"
                      variant="outlined"
                      size="small"
                      sx={{ display: "flex", flex: 1, mt: 2 }}
                      name="VariableCatName"
                      onChange={_handleCatChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>
              </Stack>
              <Card variant="outlined" sx={{ width: "100%", mb: 2 }}>
                <CardContent>
                  {formData.Caption.map((element, index) => {
                    return (
                      <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        sx={{ mb: 2 }}
                        key={index}
                      >
                        <Grid item xs={12} md={8}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={10}>
                              <TextField
                                label="Variable Options"
                                variant="outlined"
                                size="small"
                                sx={{ display: "flex", flex: 1 }}
                                name="Caption"
                                value={element.Caption}
                                onChange={(e) => _handleOnChange(e, index)}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <TextField
                                label="Index"
                                variant="outlined"
                                size="small"
                                sx={{ display: "flex", flex: 1 }}
                                name="IndexID"
                                type="number"
                                value={element.IndexID}
                                onChange={(e) => _handleOnChange(e, index)}
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6}>
                              {formData.Caption.length !== 1 && (
                                <Button
                                  color="error"
                                  variant="outlined"
                                  sx={{ mr: 1, width: "100%" }}
                                  size="small"
                                  onClick={() =>
                                    removeVariableOptionFields(index)
                                  }
                                >
                                  <DoNotDisturbOnIcon sx={{ mr: 1 }} />
                                  Remove
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {formData.Caption.length - 1 === index && (
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "100%" }}
                                  onClick={() => addVariableOptionFields()}
                                >
                                  <AddCircleIcon sx={{ mr: 1 }} />
                                  Add
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </CardContent>
              </Card>
              {/* <Box>{JSON.stringify(formData)}</Box> */}
              <FormControl fullWidth>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Create Variable
                </LoadingButton>
                {/* <Button

                // onClick={handleOpen}
                ></Button> */}
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={optionModal}
        onClose={_handleOptionModalClose}
      >
        <DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              Variables of {selectedVariables.VariableCatName}
            </Grid>
            <Grid item xs={2}>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                sx={{ width: "100%" }}
                onClick={_handleCatOptionModalOpen}
              >
                <AddCircleIcon sx={{ mr: 1 }} />
                Add
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={() => {}} component="form">
            <Box flexGrow={1}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Variables</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedVariables?.Variable?.map((variable, index) => {
                      return (
                        <>
                          <TableRow
                            // key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Stack flexDirection="row" alignItems="center">
                                <QuizIcon color="primary" sx={{ mr: 2 }} />
                                <Typography
                                  variant="subtitle2"
                                  component="span"
                                >
                                  {variable.Caption}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton onClick={handleOptionClick(variable)}>
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
                <Menu
                  id="basic-menu"
                  anchorEl={optionAnchorEl}
                  open={openOptionAnchor}
                  onClose={handleOptionAnchorClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={onOptionDelete}>Delete</MenuItem>
                  <MenuItem onClick={onOptionEdit}>Edit</MenuItem>
                </Menu>
              </TableContainer>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handleOptionModalClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={catOptionModal}
        onClose={_handleCatOptionModalClose}
      >
        <DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              Add {selectedVariables.VariableCatName} Variables
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onOptionSubmit} component="form">
            <Box flexGrow={1}>
              <Card variant="outlined" sx={{ width: "100%", mb: 2 }}>
                <CardContent>
                  {options.Caption.map((element, index) => {
                    return (
                      <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        sx={{ mb: 2 }}
                        key={index}
                      >
                        <Grid item xs={12} md={8}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={9}>
                              <TextField
                                label="Variable Options"
                                variant="outlined"
                                size="small"
                                sx={{ display: "flex", flex: 1 }}
                                name="Caption"
                                value={element.Caption}
                                onChange={(e) => _handleOptionChange(e, index)}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <TextField
                                label="Index"
                                variant="outlined"
                                size="small"
                                sx={{ display: "flex", flex: 1 }}
                                name="IndexID"
                                type="number"
                                value={element.IndexID}
                                onChange={(e) => _handleOptionChange(e, index)}
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6}>
                              {options.Caption.length !== 1 && (
                                <Button
                                  color="error"
                                  variant="outlined"
                                  sx={{ mr: 1, width: "100%" }}
                                  size="small"
                                  onClick={() => removeOptionFields(index)}
                                >
                                  <DoNotDisturbOnIcon sx={{ mr: 1 }} />
                                  Remove
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {options.Caption.length - 1 === index && (
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "100%" }}
                                  onClick={() => addOptionFields()}
                                >
                                  <AddCircleIcon sx={{ mr: 1 }} />
                                  Add
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </CardContent>
              </Card>
              <FormControl fullWidth>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save Variable
                </LoadingButton>
                {/* <Button

                // onClick={handleOpen}
                ></Button> */}
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handleCatOptionModalClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={editCatOptionModal}
        onClose={_handleEditCatOptionModalClose}
      >
        <DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              Update Variable
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onOptionEdit} component="form">
            <Box flexGrow={1}>
              <Card variant="outlined" sx={{ width: "100%", mb: 2 }}>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    sx={{ mb: 2 }}
                  >
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={9}>
                          <TextField
                            label="Variable Options"
                            variant="outlined"
                            size="small"
                            sx={{ display: "flex", flex: 1 }}
                            name="Caption"
                            value={selectedOption.Caption}
                            onChange={_handleEditOptionChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            label="Index"
                            variant="outlined"
                            size="small"
                            sx={{ display: "flex", flex: 1 }}
                            name="IndexID"
                            type="number"
                            value={selectedOption.IndexID}
                            onChange={_handleEditOptionChange}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <FormControl fullWidth>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save Variable
                </LoadingButton>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handleEditCatOptionModalClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
