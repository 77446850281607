import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

export default function TestResults() {
  const { state: locationState } = useLocation();
  const {
    ClassID,
    MaxMarks,
    ClassModel,
    PassingPercentage,
    SchoolID,
    Subject,
    SubjectVariation,
    SubjectID,
    TestDate,
    Session,
    id,
  } = locationState.selectedTest;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [students, setStudents] = useState([]);
  const [obtainedPercentage, setObtainedPercentage] = useState(null);
  const [schoolIdentity, setSchoolIdentity] = useState(null);
  const [school, setSchool] = useState("");
  const { accesLevel, access } = useSession();
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);

  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolID = accessObject[0].SchoolId;
      console.log("school id", schoolID);
      setSchoolIdentity(schoolID);
      if (Object.keys(locationState.selectedTest).length > 0) {
        console.log("schoolId", schoolID);
        _fetchStudentsByClass(schoolID, ClassID);
      }
    }
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      console.log("school id", schoolID);
      setSchoolIdentity(schoolID);
      if (Object.keys(locationState.selectedTest).length > 0) {
        console.log("schoolId", schoolID);
        _fetchStudentsByClass(schoolID, ClassID);
      }
    }
  }, []);

  useEffect(() => {
    console.log("locationState", locationState);
    console.log("students", students);
  }, []);

  const _fetchStudentsByClass = async (schoolId, classId) => {
    try {
      const { data: testResponse, status } = await axiosInstance.get(
        `/test-results/get-test-results-by-school/${schoolId}/${id}`
      );
      console.log("testResponse", testResponse.data.length);
      if (testResponse.data.length === 0) {
        const { data, status } = await axiosInstance.get(
          `/students/by-school-class/${schoolId}/${classId}`
        );
        if (data.data.length > 0) {
          const transformData = data.data.map((item) => {
            return {
              TestID: id,
              MaxMarks: MaxMarks,
              PassingPercentage: PassingPercentage,
              Subject: Subject,
              TestDate: TestDate,
              Present: true,
              ObtainedMarks: 0,
              SID: item.SID,
              GRNO: item.GRNO,
              CurrentSession: item.CurrentSession,
              ActiveSession: item.ActiveSession,
              StudentName: item.StudentName,
              SchoolID: schoolId,
            };
          });
          console.log("In if test result data", data.data);
          setStudents(transformData);
          console.log("transformData", transformData);
          // setShowDeleteButton(false);
        }
      } else if (testResponse.data.length > 0) {
        console.log("In else if test result data", testResponse.data);
        setStudents(testResponse.data);
        console.log("data.data", testResponse.data);
        // setShowDeleteButton(true);
      } else {
        setStudents([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();

    console.log("students", students);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/test-results/post-test-results",
        { results: students }
      );
      console.log("response after post", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
        if (accesLevel === "SCHOOL") {
          const accessObject = Object.assign({}, access);
          const schoolID = accessObject[0].SchoolId;
          console.log("school id", schoolID);
          setSchoolIdentity(schoolID);
          if (Object.keys(locationState.selectedTest).length > 0) {
            console.log("schoolId", schoolID);
            _fetchStudentsByClass(schoolID, ClassID);
          }
        }
        if (accesLevel === "ADMIN") {
          const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
            .SchoolId;
          console.log("school id", schoolID);
          setSchoolIdentity(schoolID);
          if (Object.keys(locationState.selectedTest).length > 0) {
            console.log("schoolId", schoolID);
            _fetchStudentsByClass(schoolID, ClassID);
          }
        }
      }
      console.log("locationState");
      console.log(locationState);
      navigate("/test-management", {
        state: {
          defaultVals: { ...locationState.params },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const _onDelete = async () => {
  //   handleAnchorClose();
  //   setDialogOpen(true);
  // };

  // const _handleDeleteAllTestResults = async () => {
  //   console.log("selectedTestResult", students);
  //   const results = students.map((student) => {
  //     return {
  //       TestID: student.TestID,
  //       SchoolID: student.SchoolID,
  //     };
  //   });
  //   console.log("selectedTestResult", results);
  //   if (results.length > 0) {
  //     const schoolId = results[0].SchoolID;
  //     // const id = Number(id);
  //     console.log("schoolId ===>", schoolId);
  //     console.log("TestID ===>", id);
  //     try {
  //       const { data, status } = await axiosInstance.delete(
  //         `/test-results/delete-test-results-by-school/${schoolId}/${id}`,
  //         { results }
  //       );
  //       if (status === 200) {
  //         setDialogOpen(false);
  //         setSnackOpen(true);
  //         setSnackMessage(data.message);
  //         if (accesLevel === "SCHOOL") {
  //           const accessObject = Object.assign({}, access);
  //           const schoolID = accessObject[0].SchoolId;
  //           console.log("school id", schoolID);
  //           setSchoolIdentity(schoolID);
  //           if (Object.keys(locationState.selectedTest).length > 0) {
  //             console.log("schoolId", schoolID);
  //             _fetchStudentsByClass(schoolID, ClassID);
  //           }
  //         }
  //         if (accesLevel === "ADMIN") {
  //           const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
  //             .SchoolId;
  //           console.log("school id", schoolID);
  //           setSchoolIdentity(schoolID);
  //           if (Object.keys(locationState.selectedTest).length > 0) {
  //             console.log("schoolId", schoolID);
  //             _fetchStudentsByClass(schoolID, ClassID);
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const _handleOnChange = (e, index, student) => {
    const { name, value } = e.target;
    const list = [...students];
    list[index].ObtainedMarks = value <= MaxMarks ? value : "";

    setStudents(list);
  };

  const _handleOnCheckBoxChange = (e, index, student) => {
    const { name, value } = e.target;
    const list = [...students];
    list[index].Present = !list[index].Present;
    list[index].ObtainedMarks = student.Present === false ? 0 : value;

    setStudents(list);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
  };

  // const handleDialogClose = () => {
  //   setDialogOpen(false);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };

  const navigate = useNavigate();

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Test Results Module
          </Typography>
        </Stack>
        {/* <Box width={300}>
          {showDeleteButton && (
            <FormControl fullWidth>
              <Button variant="contained" color="error" onClick={_onDelete}>
                Delete Test Results
              </Button>
            </FormControl>
          )}
        </Box> */}
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box onSubmit={_onSubmit} component="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Is Present</TableCell>
                      <TableCell align="center">Test Date</TableCell>
                      <TableCell align="center">Session</TableCell>
                      <TableCell align="center">GRNO</TableCell>
                      <TableCell align="center">Student Name</TableCell>
                      <TableCell align="center">Subject Name</TableCell>
                      <TableCell align="center">Subject Variation</TableCell>
                      <TableCell align="center">Max Marks</TableCell>
                      <TableCell align="center">Obtained Marks</TableCell>
                      <TableCell align="center">Passing Percentage</TableCell>
                      <TableCell align="right">Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.length > 0 &&
                      students.map((student, index) => {
                        return (
                          <TableRow
                            key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <FormControlLabel
                                value={student.Present}
                                name="Present"
                                control={<Checkbox checked={student.Present} />}
                                onChange={(e) =>
                                  _handleOnCheckBoxChange(e, index, student)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(TestDate).format("YYYY-MM-DD")}
                            </TableCell>
                            <TableCell align="center">{Session}</TableCell>
                            <TableCell align="center">
                              {student.GRNO
                                ? student.GRNO
                                : student.Student.GRNO}
                            </TableCell>
                            <TableCell align="center">
                              {student.StudentName
                                ? student.StudentName
                                : student.Student.StudentName}
                            </TableCell>
                            <TableCell align="center">
                              {Subject.SubjectName}
                            </TableCell>
                            <TableCell align="center">
                              {SubjectVariation === "" ||
                              SubjectVariation === null ||
                              SubjectVariation === undefined
                                ? "None"
                                : SubjectVariation}
                            </TableCell>
                            <TableCell align="center">{MaxMarks}</TableCell>
                            <TableCell align="center">
                              <TextField
                                size="small"
                                variant="outlined"
                                sx={{ display: "flex", flex: 1 }}
                                type="number"
                                label="Obtained Marks"
                                name="ObtainedMarks"
                                // disabled={student.Present}
                                value={student.ObtainedMarks}
                                required
                                onChange={(e) =>
                                  _handleOnChange(e, index, student)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">{`${PassingPercentage}%`}</TableCell>
                            <TableCell align="right">{`${
                              student.ObtainedMarks
                                ? (
                                    (student.ObtainedMarks * 100) /
                                    MaxMarks
                                  ).toFixed(2)
                                : 0
                            }%`}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                SAVE CHANGES
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={handleAnchorClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            //   onClick={_onDelete}
            color="error"
          >
            Delete
          </MenuItem>
          <MenuItem
          //   onClick={handleEditOpen}
          >
            Edit
          </MenuItem>
        </Menu>
      </Stack>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Create Test Results
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={() => {}} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              {/*<Grid item xs={12} md={12}>
                <Typography variant="span" color="error" component="h4">
                  Note: Please Select Class first before Subject.
                </Typography>
              </Grid>*/}
              <Grid item xs={12} md={12}>
                <SectionDivider
                  text={`Date: ${dayjs(TestDate).format(
                    "YYYY-MM-DD"
                  )} Subject: ${Subject.SubjectName}`}
                />
              </Grid>
              <Grid>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Is Present</TableCell>
                        <TableCell align="center">Test Date</TableCell>
                        <TableCell align="center">GRNO</TableCell>
                        <TableCell align="center">Student Name</TableCell>
                        <TableCell align="center">Subject Name</TableCell>
                        <TableCell align="center">Max Marks</TableCell>
                        <TableCell align="center">Obtain Marks</TableCell>
                        <TableCell align="center">Passing Percentage</TableCell>
                        <TableCell align="right">Percentage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {students.length > 0 &&
                        students.map((student, index) => {
                          return (
                            <TableRow
                              key={String(index)}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <FormControlLabel
                                  // value={menu.create}
                                  name="IsPresent"
                                  control={
                                    <Checkbox
                                    // checked={menu.create}
                                    />
                                  }
                                  // label="CREATE"
                                  // labelPlacement="CREATE"
                                  // onChange={(e) => handleMenusChange(e, index)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {dayjs(TestDate).format("YYYY-MM-DD")}
                              </TableCell>
                              <TableCell align="center">
                                {student.GRNO}
                              </TableCell>
                              <TableCell align="center">
                                {student.StudentName}
                              </TableCell>
                              <TableCell align="center">
                                {Subject.SubjectName}
                              </TableCell>
                              <TableCell align="center">{MaxMarks}</TableCell>
                              <TableCell align="center">
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  sx={{ display: "flex", flex: 1 }}
                                  type="number"
                                  label="Obtained Marks"
                                  name="ObtainedMarks"
                                  required
                                  //   value={school}
                                />
                              </TableCell>
                              <TableCell align="right">{`${PassingPercentage}%`}</TableCell>
                              <TableCell align="right">{`${
                                obtainedPercentage !== null
                                  ? obtainedPercentage
                                  : ""
                              }%`}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Post Results
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You are about to delete all test results.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="error">
            Are you sure you want to Delete all Test Results?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No, Cancel</Button>
          <Button color="error" onClick={_handleDeleteAllTestResults} autoFocus>
            Yes, Delete Test
          </Button>
        </DialogActions>
      </Dialog> */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
